import React, { FormEvent, useEffect, useState } from 'react'
import { useResetPassword } from '../queries/UsersQuery'
import Loading from '../components/Loading'
import { useNavigate } from 'react-router-dom'
import { Button, Form } from 'react-bootstrap'

const PasswordReset: React.VFC = () => {
  const [username, setUsername] = useState<string>('')

  const resetPassword = useResetPassword()
  const navigate = useNavigate()

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    resetPassword.mutate({ userId: username })
  }

  useEffect(() => {
    if (resetPassword.isSuccess) {
      navigate(`/`)
    }
  }, [resetPassword.isSuccess])

  return (
    <>
      {resetPassword.isLoading && <Loading />}
      <link rel="stylesheet" href="/css/pages/recovery.css" />
      <div className="bar-red"></div>
      <div className="container" style={{ width: 'auto', textAlign: 'center', color: '#495057' }}>
        <p style={{ lineHeight: 3 }}>
          パスワードをリセットします。
          <br />
          店舗管理者・eトレーナーにスタッフ一覧ページの最新リセットパスワードを確認してください。
        </p>
        <div className="users form mt-5">
          <form onSubmit={handleSubmit}>
            <fieldset>
              <div className="text_id">
                ユーザー（社員番号）を入力してください
              </div>
              <div className="d-flex justify-content-center align-items-center mt-4">
                <Form.Control 
                  type="text"
                  style={{ height: '35px', fontSize: '16px' }}
                  className="w-auto"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
            </fieldset>
            <div className="submit mt-5">
              <Button
                variant="primary"
                className='px-4'
                type="submit"
                style={{ fontSize: '16px' }}
              >
                パスワードリセット
              </Button>
            </div>
          </form>
        </div>
        <div className="text-center mt-4">
          <Button
            variant="outline-default"
            className='px-4'
            type="submit"
            style={{ fontSize: '16px' }}
            onClick={() => navigate(`/`)}
          >
            ログインへ戻る
          </Button>
        </div>
      </div>
    </>
  )
}

export default PasswordReset
