import React, { useEffect, useState } from 'react'
import { useGetShopsAreas } from '../queries/ShopsQuery'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'
import { useAuthUser } from '../hooks/AuthUserContext'
import { GetShopsResponse, Shop, Brand } from '../types/GetShopsResponse'
import { useModal } from 'react-hooks-use-modal'
import axios from 'axios'
import { ReloadRelationCurriculumModal } from '../components/modal/ReloadRelationCurriculumModal'
import { UserRole } from '../types/UserRole'
import useConfig from '../hooks/GetConfig'
import { Button, Card, Col, Row } from 'react-bootstrap'
import Header from '../components/Header'
import '../css/pages/shop_select.css'

function ShopSelect() {
  const { authUser } = useAuthUser()
  const navigate = useNavigate()
  const [brandId, setBrandId] = useState<string | undefined>(undefined)
  const [areaId, setAreaId] = useState<string | undefined>(undefined)
  const [shopId, setShopId] = useState<string | undefined>(undefined)
  const [shops, setShops] = useState<Shop[]>()
  const [brands, setBrands] = useState<Brand[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [Modal, open, close, isOpen] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  const { data: getAreasResponse } = useGetShopsAreas()
  const config = useConfig();
  useEffect(() => {
    const getShops = async () => {
      const { data } = await axios.get<GetShopsResponse>(`/api/shops/only/${areaId}`)
      const shops = data?.shops
      setShops([...shops])
    }

    getShops()

  }, [areaId])
  useEffect(() => {
    const getBrands = async () => {
      setIsLoading(true);
      const { data } = await axios.get<GetShopsResponse>(`/api/shops/brands/${shopId}`)
      const brands = data?.brands
      setBrands([...brands])
    }

    getBrands()
    setIsLoading(false);

  }, [shopId])

  const fetchShops = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setAreaId(e.target.value)
  }

  const fetchBrands = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setShopId(e.target.value)
  }

  const [hasWorker, setHasWorker] = useState<boolean>(false)
  const [hasManager, setHasManager] = useState<boolean>(false)
  const [hasAdmin, setHasAdmin] = useState<boolean>(false)

  useEffect(() => {
    switch (authUser?.role) {
      case UserRole.Worker:
      case UserRole.Trainer:
      case UserRole.Store1:
        setHasWorker(true)
        break
      case UserRole.Manager:
        setHasManager(true)
        break
      case UserRole.Admin:
        setHasAdmin(true)
        break
    }
  }, [authUser])

  return (
    <>
      {isLoading ? <Loading /> : ''}
      <Header />
      <div id="shop-select-page" className="shop-select-container">
        <div className="mt-5">
          <Row className="justify-content-center">
            <Col md={5} lg={4}>
              <Card className="mb-3">
                <Card.Body>
                  <div className="d-flex flex-column align-items-center">
                    <div className="menu-title">マイページ</div>
                    <Button variant="primary" className="btn-menu" href={`/worker_detail/${authUser?.id}`}>マイページへ</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={5} lg={hasWorker ? 4 : 8}>
              <Card className="mb-3">
                <Card.Body className="px-5">
                  <div
                    className={
                      hasAdmin || hasManager ? 'd-flex flex-column align-items-center align-items-lg-start' : 'd-flex flex-column align-items-center'
                    }
                  >
                    <div className="menu-title">個人レポート</div>
                    <Button variant="secondary" className="btn-menu" href={`/personal_report/${authUser?.id}`}>個人レポート</Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {hasWorker && (
              <Col md={5} lg={4}>
                <Card className="mb-3">
                  <Card.Body className="px-5">
                    <div className="d-flex flex-column align-items-center">
                      <div className="menu-title"> 店舗スタッフ </div>
                      <Button variant="pink" className="btn-menu" onClick={() => navigate(`/shop/${authUser?.shopId}/worker_list/${authUser?.brandId}`)}>店舗スタッフ一覧</Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            {(hasManager || hasAdmin) && (
              <Col md={5} lg={4}>
                <Card className="mb-3">
                  <Card.Body>
                    <div className="d-flex flex-column align-items-center mb-2 shop-select-box">
                      <div className="menu-title">店舗選択</div>
                      <form>
                        <div className="mb-3">
                          <div className="fw-bold mb-2">{config?.labels?.area}</div>
                          <select
                            required
                            value={areaId}
                            className="form-select mb-2 parent"
                            onChange={(e) => fetchShops(e)}
                          >
                            <option value="" className="msg">-----{config?.labels?.area}を選択-----</option>
                            {getAreasResponse?.map((area) => {
                              return (
                                <option key={area.id} value={area.id}>
                                  {area.name}
                                </option>
                              )
                            })}
                          </select>
                          <div className="fw-bold mb-2">{config?.labels?.shop}</div>
                          <select
                            className="form-select mb-2 children"
                            disabled={!areaId}
                            value={shopId}
                            onChange={(e) => fetchBrands(e)}
                          >
                            <option value="" className="msg">-----{config?.labels?.shop}を選択-----</option>
                            {shops?.map((shop) => {
                              return (
                                <option key={shop.id} value={shop.id}>
                                  {shop.name}
                                </option>
                              )
                            })}
                          </select>
                          <div className="fw-bold mb-2">{config?.labels?.brand}</div>
                          <select
                            className="form-select mb-2 children2"
                            disabled={!shopId}
                            value={brandId}
                            onChange={(e) => setBrandId(e.target.value)}
                          >
                            <option value="" className="msg">-----{config?.labels?.brand}を選択-----</option>
                            {brands?.map((brand) => {
                              return (
                                <option key={brand.id} value={brand.id}>
                                  {brand.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </form>
                      <Button 
                        variant="pink" 
                        className="btn-menu"
                        disabled={!brandId}
                        onClick={() => navigate(`/shop/${shopId}/worker_list/${brandId}`)}
                      >
                        店舗スタッフ一覧
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col md={5} lg={hasWorker ? 4 : 8}>
              {(hasManager || hasAdmin) && (
                <Card className="mb-3">
                  <Card.Body className="px-5">
                    <div className="d-flex flex-column align-items-center align-items-lg-start">
                      <div className="menu-title">分析レポート</div>
                      <div className="d-flex align-items-start justify-content-center justify-content-lg-start gap-3 flex-wrap">
                        <Button variant="secondary" className="btn-menu" href="/report_ranking/percent">ランキング</Button>
                        <Button variant="secondary" className="btn-menu" href="/csv_output/personal">CSV出力</Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}
              {hasManager && (
                <Card className="mb-3">
                    <Card.Body className="px-5">
                      <div className="d-flex flex-column align-items-center align-items-lg-start">
                          <Button 
                            variant="pink" 
                            className="btn-menu mt-2"
                            disabled={!brandId}
                            onClick={() => navigate(`/bulk_editing/shop/${shopId}/worker_list/${brandId}/add`)}
                          >
                            カリキュラム一括設定
                          </Button>
                          <span style={{ fontSize: '16px', marginTop: '5px' }}>※店舗選択（左）の地区、店舗、部門の選択が必要です。</span>
                      </div>
                    </Card.Body>
                </Card>
              )}
              {hasAdmin && (
                <Card className="mb-3">
                  <Card.Body className="px-5">
                    <div className="d-flex flex-column align-items-center align-items-lg-start">
                      <div className="menu-title">eトレ管理</div>
                      <div className="d-flex align-items-start justify-content-center justify-content-lg-start gap-3 flex-wrap">
                        <Button variant="orange" className="btn-menu" href="/users">ユーザー管理</Button>
                        <Button variant="orange" className="btn-menu fs-md-sm" onClick={(e) => { e.preventDefault(); open(); }} >
                          カリキュラム
                          <br />
                          情報更新
                        </Button>
                        <Button variant="orange" className="btn-menu fs-md-sm" href="/curriculum_edit">
                          カリキュラム
                          <br />
                          追加・削除
                        </Button>
                        <Button variant="orange" className="btn-menu fs-md-sm" href="/setting/curriculum_display_preference">
                          システム設定
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </div>
        <Modal>
          <ReloadRelationCurriculumModal closeModal={close} onSuccess={() => { close() }} />
        </Modal>
      </div>
    </>
  )
}

export default ShopSelect
