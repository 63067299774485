import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useChangePassword } from "../queries/UsersQuery";
import Loading from "../components/Loading";
import { useGetAuthUser, useLogout } from "../queries/AuthQuery";
import {
  ScFormContainer,
  ScFormItem,
  ScPageTitleTypeA,
} from "../style/styles";
import { Button, FormControl, FormGroup, FormLabel } from "react-bootstrap";
import Header from "../components/Header";

const PasswordChange: React.VFC = () => {
  const { isLoading, refetch, data: authUser } = useGetAuthUser();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [isShowPassword, setIsShowPassword] = useState(0);
  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);

  const navigate = useNavigate();
  const changePassword = useChangePassword();
  const logout = useLogout();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (!authUser?.id) return;
    changePassword.mutate({ userId: authUser.id, password, confirmPassword });
  };

  useEffect(() => {
    if (changePassword.isSuccess) {
      refetch().then(() => {
        navigate(`/`);
      });
    }
  }, [changePassword.isSuccess]);

  useEffect(() => {
    if (confirmPassword !== password) {
      setShowConfirmPasswordError(true)
    } else {
      setShowConfirmPasswordError(false)
    }
  }, [confirmPassword]);

  return (
    <>
      {(isLoading || changePassword.isLoading || logout.isLoading) && (
        <Loading />
      )}
      <link rel="stylesheet" href="../css/pages/login.css" />
      <Header />
      <ScFormContainer>
        <ScPageTitleTypeA>パスワードを変更してください</ScPageTitleTypeA>
        <form onSubmit={handleSubmit} style={{ color: '#495057' }}>
          <fieldset>
            <ScFormItem>
              <FormGroup>
                <FormLabel>新しいパスワード</FormLabel>
                <FormControl
                  type={isShowPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>
            </ScFormItem>
            <ScFormItem>
              <FormGroup>
                <FormLabel>新しいパスワード（確認）</FormLabel>
                <FormControl
                  type={isShowPassword ? 'text' : 'password'}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </FormGroup>
              {showConfirmPasswordError ? <div className="error-message" >パスワードが上記と一致しません</div> : ''}
            </ScFormItem>
            <ScFormItem>
              <input
                type="checkbox"
                value={isShowPassword}
                id="showPassword"
                onChange={(e) => setIsShowPassword(e.target.checked ? 1 : 0)}
              />
              <label htmlFor='showPassword'>&nbsp;パスワード表示する</label>
            </ScFormItem>
          </fieldset>
          <div className="d-flex flex-column justify-content-center align-items-center gap-3 mt-3">
            <Button
              variant="primary"
              className='px-4 w-100'
              type="submit"
              style={{ fontSize: '16px' }}
            >
              パスワードを変更
            </Button>
            <Button
              variant="outline-default"
              className='px-4'
              type="submit"
              style={{ fontSize: '16px' }}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
            >
              戻る
            </Button>
          </div>
        </form>
      </ScFormContainer>
    </>
  );
};

export default PasswordChange;
