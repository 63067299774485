import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetWorkerDetail } from '../queries/WorkersQuery'
import TaskIcon from '../components/TaskIcon'
import WorkerHeader from '../components/WorkerHeader'
import Loading from '../components/Loading'
import { CurriculumForWorkerDetail, StepForWorkerDetail } from '../types/WorkerDetailResponse'
import { Button, Collapse } from 'react-bootstrap'
import AimCurriculumSettingModal from '../components/modal/AimCurriculumSettingModal'
import { useModal } from 'react-hooks-use-modal'
import CertificationModal from '../components/modal/CertificationModal'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function WorkerDetail() {
  // hooks
  const { workerId } = useParams()
  const [AimCurriculumModalContext, openAimCurriculumModal, closeAimCurriculumModal] = useModal('root', {
    preventScroll: true
  });
  const [CertificationModalContext, openCertificationModal, closeCertificationModal] = useModal('root', {
    preventScroll: true
  });
  const [CertificationStepModalContext, openCertificationStepModal, closeCertificationStepModal] = useModal('root', {
    preventScroll: true
  });

  const getTargetBrandName = () => {
    const url = new URL(window.location.href);
    const sectionId = url.hash.substring(1);

    if(sectionId){
      return '全て';
    }
    
    return '';
  }

  // states
  const [curriculumBrandName, setCurriculumBrandName] = useState<string | undefined>(getTargetBrandName)
  const [showAlarm, setShowAlarm] = useState<boolean | undefined>(false)
  const [showCalendar, setShowCalendar] = useState<boolean | undefined>(false)
  const [showIncomplete, setShowIncomplete] = useState<boolean | undefined>(false)
  const [tasksByCurriculum, setTasksByCurriculum] = useState<CurriculumForWorkerDetail[]>([]);
  const [changeSort, setChangeSort] = useState<boolean>(false);
  const [displayCurriculums, setDisplayCurriculums] = useState<string[]>([]);

  // queries
  const {
    isLoading,
    isFetching,
    refetch,
    data: workerDetailResponse,
  } = useGetWorkerDetail({ workerId }, { curriculumBrandName })

  // methods
  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    const displayCurriculums = JSON.parse(sessionStorage.getItem("displayCurriculums") || "[]");
    if (scrollPosition) {
      window.scrollTo(0, parseInt(scrollPosition));
      setDisplayCurriculums(displayCurriculums);
      sessionStorage.removeItem("scrollPosition");
      sessionStorage.removeItem("displayCurriculums");
    }
  };

  const handleClick = () => {
    const position = window.pageYOffset;
    sessionStorage.setItem("scrollPosition", position.toString());
    sessionStorage.setItem("displayCurriculums", JSON.stringify(displayCurriculums));
  };

  const onUpdate = async () => {
    handleClick()
    await refetch().then(() => {
      handleUpdate()
      handleScrollPosition()
    });
  }

  const sortTasksByCurriculum = useCallback((sort: boolean) => {
    const order1 = sort ? -1 : 1;
    const order2 = sort ? 1 : -1;
    return tasksByCurriculum.sort((a, b) => (a.curriculumOrder < b.curriculumOrder) ? order1 : (a.curriculumOrder > b.curriculumOrder) ? order2 : 0)
  }, [tasksByCurriculum]);

  const getCurriculumsFilteredByCompletion = (curriculums: CurriculumForWorkerDetail[], isCompleted?: boolean) => {
    return curriculums.filter((curriculum) =>
      curriculum.steps.every((step) => step.taskInstances.every((taskInstance) => taskInstance.isDone)) === isCompleted
    );
  };

  const sortCurriculumsByCompletionDescending = () => {
    const curriculums = workerDetailResponse?.worker.tasksByCurriculum?.filter((curriculum) => curriculum !== null) || [];

    const curriculumsCompleted = getCurriculumsFilteredByCompletion(curriculums, true);

    const curriculumsNotCompleted = getCurriculumsFilteredByCompletion(curriculums, false)
    
    setTasksByCurriculum([...curriculumsNotCompleted, ...curriculumsCompleted]);
  };

  function handleUpdate() {
    if (showAlarm) {
      const tasksByCurriculums = workerDetailResponse?.worker.tasksByCurriculum ?? [];
      const filteredCurriculums = tasksByCurriculums.map((curriculum) => {
        const filteredSteps = curriculum.steps.filter((step) => {
          const filteredTaskInstances = step.taskInstances.filter((taskInstance) => taskInstance.isAlarm);
          return filteredTaskInstances.length > 0;
        });
        return { ...curriculum, steps: filteredSteps };
      }).filter((curriculum) => curriculum.steps.length > 0);
      setTasksByCurriculum(filteredCurriculums);
    } else if(showCalendar){
      const tasksByCurriculums = workerDetailResponse?.worker.tasksByCurriculum ?? [];
      const filteredCurriculums = tasksByCurriculums.map((curriculum) => {
        const filteredSteps = curriculum.steps.filter((step) => {
          const filteredTaskInstances = step.taskInstances.filter((taskInstance) => taskInstance.isCalendar);
          return filteredTaskInstances.length > 0;
        });
        return { ...curriculum, steps: filteredSteps };
      }).filter((curriculum) => curriculum.steps.length > 0);
      setTasksByCurriculum(filteredCurriculums);
    } else if(showIncomplete){
      const tasksByCurriculums = workerDetailResponse?.worker.tasksByCurriculum ?? [];
      const filteredCurriculums = tasksByCurriculums.map((curriculum) => {
        const filteredSteps = curriculum.steps.filter((step) => {
          const filteredTaskInstances = step.taskInstances.filter((taskInstance) => !taskInstance.isDone);
          return filteredTaskInstances.length > 0;
        });
        return { ...curriculum, steps: filteredSteps };
      }).filter((curriculum) => curriculum.steps.length > 0);
      setTasksByCurriculum(filteredCurriculums);
    } else {
      sortCurriculumsByCompletionDescending();
    }
  }

  // effects
  useEffect(() => {
    handleUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerDetailResponse, showAlarm, showCalendar, showIncomplete]);
  
  useEffect(() => {
    onUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const curriculumsSorted = sortTasksByCurriculum(changeSort);

    setTasksByCurriculum(curriculumsSorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeSort]);

  const [currentCurriculum, setCurrentCurriculum] = useState<CurriculumForWorkerDetail | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState<StepForWorkerDetail | undefined>(undefined);

  const handleAimCurriculumSettingModalOpen = (e: React.MouseEvent<HTMLButtonElement>,curriculum: CurriculumForWorkerDetail) => {
    e.stopPropagation();
    setCurrentCurriculum(curriculum);
    openAimCurriculumModal();
  }

  const handleCertificationModalOpen = (e: React.MouseEvent<HTMLButtonElement>, curriculum: CurriculumForWorkerDetail) => {
    e.stopPropagation();
    setCurrentCurriculum(curriculum);
    openCertificationModal();
  }

  const handleCertificationStepModalOpen = (step: StepForWorkerDetail) => {
    setCurrentStep(step);
    openCertificationStepModal();
  }

  const getAimCurriculumButtonStyle = (curriculum: CurriculumForWorkerDetail) => {
    let style = {
      color: '',
      backgroundColor: '',
      border: '',
    };

    if (curriculum.steps.every((step) => step.taskInstances.every((taskInstance) => taskInstance.isDone))) {
      style.backgroundColor = '#d1d1d1';
      style.color = '#fff';
      style.border = '1px solid #d1d1d1';

      return style;
    }


    if (curriculum.end) {
      const endDate = new Date(curriculum.end);
      const currentDate = new Date();

      currentDate.setHours(0, 0, 0, 0);

      if (endDate < currentDate) {
        style.backgroundColor = '#E94D4D';
        style.color = '#fff';
        style.border = '1px solid #E94D4D';
      }
    }

    if (curriculum.curriculum_aim_comment && style.backgroundColor === '#fff') {
      style.backgroundColor = '#ffe4c4';
    }

    return style;
  }

  useEffect(() => {
    const scrollPosition =  sessionStorage.getItem("scrollPosition");
    console.log('scrollPosition', scrollPosition);
    if(scrollPosition === '0'){
      updateScrollPosition();
    }

    const displayCurriculums = JSON.parse(sessionStorage.getItem("displayCurriculums") || "[]");
    if (displayCurriculums.length > 0){
      setDisplayCurriculums(displayCurriculums);
    } else if (workerDetailResponse?.curriculumDisplayPreference === true){
      const curriculumsCompleted = getCurriculumsFilteredByCompletion(tasksByCurriculum, true);
      const curriculumsCompletedIds = curriculumsCompleted.map((curriculum) => curriculum.curriculumId);
      setDisplayCurriculums(curriculumsCompletedIds);
    }
  }, [tasksByCurriculum]);

  const updateScrollPosition = () => {
    const url = new URL(window.location.href);
    const sectionId = url.hash.substring(1); // Remove the '#' from the hash
    const targetElement = document.getElementById(sectionId);
    
    if (sectionId && targetElement) {
      const scrollTop = targetElement.offsetTop;
      window.scrollTo({ top: scrollTop, behavior: 'smooth' });
      window.history.replaceState(null, '', window.location.pathname);
    }
  };

  const handleShowCurriculum = (curriculumId: string) => {
    if (displayCurriculums.includes(curriculumId)) {
      setDisplayCurriculums(displayCurriculums.filter((id) => id !== curriculumId));
    } else {
      setDisplayCurriculums([...displayCurriculums, curriculumId]);
    }
  }

  const verifyCollapseCurriculum = (curriculumId: string) => {
    if(workerDetailResponse?.curriculumDisplayPreference === false){
      return true;
    }
    return !displayCurriculums.includes(curriculumId);
  }

  const handleShowAllCurriculums = (toggleCurriculums: boolean) => {
    const completedCurriculums = getCurriculumsFilteredByCompletion(tasksByCurriculum, true);
    const completedCurriculumIds = completedCurriculums.map((curriculum) => curriculum.curriculumId);

    if(toggleCurriculums){
      const newDisplayCurriculums = displayCurriculums.filter((curriculumId) => !completedCurriculumIds.includes(curriculumId));
      setDisplayCurriculums(newDisplayCurriculums);
    } else {
      setDisplayCurriculums([...displayCurriculums, ...completedCurriculumIds]);
    }
  }

  return (
    <>
      <link rel="stylesheet" href="/css/pages/worker_detail.css" />
      {isLoading || isFetching ? (
        <Loading />
      ) : (
        <>
          <WorkerHeader
            shopId={workerDetailResponse?.worker.shopId || ''}
            brandId={workerDetailResponse?.worker.brandId || ''}
            brandName={workerDetailResponse?.worker.brandName || ''}
            forms={workerDetailResponse?.forms || []}
            curriculumDisplayPreference={workerDetailResponse?.curriculumDisplayPreference || false}
            handleShowAllCurriculums={handleShowAllCurriculums}
            workerId={workerDetailResponse?.worker.id || ''}
            workerName={workerDetailResponse?.worker.name || ''}
            growthGoal={workerDetailResponse?.worker.growthGoal || ''}
            curriculums={
              workerDetailResponse?.curriculums.filter((group) => {
                return group.items.filter((curriculum) => {
                  return !tasksByCurriculum
                    .map((item) => item.curriculumId)
                    .includes(curriculum.id)
                })
              }) || []
            }
            workerCurriculumIds={
              tasksByCurriculum.map((item) => {
                return item.curriculumId
              }) || []
            }
            onUpdate={onUpdate}
            curriculumBrandName={curriculumBrandName}
            handleCurriculumBrandName={(name: string) => setCurriculumBrandName(name)}
            hasAlarm={showAlarm}
            setHasAlarm={(alarm: boolean) => setShowAlarm(alarm)}
            setHasCalendar={(calendar: boolean) => setShowCalendar(calendar)}
            setHasIncomplete={(incomplete: boolean) => setShowIncomplete(incomplete)}
            sortCurriculums={(sortOrder) => setChangeSort(sortOrder)}
          />
          <div className="content">
            {workerDetailResponse && tasksByCurriculum.map(
              (curriculum) => {
                return (
                  <div id={curriculum.curriculumId} key={curriculum.curriculumId}>
                    <div className={workerDetailResponse?.curriculumDisplayPreference ? 'cal-title d-flex align-items-center justify-content-between pointer' : 'cal-title d-flex align-items-center'} id="task_step_111" onClick={() => handleShowCurriculum(curriculum.curriculumId)}>
                      <div className="fs-4">
                        {curriculum.curriculumName}
                      </div>
                      <div className="d-flex gap-2 ms-4">
                        <button
                          className="btn btn-sm btn-trCert d-flex align-items-center justify-content-center"
                          style={getAimCurriculumButtonStyle(curriculum)}
                          onClick={(e) => {
                            handleAimCurriculumSettingModalOpen(e, curriculum)
                          }}
                        >
                          <img src="/img/icon_date.png" style={{ filter: 'brightness(0) invert(1)' }} height="21" alt="eトレーナー認定チェック" />
                          <span className="ms-1 pt-1">目標設定</span>
                        </button>
                        <button
                          className="btn btn-sm btn-trCert d-flex align-items-center justify-content-center"
                          style={{
                            backgroundColor: curriculum.curriculum_is_certified ? '#ffe4c4' : 'transparent',
                            color: curriculum.curriculum_is_certified ? '#000' : '#fff',
                            border: curriculum.curriculum_is_certified ? '1px solid #ffe4c4' : '',
                            borderRadius: '6px',
                          }}
                          onClick={(e) => {
                            handleCertificationModalOpen(e, curriculum)
                          }}
                        >
                          <img src="/img/certify.png" height="28" alt="eトレーナー認定チェック" />
                          <span className="ms-1 pt-1">認定</span>
                        </button>
                      </div>
                      {workerDetailResponse?.curriculumDisplayPreference && (
                        <div className="ms-auto" style={{ marginRight: '15px' }}>
                          <FontAwesomeIcon icon={displayCurriculums.some(showCurriculum => showCurriculum === curriculum.curriculumId) ? faChevronUp : faChevronDown} />
                        </div>
                      )}
                    </div>
                    {curriculum.steps.map((step) => {
                      return (
                        <Collapse in={verifyCollapseCurriculum(curriculum.curriculumId)}>
                          <div key={step.stepId} className="calc-content accordion-content">
                            <table>
                              <tbody>
                                <tr>
                                  <td id={step.stepId} style={{ position: 'relative' }}>
                                    <span style={{ fontSize: '1.20rem', fontWeight: '500' }}>
                                      {step.stepName}
                                    </span>
                                    <div className="w-100 mt-3 d-flex flex-column align-items-center">
                                      <Button
                                        size="sm"
                                        variant="light"
                                        className="btn-trCert d-flex align-items-center justify-content-center position-absolute bottom-0 end-0 mb-3 me-3"
                                        style={{
                                          backgroundColor: step.step_is_certified ? '#ffe4c4' : 'transparent',
                                          color: step.step_is_certified ? '#000' : '#fff',
                                          border: step.step_is_certified ? '1px solid #ffe4c4' : '',
                                          borderRadius: '6px',
                                        }}
                                        onClick={() => handleCertificationStepModalOpen(step)}
                                      >
                                        <img src="/img/certify.png" height="28" alt="eトレーナー認定チェック" />
                                        <div className="ms-1 pt-1">認定</div>
                                      </Button>
                                    </div>
                                  </td>
                                  <td>
                                    <ul className="calc-list">
                                      {step.taskInstances.map((taskInstance) => {
                                        return (
                                          <li key={taskInstance.task_id}>
                                            <TaskIcon
                                              taskInstance={taskInstance}
                                              stepId={step.stepId}
                                              stepIsDone={step.totalTasks === step.doneTasks}
                                              onUpdate={onUpdate}
                                            />
                                          </li>
                                        )
                                      })}
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </Collapse>
                      )
                    })}
                  </div>
                )
              }
            )}
            <AimCurriculumModalContext>
              {currentCurriculum && (
                <AimCurriculumSettingModal
                  onClose={() => closeAimCurriculumModal()}
                  workerId={workerId}
                  curriculum={currentCurriculum}
                  onSuccess={async () => {
                    closeAimCurriculumModal();
                    onUpdate();
                  }}
                  canEditAsWorker={true}
                  canEditAsTrainer={true}
                />
              )}
            </AimCurriculumModalContext>
            <CertificationModalContext>
              {currentCurriculum && (
                <CertificationModal
                  onClose={() => closeCertificationModal()}
                  trainingItem={{ id: currentCurriculum.curriculumId, workerId: workerId, comment: currentCurriculum.curriculum_certified_comment, isCertified: currentCurriculum.curriculum_is_certified }}
                  onSuccess={async () => {
                    closeCertificationModal();
                    onUpdate();
                  }}
                  canEditAsWorker={true}
                  canEditAsTrainer={true}
                />
              )}
            </CertificationModalContext>
            <CertificationStepModalContext>
              {currentStep && (
                <CertificationModal
                  onClose={() => closeCertificationStepModal()}
                  trainingItem={{ id: currentStep.stepId, workerId: workerId, comment: currentStep.certified_comment, isCertified: currentStep.step_is_certified }}
                  onSuccess={async () => {
                    closeCertificationStepModal();
                    onUpdate();
                  }}
                  canEditAsWorker={true}
                  canEditAsTrainer={true}
                  hasStep
                />
              )}
            </CertificationStepModalContext>
          </div>
        </>
      )}
    </>
  )
}

export default WorkerDetail
