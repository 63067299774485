import React, { FormEvent, useEffect, useState } from 'react'
import TextareaModal from './TextareaModal'
import { TaskInstance } from '../../types/TaskInstance'
import {
  useUpdateFeedbackTrainerComment,
  useUpdateFeedbackWorkerComment,
} from '../../queries/TasksQuery'
import Loading from '../Loading'
import { StButtonTypeB } from '../../style/styles'
import PreformattedText from '../PreformattedText'

type FeedbackModalProps = {
  taskInstance: TaskInstance
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function FeedbackModal(props: FeedbackModalProps) {
  const [workerComment, setWorkerComment] = useState<string>(
    props.taskInstance.task_feedback_comment || ''
  )
  const [trainerComment, setTrainerComment] = useState<string>(
    props.taskInstance.task_feedback_trainer_comment || ''
  )
  const [isWorkerTextareaModalOpen, setWorkerTextareaModalOpen] = useState(false)
  const [isTrainerTextareaModalOpen, setTrainerTextareaModalOpen] = useState(false)

  const updateFeedbackWorkerComment = useUpdateFeedbackWorkerComment()
  const updateFeedbackTrainerComment = useUpdateFeedbackTrainerComment()

  const handleSubmitEditAsWorker = (e: FormEvent) => {
    e.preventDefault()
    updateFeedbackWorkerComment.mutate({
      workerId: props.taskInstance.worker_id,
      taskId: props.taskInstance.task_id,
      comment: workerComment,
    })
  }

  const handleSubmitEditAsTrainer = (e: FormEvent) => {
    e.preventDefault()
    updateFeedbackTrainerComment.mutate({
      workerId: props.taskInstance.worker_id,
      taskId: props.taskInstance.task_id,
      comment: trainerComment,
    })
  }

  useEffect(() => {
    if (updateFeedbackWorkerComment.isSuccess) {
      props.onSuccess()
    }
  }, [updateFeedbackWorkerComment.isSuccess])

  useEffect(() => {
    if (updateFeedbackTrainerComment.isSuccess) {
      props.onSuccess()
    }
  }, [updateFeedbackTrainerComment.isSuccess])

  const textareaStyle: React.CSSProperties = {
    border: '1px solid #ccc',
    borderRadius: '10px',
    width: '100%',
    minHeight: '80px',
    cursor: 'pointer',
    overflowY: 'auto',
    resize: 'vertical',
    backgroundColor: '#fff',
    marginBottom: '10px',
    color: '#000',
  };

  return (
    <>
      <link rel="stylesheet" href="/css/components/modal/feedback_modal.css" />
      <div className="modal">
        {updateFeedbackWorkerComment.isLoading || updateFeedbackTrainerComment.isLoading ? <Loading /> : ''}
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1> {props.taskInstance.title} </h1>
        </div>
        <PreformattedText
          description={props.taskInstance.description ?? ''}
        />
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <fieldset>
              <pre
                style={{...textareaStyle,
                  color: workerComment ? '#000' : '#a0a0a0'
                }}
                onClick={() => setWorkerTextareaModalOpen(true)}
              >
                {workerComment || "ここをクリックして表示したいテキストを入力してください。"}
              </pre>

              <TextareaModal
                isOpen={isWorkerTextareaModalOpen}
                onClose={() => setWorkerTextareaModalOpen(false)}
                onConfirm={(text) => {
                  setWorkerComment(text);
                  setWorkerTextareaModalOpen(false);
                }}
                comment={workerComment}
              />

              <div style={{ marginTop: '30px' }}>
                トレーナーコメント
                <pre
                  style={{...textareaStyle,
                    color: workerComment ? '#000' : '#a0a0a0'
                  }}
                  onClick={() => setTrainerTextareaModalOpen(true)}
                >
                  {trainerComment || "ここをクリックして表示したいテキストを入力してください。"}
                </pre>

                <TextareaModal
                  isOpen={isTrainerTextareaModalOpen}
                  onClose={() => setTrainerTextareaModalOpen(false)}
                  onConfirm={(text) => {
                    setTrainerComment(text);
                    setTrainerTextareaModalOpen(false);
                  }}
                  comment={trainerComment}
                />
              </div>
              
              {props.canEditAsWorker && (
                <button
                  type="submit"
                  style={StButtonTypeB}
                  onClick={handleSubmitEditAsWorker}
                >
                  スタッフ送信
                </button>
              )}
              {props.canEditAsTrainer && (
                <button
                  type="submit"
                  style={StButtonTypeB}
                  onClick={handleSubmitEditAsTrainer}
                >
                  トレーナー送信
                </button>
              )}
            </fieldset>
          </form>
        </div>
      </div>
    </>
  );
}

export default FeedbackModal;
