import axios from 'axios'
import { GetShopsResponse } from '../types/GetShopsResponse'

const getShops = async () => {
  const { data } = await axios.get<GetShopsResponse>(`/api/shops`)
  return data
}

const getShopsBrands = async ({
  shopId,
}: {
  shopId: string | undefined
}) => {
  const { data } = await axios.get<GetShopsResponse>(`/api/shops/brands/${shopId}`)
  return data?.brands
}

type BrandsForCurriculumsResponse = {
  brands: BrandOption[];
};
type BrandOption = {
  id: string;
  name: string;
};

// return array of brand names  
const getBrandsForCurriculums = async ({ shopId, }: { shopId: string | undefined }) => {
  const { data } = await axios.get<BrandsForCurriculumsResponse>(`/api/shops/${shopId}/brands-for-curriculums`)
  return data?.brands
}

const getShopsAreas = async () => {
  const { data } = await axios.get<GetShopsResponse>(`/api/shops/areas`)
  return data?.areas
}

const getShopsOnly = async ({
  areaId,
}: {
  areaId: string | undefined
}) => {
  const { data } = await axios.get<GetShopsResponse>(`/api/shops/only/${areaId}`)
  return data?.shops
}

const updateShopGrowthGoal = async ({
  shopId,
  growthGoal,
}: {
  shopId: string
  growthGoal: string
}) => {
  await axios.get(`api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/shops/${shopId}/growthGoal`, {
    growthGoal,
  })
  return data
}

export { getShops,
  updateShopGrowthGoal,
  getShopsBrands,
  getShopsOnly,
  getShopsAreas,
  getBrandsForCurriculums
}
