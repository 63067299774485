import { FormEvent, useEffect, useState } from 'react'
import { TaskInstance } from '../../types/TaskInstance'
import { SelfCheckItem } from '../../types/SelfCheckItem'
import {
  useGetTaskInformation,
  useUpdateSelfChecks,
  useUpdateTrainerChecks,
} from '../../queries/TasksQuery'
import Loading from '../Loading'
import { StButtonTypeB, StButtonTypeBDisabled, textareaStyle } from '../../style/styles'
import { useAuthUser } from '../../hooks/AuthUserContext'
import { TaskInstanceStatus } from '../../types/TaskInstanceStatus'
import ReactLoading from "react-loading";
import TextareaModal from './TextareaModal'
import PreformattedText from '../PreformattedText'


type SelfCheckModalProps = {
  taskInstance: TaskInstance
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function SelfCheckModal(props: SelfCheckModalProps) {
  const { authUser } = useAuthUser()

  const {
    isFetching,
    isLoading,
    data: taskInformationResponse,
  } = useGetTaskInformation(
    { workerId: props.taskInstance.worker_id },
    { taskId: props.taskInstance.task_id }
  )

  const [selfCheckItems, setSelfCheckItems] = useState<SelfCheckItem[]>([])
  const [selfCheckItemIds, setSelfCheckItemIds] = useState<string[]>([])
  const [trainerCheckItemIds, setTrainerCheckItemIds] = useState<string[]>([])
  const [trainerComment, setTrainerComment] = useState<string>(props.taskInstance.trainerComment || '')
  const [propSelfCheckItemIds, setPropSelfCheckItemIds] = useState<string[]>([])
  const [propTrainerCheckItemIds, setPropTrainerCheckItemIds] = useState<string[]>([])
  const canEditAsWorker = props.canEditAsWorker
  const canEditAsTrainer = props.canEditAsTrainer
  const [isTextareaModalOpen, setTextareaModalOpen] = useState(false);
  const [hasTrainerCheckedItems, setHasTrainerCheckedItems] = useState<boolean>(false)
  const [taskStatus] = useState<number>(props.taskInstance.task_status)

  useEffect(() => {
    const selfCheckItemIds = taskInformationResponse?.workerCheckedItems?.map((item) => item.id) || [];
    const trainerCheckItemIds = taskInformationResponse?.trainerCheckedItems?.map((item) => item.id) || [];

    setSelfCheckItems(taskInformationResponse?.workerCheckItems || [])
    setSelfCheckItemIds(selfCheckItemIds)
    setTrainerCheckItemIds(trainerCheckItemIds);

    // props 
    setPropSelfCheckItemIds(selfCheckItemIds)
    setPropTrainerCheckItemIds(trainerCheckItemIds);
    if (props.taskInstance.task_status != TaskInstanceStatus.Undone) setHasTrainerCheckedItems(trainerCheckItemIds.length > 0);
  }, [taskInformationResponse])

  const [checkAll, setCheckAll] = useState<boolean>(false)

  const updateSelfChecks = useUpdateSelfChecks()
  const confirmSelfChecks = useUpdateSelfChecks()
  const updateTrainerChecks = useUpdateTrainerChecks()
  const confirmTrainerChecks = useUpdateTrainerChecks()

  const shouldDisableSubmitButton = (): boolean => {
    if (hasTrainerCheckedItems) {
      return true;
    }
    const isItemCountEqual = selfCheckItemIds?.length === selfCheckItems?.length;
    const result = !(isItemCountEqual && canEditAsWorker);

    return result;
  };

  const disabledInputWorker = function (): boolean {
    if (taskStatus === TaskInstanceStatus.Undone) {
      return false;
    }
    if (trainerCheckItemIds.length > 0 || !canEditAsWorker || taskStatus > TaskInstanceStatus.WorkerDone || hasTrainerCheckedItems) {
      return true;
    } else {
      return false;
    }
  }

  const disabledInputTrainer = function (): boolean {
    // @ts-ignore
    if (props.canEditAsTrainer && taskStatus >= TaskInstanceStatus.WorkerDone && selfCheckItemIds?.length >= selfCheckItems?.length) {
      return false;
    } else {
      return true;
    }
  }
  
  const closeModal = () => {

    if (arraysAreEqual(selfCheckItemIds, propSelfCheckItemIds) && arraysAreEqual(trainerCheckItemIds, propTrainerCheckItemIds)) {
      props.closeModal();
      return;
    }

    updateChecks();
  }

  const updateChecks = () => {
    updateSelfChecks.mutate({
      workerId: props.taskInstance.worker_id,
      taskId: props.taskInstance.task_id,
      selfCheckItemIds: selfCheckItemIds,
      confirm: false,
    })
  
    if(selfCheckItemIds.length === selfCheckItems.length && !arraysAreEqual(trainerCheckItemIds, propTrainerCheckItemIds)){
      updateTrainerChecks.mutate({
        workerId: props.taskInstance.worker_id,
        taskId: props.taskInstance.task_id,
        trainerCheckedItemIds: trainerCheckItemIds,
        trainerComment,
        confirm: false,
      })
    }
  }


  function arraysAreEqual<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    return arr1.every((element, index) => element === arr2[index]);
  }

  useEffect(() => {
    if (confirmSelfChecks.isSuccess || confirmTrainerChecks.isSuccess || updateSelfChecks.isSuccess || updateTrainerChecks.isSuccess) {
      props.onSuccess()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmSelfChecks.isSuccess, confirmTrainerChecks.isSuccess, updateSelfChecks.isSuccess, updateTrainerChecks.isSuccess, authUser])

  const handleCheckByWorker = (
    checked: boolean,
    selfCheckItem: SelfCheckItem
  ) => {
    let itemIds = [...selfCheckItemIds]
    if (checked) {
      if (!itemIds.includes(selfCheckItem.id)) itemIds.push(selfCheckItem.id)
      setSelfCheckItemIds(itemIds)
    } else {
      itemIds = itemIds.filter((id) => id !== selfCheckItem.id)
      setSelfCheckItemIds(itemIds)
    }
  }

  const handleCheckByTrainer = (
    checked: boolean,
    selfCheckItem: SelfCheckItem
  ) => {
    let itemIds = [...trainerCheckItemIds]
    if (checked) {
      if (!itemIds.includes(selfCheckItem.id)) itemIds.push(selfCheckItem.id)
      setTrainerCheckItemIds(itemIds)
    } else {
      itemIds = itemIds.filter((id) => id !== selfCheckItem.id)
      setTrainerCheckItemIds(itemIds)
    }
  }

  const handleSubmitAsWorker = (e: FormEvent) => {
    e.preventDefault()
    confirmSelfChecks.mutate({
      workerId: props.taskInstance.worker_id,
      taskId: props.taskInstance.task_id,
      selfCheckItemIds: selfCheckItemIds,
      confirm: true,
    })
  }
  const handleSubmitAsTrainer = (e: FormEvent) => {
    e.preventDefault()
    confirmTrainerChecks.mutate({
      workerId: props.taskInstance.worker_id,
      taskId: props.taskInstance.task_id,
      trainerCheckedItemIds: trainerCheckItemIds,
      trainerComment: trainerComment,
      confirm: true,
    })
  }

  useEffect(() => {
    const isAllItemsChecked = selfCheckItems?.length === trainerCheckItemIds.length
    setCheckAll(isAllItemsChecked);
  }, [props.taskInstance, trainerCheckItemIds]);

  const handleCheckAllTrainer = () => {
    let itemIds: string[] = [];

    if (!checkAll) {
      selfCheckItems?.forEach(function (selfCheckItem) {
        itemIds.push(selfCheckItem.id)
      })
      setTrainerCheckItemIds(itemIds)
      return;
    }
    setTrainerCheckItemIds([]);
  }

  return (
    <>
      <link rel="stylesheet" href="/css/components/modal/self_check_modal.css" />
      {updateSelfChecks.isLoading || updateTrainerChecks.isLoading || confirmSelfChecks.isLoading || confirmTrainerChecks.isLoading ? <Loading /> : ''}
      {(isLoading || isFetching) ? <ReactLoading type="spin" /> :
        <div className="modal-selfcheck">
          <div className='modal-selfcheck-inner'>
            <div style={{ textAlign: 'right', marginRight: '20px', marginTop: '-22px'}}>
              <span>レ点は自動保存</span>
            </div>
            <div className="modal-close">
              <button type="button" className="modal-close-button" onClick={closeModal}>
                <img src="/images/circle-xmark.svg" alt="" className="modal-close-icon" />
              </button>
            </div>
            <div className="modal-header">
              <h1> {props.taskInstance.title} </h1>
            </div>
            <PreformattedText description={props.taskInstance.description ?? ''} />
            <div>
              {props.canEditAsTrainer ? (
                <div className='allselect-box'>
                  <div className="count-text">チェック項目：<span>{selfCheckItems?.length}</span></div>
                  <button
                    style={{
                      borderRadius: '5px',
                      fontSize: '12px',
                      cursor: 'pointer'
                    }}
                    onClick={handleCheckAllTrainer}
                    disabled={disabledInputTrainer()}
                  >
                    {checkAll ? '全解除' : '全選択'}
                  </button>
                </div>
              ) : null}

              <div className="selfchecktable-box" tabIndex={0}>
                <table className="selfchecktable">
                  <tbody>
                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td
                        style={{
                          fontSize: '10px',
                          padding: '0 5px',
                          textAlign: 'center',
                        }}
                      >
                        トレーナー
                      </td>
                    </tr>
                    {selfCheckItems?.map((selfCheckItem) => {
                      return (
                        <tr key={selfCheckItem.id}>
                          <td>{selfCheckItem.content}</td>
                          <td>
                            <div className="input checkbox">
                              <input
                                type="checkbox"
                                checked={selfCheckItemIds.includes(selfCheckItem.id)}
                                onChange={(e) => {
                                  handleCheckByWorker(e.target.checked, selfCheckItem)
                                }}
                                disabled={disabledInputWorker()}
                              />
                            </div>
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={trainerCheckItemIds.includes(selfCheckItem.id)}
                              onChange={(e) => {
                                handleCheckByTrainer(e.target.checked, selfCheckItem)
                              }}
                              disabled={disabledInputTrainer()}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

              <div style={{ marginTop: '15px' }}>
                トレーナーコメント
                <pre
                  onClick={() => setTextareaModalOpen(true)}
                  style={{
                    ...textareaStyle,
                    color: trainerComment ? '#000' : '#a0a0a0'
                  }}
                >
                  {trainerComment || "ここをクリックして表示したいテキストを入力してください。"}
                </pre>

                <TextareaModal
                  isOpen={isTextareaModalOpen}
                  onClose={() => setTextareaModalOpen(false)}
                  onConfirm={(text) => {
                    setTrainerComment(text);
                    setTextareaModalOpen(false);
                  }}
                  comment={trainerComment}
                />
                <div className="self-check-modal-confirm-buttons">
                  <div>
                    <div style={{ paddingLeft: 300 }}>
                      セルフ全項目
                    </div>
                    <div>
                      <button
                        type="submit"
                        style={shouldDisableSubmitButton() ? StButtonTypeBDisabled : StButtonTypeB}
                        onClick={handleSubmitAsWorker}
                        disabled={shouldDisableSubmitButton()}
                      >
                        完了
                      </button>
                    </div>
                  </div>
                  <div>
                    <div style={{ paddingLeft: 300 }}>
                      トレーナー全項目
                    </div>
                    <div>
                      {props.canEditAsTrainer ?
                        <>
                          <button
                            type="submit"
                            style={!((trainerCheckItemIds?.length === selfCheckItems?.length) && (canEditAsTrainer)
                              && (selfCheckItemIds?.length >= selfCheckItems?.length)) ? StButtonTypeBDisabled : StButtonTypeB}
                            disabled={!((trainerCheckItemIds?.length === selfCheckItems?.length) && (canEditAsTrainer) && (selfCheckItemIds?.length >= selfCheckItems?.length))}
                            onClick={handleSubmitAsTrainer}
                          >
                            合格
                          </button>
                        </>
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default SelfCheckModal
