import React, { FormEvent, useEffect, useState } from 'react'
import { useUpdateGrowthGoal } from '../../queries/WorkersQuery'
import Loading from '../Loading'
import { StButtonTypeB, StButtonTypeBDisabled } from '../../style/styles'

type GrowthGoalEditModalProps = {
  workerId: string
  growthGoal: string
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function GrowthGoalEditModal(props: GrowthGoalEditModalProps) {
  const [growthGoal, setGrowthGoal] = useState<string>(props.growthGoal)

  const updateGrowthGoal = useUpdateGrowthGoal()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    updateGrowthGoal.mutate({ workerId: props.workerId, growthGoal })
  }

  useEffect(() => {
    if (updateGrowthGoal.isSuccess) {
      props.onSuccess()
    }
  }, [updateGrowthGoal.isSuccess])

  return (
    <>
      {updateGrowthGoal.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1>今月の目標を設定する</h1>
        </div>

        <form onSubmit={handleSubmit}>
          <fieldset disabled={!props.canEditAsWorker}>
            <textarea
              rows={5}
              value={growthGoal}
              onChange={(e) => setGrowthGoal(e.target.value)}
            />
            <div className="submit">
              <input type="submit" disabled={!props.canEditAsWorker} style={props.canEditAsWorker ? StButtonTypeB : StButtonTypeBDisabled} value="送信" />
            </div>
          </fieldset>
        </form>
      </div>
    </>
  )
}

export { GrowthGoalEditModal }
