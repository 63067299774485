import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useGetListRankingReport } from "../queries/TasksQuery";
import { Area } from "../types/CurriculumEditInformationResponse";
import { Brand, EmploymentType, ListReportRanking, ReportRanking as ReportRankingType, Shop } from "../types/ListReportRanking";
import useConfig from "../hooks/GetConfig";
import ReportTable from "../components/ReportTable";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet-async";
import { useAuthUser } from "../hooks/AuthUserContext";

function ReportRanking (props: {type: 'count' | 'percent'}) {

  const { authUser } = useAuthUser();

  const [brandName, setBrandName] = useState<string | undefined>(undefined)
  const [areaId, setAreaId] = useState<string | undefined>('')
  const [shopId, setShopId] = useState<string | undefined>('')
  const [employmentTypeId, setEmploymentTypeId] = useState<string | undefined>(undefined)

  const [shops, setShops] = useState<Shop[]>()
  const [brands, setBrands] = useState<Brand[]>()
  const [areas, setAreas] = useState<Area[]>()
  const [employmentTypes, setEmploymentTypes] = useState<EmploymentType[]>()

  const [listReportRanking, setListReportRanking] = useState<ReportRankingType>()
  const [hasNumericData, setHasNumericData] = useState<boolean>(props.type === 'count' ? true : false)

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

  const config = useConfig();
  
  const {
    data: reportRanking,
  } = useGetListRankingReport({ areaId }, { shopId }, { brandName }, { employmentTypeId })

  useEffect(() => {
    setAreas(reportRanking?.areas)
    setShops(reportRanking?.shops)
    setBrands(reportRanking?.brands)
    setEmploymentTypes(reportRanking?.employmentTypes)

    if (reportRanking?.areaId) {
      setAreaId(reportRanking.areaId)
      setShopId(reportRanking.shopId)
    }

  }, [reportRanking])

  const fetchShops = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setShopId('')
    setAreaId(e.target.value)
  }

  const fetchBrands = (e: { target: { value: React.SetStateAction<string | undefined> } }) => {
    setShopId(e.target.value)
  }

  const handleListReportRanking = async () => {
    setIsLoading(true);

    const payload = {
      area_id: areaId,
      shop_id: shopId,
      brand_name: brandName,
      employment_type_id: employmentTypeId,
    };
  
    try {
      const data = await makeReportRanking(payload);
      setListReportRanking(data.listReportRanking);
      setAreaId(data.areaId);
      setShopId(data.shopId);
      setBrandName(data.brandName);
      setEmploymentTypeId(data.employmentTypeId);
    } catch (error) {
      console.error('Failed to fetch ranking report:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  const makeReportRanking = async (items: any) => {
    await axios.get(`/api/sanctum/csrf-cookie`)

    const { data } = await axios.post<ListReportRanking>('/api/generate-report-ranking', items);
    return data;
  };

  useEffect(() => {
    handleListReportRanking();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div style={{ backgroundColor: '#EEEEEE' }}>
      <Helmet>
        <title>{hasNumericData ? '合格数ランキング' : '履修率ランキング'} | e-Training</title>
      </Helmet>
      <Header>
        <div className="head-report-personal">
          <Dropdown>
            <Dropdown.Toggle variant="default" id="dropdown-basic">
              ランキング
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="/report_ranking/percent">履修率ランキング</Dropdown.Item>
              <Dropdown.Item href="/report_ranking/count">合格数ランキング</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Dropdown>
            <Dropdown.Toggle variant="default" id="dropdown-basic">
              地区店舗別
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">地域別</Dropdown.Item>
              <Dropdown.Item href="#/action-1">店舗別</Dropdown.Item>
              <Dropdown.Item href="#/action-1">店舗別（王冠率）</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Button variant="default">部門レポート</Button>
          <Button variant="default">店舗レポート</Button>
          <Button variant="default">店舗人員計画</Button>
          <Button variant="default">eトレーナ育成</Button>
          <Button variant="default">カスタムレポート</Button> */}
          <Button variant="default" onClick={() => navigate(`/personal_report/${authUser?.id}`)}>個人レポート</Button>
        </div>
      </Header>
      <div className="content">
        <div className="report-header">
          <div className="report-header-left">
            <div className="report-title">{ hasNumericData ? '合格数ランキング' : '履修率ランキング'}</div>
          </div>
          <div className="report-header-right">
            <a className="btn btn-secondary btn-sm" href="/shop_select">トップに戻る</a>
          </div>
        </div>
        <div className="report-search-header">
          <div className="report-search-header-inner">
            <div className="report-search-select">
              <h3 className="select-title">地区</h3>
              <div className="report-select-box">
                <Form.Select 
                  value={areaId}
                  onChange={(e) => fetchShops(e)}
                >
                <option value="全て" className="msg">
                  -----{config?.labels?.area}を選択-----
                </option>
                {areas?.map((area) => {
                  return (
                    <option key={area.id} value={area.id}>
                      {area.name}
                    </option>
                  )
                })}
                </Form.Select>
              </div>
            </div>
            <div className="report-search-select">
              <h3 className="select-title">店舗</h3>
              <div className="report-select-box">
                <Form.Select 
                  value={shopId}
                  onChange={(e) => {
                    fetchBrands(e)
                  }}
                >
                <option value="" className="msg">
                  -----{config?.labels?.shop}を選択-----
                </option>
                {shops?.map((shop) => {
                  return (
                    <option key={shop.id} value={shop.id}>
                      {shop.name}
                    </option>
                  )
                })}
                </Form.Select>
              </div>
            </div>
            <div className="report-search-select">
              <h3 className="select-title">部門</h3>
              <div className="report-select-box">
                <Form.Select
                  value={brandName}
                  onChange={(e) => {
                    setBrandName(e.target.value)
                  }}
                >
                  <option value="" className="msg">
                    -----{config?.labels?.brand}を選択-----
                  </option>
                  {brands?.map((brand) => {
                    return (
                      <option key={brand.name} value={brand.name}>
                        {brand.name}
                      </option>
                    )
                  })}
                </Form.Select>
              </div>
            </div>
            <div className="report-search-select">
              <h3 className="select-title">職位</h3>
              <div className="report-select-box">
                <Form.Select 
                  value={employmentTypeId}
                  onChange={(e) => setEmploymentTypeId(e.target.value)}
                >
                  <option value="" className="msg">
                    -----職位を選択-----
                  </option>
                  {employmentTypes?.map((employment_type) => {
                    return (
                      <option key={employment_type.id} value={employment_type.id}>
                        {employment_type.name}
                      </option>
                    )
                  })}
                </Form.Select>
              </div>
            </div>
            <Button variant="primary" className="ms-2 px-4 btn-sm" style={{ padding: '0.338rem 0.25rem' }} onClick={handleListReportRanking}>表示</Button>
          </div>
        </div>
      </div>
      {isLoading && <Loading />}
      <div className="content-report content-report-ranking" id="content-report">
        <div className="content-report-inner">
          <div className="row gx-3">
            <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
              <ReportTable 
                title="地区別"
                headReportName="ブロック名"
                data={listReportRanking?.area ?? []} 
                hasNumericData={hasNumericData}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
              <ReportTable
                title="店舗別"
                headReportName="店舗名"
                data={listReportRanking?.shop ?? []}
                hasNumericData={hasNumericData}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
              <ReportTable
                title="部門別"
                headReportName="部門名"
                isBrand
                data={listReportRanking?.brand ?? []}
                hasNumericData={hasNumericData}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3 mb-4 mb-lg-0">
              <ReportTable
                title="職位別"
                headReportName="職位名"
                data={listReportRanking?.employmentType ?? []}
                hasNumericData={hasNumericData}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportRanking;
