import React, { FormEvent, useEffect, useState } from 'react'
import Loading from '../components/Loading'
import {
  useCreateManagerUser,
  useCreateStore1User,
  useCreateTrainerUser,
  useCreateWorkerUser,
  useGetUser,
  useUpdateManagerUser,
  useUpdateStore1User,
  useUpdateTrainerUser,
  useUpdateWorkerUser,
} from '../queries/UsersQuery'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetShops } from '../queries/ShopsQuery'
import { UserRole } from '../types/UserRole'
import useConfig from '../hooks/GetConfig'
import Header from '../components/Header'
import { Button, Card, Form } from 'react-bootstrap'

function UserEdit() {
  const { userId } = useParams()
  const {
    isLoading: isLoadingUser,
    isFetching,
    data: user,
  } = useGetUser({ userId })
  const { isLoading: isLoadingShops, data: shops } = useGetShops()
  const config = useConfig()

  const createWorkerUser = useCreateWorkerUser()
  const createTrainerUser = useCreateTrainerUser()
  const updateWorkerUser = useUpdateWorkerUser()
  const updateTrainerUser = useUpdateTrainerUser()
  const createStore1User = useCreateStore1User()
  const updateStore1User = useUpdateStore1User()
  const createManagerUser = useCreateManagerUser()
  const updateManagerUser = useUpdateManagerUser()

  const navigate = useNavigate()

  const [isShowPassword, setIsShowPassword] = useState(0);
  const [selectedEmploymentTypeIds, setSelectedEmploymentTypeIds] = useState<string[]>([]);

  const [editUser, setEditUser] = useState<{
    userId: string
    username: string
    password: string
    confirmPassword: string
    role: string
    shopId: string
    brandId: string
    name: string
    employmentTypeIds: string[]
  }>({
    userId: '',
    username: '',
    password: '',
    confirmPassword: '',
    role: '',
    shopId: '',
    brandId: '',
    name: '',
    employmentTypeIds: [''],
  })

  const [showConfirmPasswordError, setShowConfirmPasswordError] = useState(false);

  useEffect(() => {
    setEditUser({
      userId: user?.id || '',
      username: user?.username || '',
      password: '',
      confirmPassword: '',
      role: user?.role || '',
      shopId: user?.shopId || '',
      brandId: user?.brandId || '',
      name: user?.name || '',
      employmentTypeIds: user?.employmentTypeIds || [''],
    })
    setSelectedEmploymentTypeIds(user?.employmentTypeIds || []);
  }, [user])

  useEffect(() => {
    if (
      createWorkerUser.isSuccess ||
      createTrainerUser.isSuccess ||
      updateWorkerUser.isSuccess ||
      updateTrainerUser.isSuccess ||
      createStore1User.isSuccess ||
      updateStore1User.isSuccess ||
      createManagerUser.isSuccess ||
      updateManagerUser.isSuccess
    ) {
      navigate(`/users`)
    }
  }, [
    createWorkerUser.isSuccess,
    createTrainerUser.isSuccess,
    updateWorkerUser.isSuccess,
    updateTrainerUser.isSuccess,
    createStore1User.isSuccess,
    updateStore1User.isSuccess,
    createManagerUser.isSuccess,
    updateManagerUser.isSuccess,
  ])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    switch (editUser.role) {
      case UserRole.Worker:
        if (editUser.userId) {
          updateWorkerUser.mutate({
            userId: editUser.userId,
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        } else {
          createWorkerUser.mutate({
            username: editUser.username || '',
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }
        break
      case UserRole.Trainer:
        if (editUser.userId) {
          updateTrainerUser.mutate({
            userId: editUser.userId,
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        } else {
          createTrainerUser.mutate({
            username: editUser.username || '',
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }
        break
      case UserRole.Store1:
        if (editUser.userId) {
          updateStore1User.mutate({
            userId: editUser.userId,
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        } else {
          createStore1User.mutate({
            username: editUser.username || '',
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }
        break
      case UserRole.Manager:
        if (editUser.userId) {
          updateManagerUser.mutate({
            userId: editUser.userId,
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }else{
          createManagerUser.mutate({
            username: editUser.username || '',
            password: editUser.password,
            confirmPassword: editUser.confirmPassword,
            shopId: editUser.shopId || '',
            brandId: editUser.brandId || '',
            name: editUser.name || '',
            employmentTypeIds: selectedEmploymentTypeIds,
          })
        }
        break

      default:
        return
    }
  }

  useEffect(() => {
    if (editUser.confirmPassword !== editUser.password) {
      setShowConfirmPasswordError(true)
    } else {
      setShowConfirmPasswordError(false)
    }
  }, [editUser.confirmPassword]);

  return (
    <>
      <Header />
      <div className="content-main">
        <div className="d-flex flex-column">
          <h3 className="align-self-center mt-3 mb-4" style={{ fontSize: '21px' }}>
            {editUser?.userId ? '【 ユーザー変更 】' : '【 ユーザー登録 】'}
          </h3>

          {isLoadingUser || isLoadingShops || isFetching ? (
            <Loading />
          ) : (
            <form onSubmit={handleSubmit}>
              {(createWorkerUser.isLoading ||
                createTrainerUser.isLoading ||
                updateWorkerUser.isLoading ||
                updateTrainerUser.isLoading) && <Loading />}
              <fieldset>
                <Card style={{ color: '#495057' }}>
                  <Card.Body>
                    <div className="d-flex flex-column gap-2 py-1">
                      <div className="d-flex flex-column flex-sm-row">
                        <label className="col-form-label form-label-minw fw-bold">{config?.labels?.shop}</label>
                        <select
                          className="form-select"
                          value={editUser.shopId}
                          onChange={(e) =>
                            setEditUser({ ...editUser, shopId: e.target.value })
                          }
                        >
                          <option value={``}>選択してください</option>
                          {shops?.shops.map((shop) => {
                            return (
                              <option key={shop.id} value={shop.id}>
                                {shop.name}
                              </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                    {editUser.shopId &&
                      <div className="d-flex flex-column gap-2 py-1">
                        <div className="d-flex flex-column flex-sm-row">
                          <label className="col-form-label form-label-minw fw-bold">{config?.labels?.brand}</label>
                          <select
                            className="form-select"
                            value={editUser.brandId}
                            onChange={(e) =>
                              setEditUser({ ...editUser, brandId: e.target.value })
                            }
                          >
                            <option value={``}>選択してください</option>
                            {shops?.brands.filter((brand) => brand.shop_id === editUser.shopId).map((brand) => {
                              return (
                                <option key={brand.id} value={brand.id}>
                                  {brand.name}
                                </option>
                              )
                            })}
                          </select>
                        </div>
                      </div>
                    }
                    <div className="d-flex flex-column gap-2 py-1">
                      <div className="d-flex flex-column flex-sm-row">
                        <label className="col-form-label form-label-minw fw-bold">ロール</label>
                        <select
                          className="form-select"
                          value={editUser.role}
                          onChange={(e) =>
                            setEditUser({ ...editUser, role: e.target.value })
                          }
                        >
                          <option>選択してください</option>
                          <option value={UserRole.Worker}>スタッフ</option>
                          <option value={UserRole.Trainer}>トレーナー（店長）</option>
                          <option value={UserRole.Store1}>トレーナー・店長（※一斉追加権限有）</option>
                          <option value={UserRole.Manager}>マネージャー</option>
                        </select>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-start flex-sm-row py-1">
                      <label className="col-form-label form-label-minw fw-bold">職位</label>
                      <div className="d-flex flex-wrap gapx-3 w-100">
                        <div className="flex-fill">
                          {shops?.employmentTypes.map((employmentType) => (
                            <div className="form-check align-items-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                value={employmentType.id}
                                checked={selectedEmploymentTypeIds.includes(employmentType.id)}
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedEmploymentTypeIds([...selectedEmploymentTypeIds, employmentType.id]);
                                  } else {
                                    setSelectedEmploymentTypeIds(selectedEmploymentTypeIds.filter((id) => id !== employmentType.id));
                                  }
                                }}
                              />
                              <label className="form-check-label text-small">{employmentType.name}</label>
                            </div>
                          ))}
                        </div>
                      </div>

                    </div>
                    <div className="d-flex flex-column justify-content-start flex-sm-row py-1">
                      <label className="col-form-label form-label-minw fw-bold">ユーザーID</label>
                      <div className="d-flex w-100">
                        <Form.Control
                          type="text"
                          name="password"
                          value={editUser.username}
                          disabled={editUser.userId ? true : false}
                          max={255}
                          onChange={(e) =>
                            setEditUser({ ...editUser, username: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-start flex-sm-row py-1">
                      <label className="col-form-label form-label-minw fw-bold">パスワード</label>
                      <div className="d-flex flex-column w-100">
                        {editUser.userId ? (
                          <p style={{ fontSize: '11px', color: '#FF0000', paddingBottom: '2px' }}>
                            ※変更する場合のみ入力
                          </p>
                        ) : (
                          ''
                        )}
                        <Form.Control
                          type={isShowPassword ? 'text' : 'password'}
                          name="password"
                          onChange={(e) =>
                            setEditUser({ ...editUser, password: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-start flex-sm-row py-1">
                      <label className="col-form-label form-label-minw fw-bold">パスワード（確認）</label>
                      <div className="d-flex flex-column w-100">
                        {editUser.userId ? (
                          <p style={{ fontSize: '11px', color: '#FF0000', paddingBottom: '2px'  }}>
                            ※変更する場合のみ入力
                          </p>
                        ) : (
                          ''
                        )}
                        <Form.Control
                          type="password"
                          onChange={(e) =>
                            setEditUser({ ...editUser, confirmPassword: e.target.value })
                          } 
                        />
                          {showConfirmPasswordError ? <div className="error-message" >パスワードが上記と一致しません</div> : ''}
                        <div className="form-check align-items-center mt-1">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={isShowPassword}
                            id="showPassword"
                            onChange={(e) => setIsShowPassword(e.target.checked ? 1 : 0)}
                          />
                          <label className="form-check-label text-small">パスワード表示する</label>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-column justify-content-start flex-sm-row py-1">
                      <label className="col-form-label form-label-minw fw-bold">名前</label>
                      <div className="d-flex w-100">
                        <Form.Control
                          type="text"
                          max={255}
                          value={editUser.name}
                          onChange={(e) =>
                            setEditUser({ ...editUser, name: e.target.value })
                          }
                        />
                      </div>
                    </div>
                  </Card.Body>
                  <Card.Footer style={{ backgroundColor: '#fff' }}>
                    <div className="d-flex  align-items-center justify-content-center gap-2">
                      <Button href={`/users`} variant="default">キャンセル</Button>
                      <Button type="submit">登録する</Button>
                    </div>
                  </Card.Footer>
                </Card>
              </fieldset>
            </form>
          )}
        </div>
      </div>
    </>
  )
}

export default UserEdit
