import * as api from "../api/UsersAPI";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

interface GetUsersParams {
  page?: number;
  limit?: number;
  search?: string;
}

const useGetUsers = ({ page = 1, limit, search }: GetUsersParams) => {
  return useQuery(
    ["getUsers", page, limit, search],
    () => api.getUsers({ page, limit, search })
  );
}

const useGetUser = ({ userId }: { userId: string | undefined }) => {
  return useQuery(["getUser"], () => api.getUser({ userId }));
};

const useGetPasswordConfig = () => {
  return useQuery(["getPasswordConfig"], () => api.getPasswordConfig());
}

const useCreateWorkerUser = () => {
  return useMutation(api.createWorkerUser, {
    onSuccess: () => {
      toast.success("スタッフを作成しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "スタッフの作成に失敗しました。"
      );
    },
  });
};

const useUpdateWorkerUser = () => {
  return useMutation(api.updateWorkerUser, {
    onSuccess: () => {
      toast.success("スタッフを更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "スタッフの更新に失敗しました。"
      );
    },
  });
};

const useCreateTrainerUser = () => {
  return useMutation(api.createTrainerUser, {
    onSuccess: () => {
      toast.success("トレーナー（店長）を作成しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナー（店長）の作成に失敗しました。"
      );
    },
  });
};

const useUpdateTrainerUser = () => {
  return useMutation(api.updateTrainerUser, {
    onSuccess: () => {
      toast.success("トレーナー（店長）を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナー（店長）の更新に失敗しました。"
      );
    },
  });
};

const useCreateStore1User = () => {
  return useMutation(api.createStore1User, {
    onSuccess: () => {
      toast.success("トレーナー（店長）を作成しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナー（店長）の作成に失敗しました。"
      );
    },
  });
};

const useUpdateStore1User = () => {
  return useMutation(api.updateStore1User, {
    onSuccess: () => {
      toast.success("トレーナー（店長）を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナー（店長）の更新に失敗しました。"
      );
    },
  });
};

const useCreateManagerUser = () => {
  return useMutation(api.createManagerUser, {
    onSuccess: () => {
      toast.success("トレーナー（店長）を作成しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナー（店長）の作成に失敗しました。"
      );
    },
  });
};

const useUpdateManagerUser = () => {
  return useMutation(api.updateManagerUser, {
    onSuccess: () => {
      toast.success("トレーナー（店長）を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナー（店長）の更新に失敗しました。"
      );
    },
  });
};

const useDeleteUser = () => {
  return useMutation(api.deleteUser, {
    onSuccess: () => {
      toast.success("ユーザーを削除しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "ユーザーの削除に失敗しました。"
      );
    },
  });
};

const useResetPassword = () => {
  return useMutation(api.resetPassword, {
    onSuccess: () => {
      toast.success("パスワードをリセットしました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "パスワードのリセットに失敗しました。"
      );
    },
  });
};

const useChangePassword = () => {
  return useMutation(api.changePassword, {
    onSuccess: () => {
      toast.success("パスワードを変更しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "パスワードの変更に失敗しました。"
      );
    },
  });
};

export {
  useGetUsers,
  useGetUser,
  useGetPasswordConfig,
  useCreateWorkerUser,
  useUpdateWorkerUser,
  useCreateTrainerUser,
  useUpdateTrainerUser,
  useDeleteUser,
  useResetPassword,
  useChangePassword,
  useCreateStore1User,
  useUpdateStore1User,
  useCreateManagerUser,
  useUpdateManagerUser,
};
