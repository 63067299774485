import React from 'react';
import { Ranking } from '../types/ListReportRanking';

interface ReportTableProps {
  title: string;
  headReportName?: string;
  data: Ranking[] | [];
  hasNumericData?: boolean;
  isBrand?: boolean;
}

const ReportTable: React.FC<ReportTableProps> = ({ title, headReportName, data, hasNumericData, isBrand = false }) => {
  let sortedData = [...data];

  if (hasNumericData) {
    sortedData.sort((a, b) => b.step_done_count - a.step_done_count);
  }

  const activeRow = sortedData.findIndex((item) => item.is_user);

  return (
    <div className="scroll-box" style={{ height: 'calc(100vh - 283px)' }}>
      <table className="table-report-list table-header-fixed" cellSpacing="0">
        <thead>
          <tr>
            <td className="sticky-row" colSpan={4}>
              <div className="d-flex align-items-center justify-content-between">
                <div className="flex-fill text-center">{title}</div>
                <button className="btn btn-default btn-xs moverow-button d-none">自{title}</button>
              </div>
            </td>
          </tr>
          <tr>
            <td className="sticky-row-2 report-col-no">順位</td>
            <td className="sticky-row-2 report-col-name">{headReportName}</td>
            <td className="sticky-row-2 report-col-rate">{hasNumericData ? '合格数' : '履修率'}</td>
            <td className="sticky-row-2 report-col-diff">前月差</td>
          </tr>
          {activeRow !== null && activeRow !== undefined && sortedData.length > 1 && activeRow > 0 && sortedData[activeRow] && (
            <tr className="active-row fw-bold">
              <td className="sticky-row-3 report-col-no">{activeRow + 1}</td>
              {isBrand ? (
                <td className="sticky-row-3 report-col-name">{sortedData[activeRow]?.abrev ?? sortedData[activeRow]?.name.substring(0,4)}</td>
              ) : (
                <td className="sticky-row-3 report-col-name">{sortedData[activeRow]?.name}</td>
              )}
              {hasNumericData ? (
                <>
                  <td className="sticky-row-3 report-col-diff">{sortedData[activeRow]?.step_done_count}</td>
                  <td className="sticky-row-3 report-col-rate">{sortedData[activeRow]?.diff_step_count}</td>
                </>
              ) : (
                <>
                  <td className="sticky-row-3 report-col-diff">{sortedData[activeRow]?.rate}%</td>
                  <td className="sticky-row-3 report-col-rate">{sortedData[activeRow]?.diff}%</td>
                </>
              )}
            </tr>
          )}
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={item.id} className={`${item.is_user ? 'active-row fw-bold' : ''}`}>
              <td className="report-col-no">{index + 1}</td>
              {isBrand ? (
                <td className="report-col-name">{item.abrev ?? item.name.substring(0,4)}</td>
              ) : (
                <td className="report-col-name">{item.name}</td>
              )}
              {hasNumericData ? (
                <>
                  <td className="report-col-diff">{item.step_done_count}</td>
                  <td className="report-col-diff">{item.diff_step_count}</td>
                </>
              ) : (
                <>
                  <td className="report-col-diff">{item.rate}%</td>
                  <td className="report-col-diff">{item.diff}%</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
