import { useState, useEffect } from "react";
import CryptoJS from "crypto-js";

type Config = {
    [domain: string]: {
        secureLoginDisabled: string;
        labels: Labels;
        hideFormButtons: string;
    };
};

type Labels = {
    brand: string;
    shop: string;
    area: string;
};

const useConfig = () => {
    const [config, setConfig] = useState<Config>({});
    const key = CryptoJS.enc.Hex.parse("0123456789abcdef0123456789abcdef");
    const iv = CryptoJS.enc.Hex.parse("abcdef9876543210abcdef9876543210");

    useEffect(() => {
      fetch(`/config.aes`)
      .then((response) => response.text())
      .then((encryptedConfig) => {
        const decryptedConfig = CryptoJS.AES.decrypt(encryptedConfig, key, { iv: iv, padding: CryptoJS.pad.ZeroPadding }).toString(CryptoJS.enc.Utf8);
        const parsedConfig = JSON.parse(decryptedConfig);
        setConfig(parsedConfig);
      });
    }, []);

    const domainName = window.location.hostname.split(".")[0];

    if(!domainName) {
      throw new Error(`Domain name not found`);
    }

    let domainConfig = config[domainName] || config['default'];

    if (domainConfig) {
      let defaultDomainConfig = config?.default;
      if (defaultDomainConfig) {
          if (domainConfig.secureLoginDisabled === undefined) {
              domainConfig.secureLoginDisabled = defaultDomainConfig.secureLoginDisabled;
          }

          if (domainConfig.hideFormButtons === undefined) {
              domainConfig.hideFormButtons = defaultDomainConfig.hideFormButtons;
          }

          if (!domainConfig.labels) {
              domainConfig.labels = defaultDomainConfig.labels;
          } else {
              if (!domainConfig.labels.brand) {
                  domainConfig.labels.brand = defaultDomainConfig.labels.brand;
              }

              if (!domainConfig.labels.shop) {
                  domainConfig.labels.shop = defaultDomainConfig.labels.shop;
              }

              if (!domainConfig.labels.area) {
                  domainConfig.labels.area = defaultDomainConfig.labels.area;
              }
          }
      }
    }

    return domainConfig;
};

export default useConfig;
