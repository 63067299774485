import React, { createContext, useState, useContext, ReactNode } from "react";
import { AuthUser } from "../types/AuthUser";

type AuthUserContextProps = {
  authUser: AuthUser | undefined;
  setAuthUser: React.Dispatch<React.SetStateAction<AuthUser | undefined>>;
};

const AuthUserContext = createContext<AuthUserContextProps>({
  authUser: undefined,
  setAuthUser: () => {},
});

export const AuthUserProvider: React.VFC<{ children: ReactNode }> = ({
  children,
}) => {
  const [authUser, setAuthUser] = useState<AuthUser | undefined>(undefined);

  return (
    <AuthUserContext.Provider value={{ authUser, setAuthUser }}>
      {children}
    </AuthUserContext.Provider>
  );
};

export const useAuthUser = () => useContext(AuthUserContext);
