export const TaskType = {
  AimSetting: "1",
  SelfCheck: "2",
  MovieWatching: "3",
  SheetReading: "4",
  Practice: "5",
  Feedback: "6",
  PracticeFeedback: "7",
  Form: "8",
} as const;

export type TaskType = typeof TaskType[keyof typeof TaskType];
