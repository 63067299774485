import axios from 'axios'
import { TaskInstance } from '../types/TaskInstance'
import { ListReportRanking } from '../types/ListReportRanking'

const updateAim = async ({
  workerId,
  stepId,
  taskId,
  aim,
  startAt,
  endAt,
}: {
  workerId: string
  stepId: string
  taskId: string
  aim: string
  startAt: string
  endAt: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/aim`, {
    workerId,
    stepId,
    taskId,
    aim,
    startAt,
    endAt,
  })
  return data
}

const updateSelfChecks = async ({
  workerId,
  taskId,
  selfCheckItemIds,
  confirm,
}: {
  workerId: string
  taskId: string
  selfCheckItemIds: Array<string>
  confirm: boolean
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/selfChecks`, {
    workerId,
    taskId,
    checkedByWorkerItemIds: selfCheckItemIds,
    confirm,
  })
  return data
}

const updateTrainerChecks = async ({
  workerId,
  taskId,
  trainerCheckedItemIds,
  trainerComment,
  confirm,
}: {
  workerId: string
  taskId: string
  trainerCheckedItemIds: Array<string>
  trainerComment: string
  confirm: boolean
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/selfChecks/trainer`, {
    workerId,
    taskId,
    checkedByTrainerItemIds: trainerCheckedItemIds,
    trainerComment,
    confirm,
  })
  return data
}

const markMovieAsWatched = async ({
  workerId,
  taskId,
}: {
  workerId: string
  taskId: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/markMovieAsWatched`, {
    workerId,
    taskId,
  })
  return data
}

const markSheetAsRead = async ({
  workerId,
  taskId,
}: {
  workerId: string
  taskId: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/markSheetAsRead`, {
    workerId,
    taskId,
  })
  return data
}

const updatePracticeWorkerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/practice`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updateFeedbackWorkerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/feedback`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updatePracticeFeedbackWorkerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/practiceFeedback`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updateFeedbackTrainerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/feedback/trainer`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const updatePracticeFeedbackTrainerComment = async ({
  workerId,
  taskId,
  comment,
}: {
  workerId: string
  taskId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.put<{}>(`/api/tasks/practiceFeedback/trainer`, {
    workerId,
    taskId,
    comment: comment,
  })
  return data
}

const getTaskInformation = async (workerId: string | undefined, taskId: string | undefined) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.get<TaskInstance>(`/api/worker/${workerId}/task/${taskId}/related-content`)

  return data
}

const updateCurriculumCertificate = async ({
  workerId,
  curriculumId,
  comment,
}: {
  workerId: string
  curriculumId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/curriculum/certificate`, {
    workerId,
    curriculumId,
    comment: comment,
  })
  return data
}

const revokeCurriculumCertificate = async ({
  workerId,
  curriculumId,
}: {
  workerId: string
  curriculumId: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/curriculum/certificate/revoke`, {
    workerId,
    curriculumId,
  })
  return data
}

const updateStepCertificate = async ({
  workerId,
  stepId,
  comment,
}: {
  workerId: string
  stepId: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/step/certificate`, {
    workerId,
    stepId,
    comment: comment,
  })
  return data
}

const revokeStepCertificate = async ({
  workerId,
  stepId,
}: {
  workerId: string
  stepId: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/step/certificate/revoke`, {
    workerId,
    stepId
  })
  return data
}

const updateAimCurriculum = async ({
  workerId,
  curriculumId,
  start,
  end,
  comment,
}: {
  workerId: string
  curriculumId: string
  start: string
  end: string
  comment: string
}) => {
  await axios.get(`/api/sanctum/csrf-cookie`)

  const { data } = await axios.post<{}>(`/api/curriculum/aim`, {
    workerId,
    curriculumId,
    start,
    end,
    comment: comment,
  })
  return data
}

const getListRankingReport = async (
  areaId: string | undefined,
  shopId: string | undefined,
  brandName: string | undefined,
  employmentTypeId: string | undefined
) => {
  let url = `/api/report-ranking?`
  if (areaId) {
    url += `area_id=${areaId}&`
  }
  if (shopId) {
    url += `shop_id=${shopId}&`
  }
  if(brandName) {
    url += `brand_name=${brandName}&`
  }
  if(employmentTypeId) {
    url += `employment_type_id=${employmentTypeId}`
  }

  const { data } = await axios.get<ListReportRanking>(url);

  return data;
};

export {
  updateAim,
  updateSelfChecks,
  updateTrainerChecks,
  markMovieAsWatched,
  markSheetAsRead,
  updatePracticeWorkerComment,
  updateFeedbackWorkerComment,
  updatePracticeFeedbackWorkerComment,
  updateFeedbackTrainerComment,
  updatePracticeFeedbackTrainerComment,
  getTaskInformation,
  updateCurriculumCertificate,
  revokeCurriculumCertificate,
  updateStepCertificate,
  revokeStepCertificate,
  updateAimCurriculum,
  getListRankingReport
}
