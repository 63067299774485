import { FormEvent, useEffect, useState } from 'react'
import { useUpdateShopGrowthGoal } from '../../queries/ShopsQuery'
import Loading from '../Loading'
import {StButtonTypeA, StButtonTypeB, StButtonTypeC, StButtonTypeBDisabled} from '../../style/styles'


type ShopGrowthGoalEditModalProps = {
  shopId: string
  growthGoal: string
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function ShopGrowthGoalEditModal(props: ShopGrowthGoalEditModalProps) {
  const [growthGoal, setGrowthGoal] = useState<string>(props.growthGoal)

  const updateShopGrowthGoal = useUpdateShopGrowthGoal()

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    updateShopGrowthGoal.mutate({
      shopId: props.shopId,
      growthGoal,
    })
  }

  useEffect(() => {
    if (updateShopGrowthGoal.isSuccess) {
      props.onSuccess()
    }
  }, [updateShopGrowthGoal.isSuccess])

  return (
    <div className="modal">
      {updateShopGrowthGoal.isLoading ? <Loading /> : ''}
      <div className="modal-close">
        <button type="button" className="modal-close-button" onClick={props.closeModal}>
          <img src="/images/circle-xmark.svg" className="modal-close-icon" />
        </button>
      </div>
      <div className="modal-header">
        <h1>今月の目標を設定する</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <fieldset disabled={!props.canEditAsTrainer}>
          <textarea
            rows={5}
            value={growthGoal}
            onChange={(e) => setGrowthGoal(e.target.value)}
          />
          <div className="submit">
            <input type="submit" disabled={!props.canEditAsTrainer} style={props.canEditAsTrainer ? StButtonTypeB : StButtonTypeBDisabled} value="送信" />
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export { ShopGrowthGoalEditModal }
