import { UserRole } from '../types/UserRole'
import { Navigate, useLocation } from 'react-router-dom'
import { useAuthUser } from '../hooks/AuthUserContext'
import React from 'react'

type Props = {
  component: React.ReactNode
  redirect: string
  denyTentativePassword: boolean
  allowRoles?: UserRole[]
}

export const RouteAuthGuard: React.VFC<Props> = (props) => {
  const { authUser } = useAuthUser()
  const location = useLocation()

  const authenticated = props.allowRoles
    ? authUser?.id !== undefined && props.allowRoles.includes(authUser.role)
    : authUser?.id !== undefined
  const shouldChangePassword = authUser?.shouldChangePassword

  if (!authenticated) {
    return (
      <Navigate
        to={props.redirect}
        state={{ from: location }}
        replace={false}
      />
    )
  }

  if (props.denyTentativePassword && shouldChangePassword) {
    return (
      <Navigate
        to={`/password_change`}
        state={{ from: location }}
        replace={false}
      />
    )
  }

  return <>{props.component}</>
}
