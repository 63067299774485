import { useLogout } from '../queries/AuthQuery'
import { useAuthUser } from '../hooks/AuthUserContext'
import { Button } from 'react-bootstrap'

type HeaderProps = {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const { authUser } = useAuthUser()
  const logout = useLogout()

  return (
    <header id="content-header">
      <table>
        <tbody>
          <tr className="header-row-top">
            <td className="head-logo">
              <img src="/img/calc07.png" alt="logo.png" />
            </td>
            <td className="head-name">
              {children}
            </td>
            <td className="head-nav">
              <div className="head-nav-inner">
                <div className="head-loginuser">
                  {authUser?.username}({authUser?.role}) でログイン中
                </div>
                <Button
                  variant="default"
                  size="sm"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    logout.mutate()
                  }}
                >
                  ログアウト
                </Button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </header>
  )
}

export default Header
