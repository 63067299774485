import { useEffect, useState } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Login from './pages/Login'
import ShopSelect from './pages/ShopSelect'
import WorkerList from './pages/WorkerList'
import { useGetAuthUser } from './queries/AuthQuery'
import { RouteAuthGuard } from './components/RouteAuthGuard'
import { useAuthUser } from './hooks/AuthUserContext'
import WorkerDetail from './pages/WorkerDetail'
import Loading from './components/Loading'
import UserList from './pages/UserList'
import PasswordReset from './pages/PasswordReset'
import PasswordChange from './pages/PasswordChange'
import Home from './pages/Home'
import Logout from './pages/Logout'
import UserEdit from './pages/UserEdit'
import AuthStatusHeader from './components/AuthStatusHeader'
import { UserRole } from './types/UserRole'
import CurriculumEdit from './pages/CurriculumEdit'
import PersonalReport from './pages/PersonalReport'
import ReportRanking from './pages/ReportRanking'
import CsvOutput from './pages/CsvOutput'
import CurriculumDisplaySetting from './pages/CurriculumDisplaySetting'

function Router() {
  const { isLoading, isSuccess, data: user } = useGetAuthUser()
  const { authUser, setAuthUser } = useAuthUser()
  const [ready, setReady] = useState<boolean>(false)

  useEffect(() => {
    setAuthUser(user)
  }, [user])

  useEffect(() => {
    if (isSuccess) {
      setReady(true)
    }
  }, [authUser])

  return (
    <>
      {isLoading || (authUser === undefined && !ready) ? (
        <Loading />
      ) : (
        <BrowserRouter>
          {authUser?.id && <AuthStatusHeader />}
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/logout"
              element={
                <RouteAuthGuard
                  component={<Logout />}
                  redirect="/login"
                  denyTentativePassword={false}
                />
              }
            />
            <Route path="/password_reset" element={<PasswordReset />} />
            <Route path="/" element={<Home />} />
            <Route
              path="/shop_select"
              element={
                <RouteAuthGuard
                  component={<ShopSelect />}
                  redirect="/login"
                  denyTentativePassword={true}
                  allowRoles={[UserRole.Admin, UserRole.Manager, UserRole.Store1, UserRole.Trainer]}
                />
              }
            />
            <Route
              path="/curriculum_edit"
              element={
                <RouteAuthGuard
                  component={<CurriculumEdit />}
                  redirect="/login"
                  denyTentativePassword={true}
                  allowRoles={[UserRole.Admin]}
                />
              }
            />
            <Route
              path="/shop/:shopId/worker_list/:brandId"
              element={
                <RouteAuthGuard
                  component={<WorkerList />}
                  redirect="/login"
                  denyTentativePassword={true}
                />
              }
            />
            <Route
              path="/worker_detail/:workerId"
              element={
                <RouteAuthGuard
                  component={<WorkerDetail />}
                  redirect="/login"
                  denyTentativePassword={true}
                />
              }
            />
            <Route
              path="/password_change"
              element={
                <RouteAuthGuard
                  component={<PasswordChange />}
                  redirect="/login"
                  denyTentativePassword={false}
                />
              }
            />
            <Route
              path="/users"
              element={
                <RouteAuthGuard
                  component={<UserList />}
                  redirect="/login"
                  denyTentativePassword={false}
                  allowRoles={[UserRole.Admin]}
                />
              }
            />
            <Route
              path="/users/create"
              element={
                <RouteAuthGuard
                  component={<UserEdit />}
                  redirect="/login"
                  denyTentativePassword={false}
                  allowRoles={[UserRole.Admin]}
                />
              }
            />
            <Route
              path="/users/update/:userId"
              element={
                <RouteAuthGuard
                  component={<UserEdit />}
                  redirect="/login"
                  denyTentativePassword={false}
                  allowRoles={[UserRole.Admin]}
                />
              }
            />
            <Route
              path="/personal_report/:targetWorkerId"
              element={
                <RouteAuthGuard
                  component={<PersonalReport />}
                  redirect="/login"
                  denyTentativePassword={false}
                />
              }
            />
            <Route
              path="/report_ranking/count"
              element={
                <RouteAuthGuard
                  component={<ReportRanking type="count" />}
                  redirect="/login"
                  denyTentativePassword={false}
                  allowRoles={[UserRole.Admin, UserRole.Manager]}
                />
              }
            />
            <Route
              path="/report_ranking/percent"
              element={
                <RouteAuthGuard
                  component={<ReportRanking type="percent" />}
                  redirect="/login"
                  denyTentativePassword={false}
                  allowRoles={[UserRole.Admin, UserRole.Manager]}
                />
              }
            />
            <Route
              path="/csv_output/:type"
              element={
                <RouteAuthGuard
                  component={<CsvOutput />}
                  redirect="/login"
                  denyTentativePassword={false}
                  allowRoles={[UserRole.Admin, UserRole.Manager]}
                />
              }
            />
            <Route
              path="/bulk_editing/shop/:shopId/worker_list/:brandId/:type"
              element={
                <RouteAuthGuard
                  component={<WorkerList isBulkEditing />}
                  redirect="/login"
                  denyTentativePassword={true}
                />
              }
            />
            <Route
              path="/setting/curriculum_display_preference"
              element={
                <RouteAuthGuard
                  component={<CurriculumDisplaySetting />}
                  redirect="/login"
                  denyTentativePassword={true}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      )}
    </>
  )
}

export default Router
