import { FormEvent, useEffect, useState } from 'react'
import Loading from '../Loading'
import { useUpdateCurriculumSchedule, useGetCurriculumUpdate } from '../../queries/WorkersQuery'
import { ScFormItem, StButtonTypeB } from '../../style/styles'
import ReactLoading from "react-loading";

type ReloadRelationCurriculumModalProps = {
  onSuccess: () => void
  closeModal: () => void
}

function ReloadRelationCurriculumModal(props: ReloadRelationCurriculumModalProps) {

  const reloadRelationCurriculum = useUpdateCurriculumSchedule()
  const {
    isLoading,
    isFetching,
    data: getCurriculumUpdateResponse,
  } = useGetCurriculumUpdate()

  const [curriculumId, setCurriculumId] = useState<string>('');
  const [hasItems, setHasItems] = useState<boolean>(false);

  useEffect(() => {
    if (getCurriculumUpdateResponse && getCurriculumUpdateResponse.length > 0) {
      setHasItems(true);
    } else {
      setHasItems(false);
    }
  }, [getCurriculumUpdateResponse]);


  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    reloadRelationCurriculum.mutate({
      curriculumId: curriculumId
    })
  }

  useEffect(() => {
    if (reloadRelationCurriculum.isSuccess) {
      props.onSuccess()
    }
  }, [reloadRelationCurriculum.isSuccess])

  return (
    <div className="modal">
      <link rel="stylesheet" href="/css/components/modal/reload_curriculum_relation_modal.css" />
      <div className="modal-close">
        <button type="button" className="modal-close-button" onClick={props.closeModal}>
          <img src="/images/circle-xmark.svg" className="modal-close-icon" />
        </button>
      </div>
      <div className="modal-header">
        <h1>カリキュラム情報更新</h1>
      </div>

      <form onSubmit={handleSubmit}>
        {/* {reloadRelationCurriculum.isLoading && <Loading />} */}
        <fieldset style={{ textAlign: 'center' }}>
          <ScFormItem>
            <p>Curriculum ID:</p>
            <input
              style={{
                margin: '10px auto',
                padding: '10px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '5px',
              }}

              type="text" value={curriculumId} onChange={(e) => setCurriculumId(e.target.value)} />
          </ScFormItem>
          <div style={{ color: 'red', fontSize: '12px', marginBottom: '10px' }}>
            注意：この操作には数分かかる場合があります。
          </div>

          <div className="submit">
            <input type="submit" style={StButtonTypeB} value="送信" />
          </div>
        </fieldset>
      </form>

      <div style={{ marginTop: 25 }}>
        <h3>カリキュラム情報更新履歴：</h3>

        {(isLoading || isFetching) ?
          <ReactLoading type="spin" height={30} width={30} />
          :
          (hasItems ?
            <div className="table-container">
              <table className="status-table">
                <thead>
                  <tr>
                    <td>ユーザー</td>
                    <td>カリキュラムID</td>
                    <td>対象スタッフ人数</td>
                    <td>状況</td>
                    <td>最新更新</td>
                  </tr>
                </thead>
                <tbody>
                  {getCurriculumUpdateResponse?.map((curriculumUpdate: any) => (
                    <tr key={curriculumUpdate.id}>
                      <td>{curriculumUpdate.username}</td>
                      <td>{curriculumUpdate.curriculum_id}</td>
                      <td>{curriculumUpdate.total_workers}人</td>
                      <td>{curriculumUpdate.status}</td>
                      <td>{curriculumUpdate.updated_at}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            :
            <div style={{ marginTop: 20, textAlign: 'center' }}>更新履歴がありません。</div>
          )
        }
      </div>
    </div>
  )
}

export { ReloadRelationCurriculumModal }
