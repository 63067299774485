import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Pagination } from 'react-bootstrap';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

interface CustomPaginationProps {
  pagination: {
    current_page: number
    from: number
    last_page: number
    links: {
      url: string | null
      label: string
      active: boolean
    }[]
    path: string
    per_page: number
    to: number
    total: number
    first_page_url: string
    last_page_url: string
    next_page_url: string | null
    prev_page_url: string | null
  };
  onPageChange: (page: number) => void; // Modificado para aceitar um número
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ pagination, onPageChange }) => {
  const getPageFromUrl = (url: string | null): number | null => {
    if (!url) return null;

    const parsedUrl = new URL(url);
    const page = parsedUrl.searchParams.get('page');

    return page ? parseInt(page) : null;
  };

  const pageNumbers = [];
  const startPage = pagination.current_page - 2 > 0 ? pagination.current_page - 2 : 1;
  const endPage = pagination.current_page + 2 <= pagination.last_page ? pagination.current_page + 2 : pagination.last_page;

  for (let number = startPage; number <= endPage; number++) {
    pageNumbers.push(
      <Pagination.Item
        key={number}
        active={number === pagination.current_page}
        onClick={() => onPageChange(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className="fs-sm mb-0 mb-0 flex-wrap">
      <Pagination.First
        onClick={() => onPageChange(getPageFromUrl(pagination.first_page_url) ?? 1)}
        disabled={pagination.current_page === 1}
      />
      <Pagination.Prev
        onClick={() => onPageChange(getPageFromUrl(pagination.prev_page_url) ?? Math.max(1, pagination.current_page - 1))}
        disabled={pagination.current_page === 1}
      />
      {pageNumbers}
      <Pagination.Next
        onClick={() => onPageChange(getPageFromUrl(pagination.next_page_url) ?? Math.min(pagination.last_page, pagination.current_page + 1))}
        disabled={pagination.current_page === pagination.last_page}
      />
      <Pagination.Last
        onClick={() => onPageChange(getPageFromUrl(pagination.last_page_url) ?? pagination.last_page)}
        disabled={pagination.current_page === pagination.last_page}
      />
    </Pagination>
  );
}

export default CustomPagination;
