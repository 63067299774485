import * as api from "../api/AuthAPI";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import { useAuthUser } from "../hooks/AuthUserContext";

const useGetAuthUser = () => {
  return useQuery("users", api.getAuthUser);
};

const useLogin = () => {
  const { setAuthUser } = useAuthUser();
  return useMutation(api.login, {
    onSuccess: (user) => {
      if (user?.id) {
        setAuthUser(user);
      }
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.message || "ログインに失敗しました。");
    },
  });
};

const useLogout = () => {
  const { setAuthUser } = useAuthUser();
  return useMutation(api.logout, {
    onSuccess: () => {
      setAuthUser(undefined);
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "ログアウトに失敗しました。"
      );
    },
  });
};

export { useGetAuthUser, useLogin, useLogout };
