import React from "react";
import ReactLoading from "react-loading";

function Loading() {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        zIndex: 9,
        backgroundColor: "#000",
        opacity: 0.5,
      }}
    >
      <ReactLoading type="spin" />
    </div>
  );
}

export default Loading;
