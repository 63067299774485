import axios from "axios";
import { AuthUser } from "../types/AuthUser";

const getAuthUser = async () => {
    const { data } = await axios.get<AuthUser>(`/api/authUser`);
    return data;
};

const login = async ({
    username,
    password,
}: {
    username: string;
    password: string;
}) => {
    await axios.get(`/api/sanctum/csrf-cookie`);

    const { data } = await axios.post<AuthUser>(`/api/auth/login`, {
        username: username,
        password: password,
    });
    return data;
};

const logout = async () => {
    await axios.get(`/api/sanctum/csrf-cookie`);

    const { data } = await axios.post<AuthUser>(`/api/auth/logout`);
    return data;
};

export { getAuthUser, login, logout };
