import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import { Button, Card, Col, Dropdown, Form, Row } from "react-bootstrap";
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartOptions } from 'chart.js';
import axios from "axios";
import Loading from "../components/Loading";
import { Helmet } from "react-helmet-async";
import { useAuthUser } from "../hooks/AuthUserContext";
import { UserRole } from "../types/UserRole";
import { useParams } from "react-router-dom";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface CrownProgressTable {
  brandName: string;
  curriculumCount: number;
  completedCount: number | null;
  completedRate: string | null;
  certifiedCompletedCount: number | null;
  certifiedCompletedRate: string | null;
}

interface CurriculumProgressTable {
  brandName: string;
  curriculumName: string;
  remainingDays: number;
  curriculumRate: string;
  videoRate: string;
  selfCheckRate: string;
  trainerCheckRate: string;
}

interface Brand {
  id: number;
  name: string;
}

interface WorkerMark {
  brandName?: string;
  workerName?: string;
  workerDuration?: string;
  roundedWorkerDuration?: string;
  estimatedCurriculumCount?: number;
  estimatedCurriculumMonth?: string;
  completedCurriculumCount?: number;
  diffCurriculumCount?: number;
}

const PersonalReport: React.VFC = () => {

  const { authUser } = useAuthUser();
  const { targetWorkerId } = useParams();
  const [ workerId ] = useState<string>(authUser?.role === UserRole.Worker ? authUser?.id : targetWorkerId || '');
  const [ workerName, setWorkerName ] = useState<string>('');
 
  const [isLoading, setIsLoading] = useState(false);

  const [targetWorkers, setTargetWorkers] = useState<string[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);

  const [targetWorker, setTargetWorker] = useState<string>('');
  const [brandIdCurriculumProgress, setBrandIdCurriculumProgress] = useState('');
  const [brandIdCurriculumMark, setBrandIdCurriculumMark] = useState('');

  const [crownProgressData, setCrownProgressData] = useState<CrownProgressTable[]>([]);
  const [curriculumProgressData, setCurriculumProgressData] = useState<CurriculumProgressTable[]>([]);

  const [curriculumMonths, setCurriculumMonths] = useState<string[]>([]);
  const [curriculumCounts, setCurriculumCounts] = useState<number[]>([]);

  const [workerMark, setWorkerMark] = useState<WorkerMark>();

  const hasRoleManagerAdmin = authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin
  interface CustomPointOptions {
    pointRadius?: number;
    backgroundColor: string;
    borderColor: string;
  }
  
  const customPointCanvas = function(context: CanvasRenderingContext2D, options: CustomPointOptions): HTMLCanvasElement {
    const cvs = document.createElement('canvas');
    const ctx = cvs.getContext('2d');
    if (!ctx) {
      throw new Error('Failed to get canvas context');
    }
    const radius = options.pointRadius || 5;
    cvs.height = 2 * radius;
    cvs.width = 2 * radius;
    // Star shape logic
    const nSpikes = 5, x0 = cvs.width / 2, y0 = cvs.height / 2;
    ctx.beginPath();
    for (let i = 0; i < nSpikes * 2; i++) {
      const rotation = Math.PI / 2,
        angle = (i / (nSpikes * 2)) * Math.PI * 2 + rotation,
        dist = radius / 2 * (i % 2) + radius / 2,
        x = x0 + Math.cos(angle) * dist,
        y = y0 + Math.sin(angle) * dist;
      if (i === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
    }
    ctx.closePath();
    ctx.fillStyle = options.backgroundColor;
    ctx.strokeStyle = options.borderColor;
    ctx.fill();
    ctx.stroke();
    return cvs;
  };

  const data: any = {
    labels: curriculumMonths,
    datasets: [
      {
        label: '目安',
        borderColor: 'rgb(75, 192, 192)',
        data: curriculumCounts,
        fill: false,
        tension: 0.1,
      },
      {
        label: '実績',
        borderColor: 'red',
        backgroundColor: 'red',
        pointRadius: 5,
        pointHoverRadius: 5,
        pointStyle: (ctx: CanvasRenderingContext2D, options: any) => customPointCanvas(ctx, {
          pointRadius: 5,
          backgroundColor: 'red',
          borderColor: 'red',
        }),
        data: [{ x: workerMark?.estimatedCurriculumMonth, y: workerMark?.completedCurriculumCount }],
      },
    ],
  };

  const options: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay: 50,
    scales: {
      y: {
        beginAtZero: true,  // 縦軸を0から始める
        suggestedMax: 35,
      },
      x: {
        title: {
          display: false,
        },
        ticks: {
          minRotation: 45, // 最小の角度（デフォルトは 0）
          maxRotation: 45, // 最大の角度（デフォルトは 50）
          stepSize: 2, // 2ステップごとに表示
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const drawBackground = (chart: ChartJS) => {
    const ctx = chart.ctx;
    const x_scale = chart.scales.x;
    const y_scale = chart.scales.y;

    const left = x_scale.left;
    const top = y_scale.top;
    const width = x_scale.width;
    const height = y_scale.height;

    ctx.save();
    ctx.fillStyle = "#fff"; // Set to the desired background color
    ctx.fillRect(left, top, width, height);
    ctx.restore();
  };

  const backgroundPlugin = {
    id: 'backgroundPlugin',
    beforeDraw: drawBackground,
  };

  const handleTargetWorkerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTargetWorker = event.target.value;
    setTargetWorker(selectedTargetWorker);
    handleGetCrownProgress(selectedTargetWorker);
  };

  const handleBrandCurriculumProgressChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBrandId = event.target.value;
    setBrandIdCurriculumProgress(selectedBrandId);
    handleGetCurriculumProgress(selectedBrandId);
  };

  const handleBrandCurriculumMarkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedBrandId = event.target.value;
    console.log(selectedBrandId);
    setBrandIdCurriculumMark(selectedBrandId);
    handleGetWorkerBrandCurriculumMark(selectedBrandId)
  }

  const handleGetCrownProgress = async (selectedTargetWorker: string) => {
    try {
      const data = await getCrownProgress(selectedTargetWorker);
      setCrownProgressData(data.crownProgress);
      setTargetWorkers(data.targetWorkers);
    } catch (error) {
      console.error('Failed to fetch ranking report:', error);
    }
  };

  const handleGetCurriculumProgress = async (selectedBrandId: string) => {
    try {
      const data = await getCurriculumProgress(selectedBrandId);
      setCurriculumProgressData(data.curriculumProgress);
      setBrands(data.brands);
      setBrandIdCurriculumProgress(data.brandId)
    } catch (error) {
      console.error('Failed to fetch ranking report:', error);
    }
  };

  const handleGetWorkerBrandCurriculumMark = async (selectedBrandId: string) => {
    try {
      const data = await getWorkerBrandCurriculumMark(selectedBrandId);
      setCurriculumMonths(data.brandCurriculumMarks.curriculumMonths);
      setCurriculumCounts(data.brandCurriculumMarks.curriculumCounts);
      setWorkerMark(data.workerMark);
      setBrandIdCurriculumMark(data.brandId);
    } catch (error) {
      console.error('Failed to fetch ranking report:', error);
    }
  }

  const getCrownProgress = async (targetWorker: any) => {
    await axios.get(`/api/sanctum/csrf-cookie`)

    const { data } = await axios.post<any>('/api/show-personal-report/crown-progress', { worker_id: workerId, target_worker: targetWorker });
    return data;
  };

  const getCurriculumProgress = async (brandId: any) => {
    await axios.get(`/api/sanctum/csrf-cookie`)

    const { data } = await axios.post<any>('/api/show-personal-report/curriculum-progress', { worker_id: workerId, brand_id: brandId });
    return data;
  }

  const getWorkerBrandCurriculumMark = async (brandId: any) => {
    await axios.get(`/api/sanctum/csrf-cookie`) 

    const { data } = await axios.post<any>('/api/show-personal-report/worker-brand-curriculum-mark', { worker_id: workerId, brand_id: brandId });
    return data;
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      try {
        const [crownProgress, curriculumProgress, workerBrandMark] = await Promise.all([
          getCrownProgress(targetWorker),
          getCurriculumProgress(brandIdCurriculumProgress),
          getWorkerBrandCurriculumMark(brandIdCurriculumMark)
        ]);

        setCrownProgressData(crownProgress.crownProgress);
        setTargetWorkers(crownProgress.targetWorkers);
        setCurriculumProgressData(curriculumProgress.curriculumProgress);
        setBrands(curriculumProgress.brands);
        setCurriculumMonths(workerBrandMark.brandCurriculumMarks.curriculumMonths);
        setCurriculumCounts(workerBrandMark.brandCurriculumMarks.curriculumCounts);
        setWorkerMark(workerBrandMark.workerMark);
        setBrandIdCurriculumProgress(curriculumProgress.brandId);
        setBrandIdCurriculumMark(workerBrandMark.brandId);
        setWorkerName(workerBrandMark.workerMark.workerName);
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authUser?.role === UserRole.Worker) {
      window.history.replaceState(null, '', `/personal_report/${authUser?.id}`);
    }
  }, [authUser]);

  return (
    <>
      {isLoading && <Loading />}
      <Helmet>
        <title>個人レポート　{workerName} | e-Training</title>
      </Helmet>
      <Header>
        <div className="head-report-personal">
          {hasRoleManagerAdmin && (
            <>
              <Dropdown>
                <Dropdown.Toggle variant="default" id="dropdown-basic">
                  ランキング
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="/report_ranking/percent">履修率ランキング</Dropdown.Item>
                  <Dropdown.Item href="/report_ranking/count">合格数ランキング</Dropdown.Item>
                </Dropdown.Menu>
                <Button variant="default">個人レポート</Button>
              </Dropdown>
            </>
          )}
        </div>
      </Header>
      <div className="content-charts">
        <div className="report-header">
          <div className="report-header-left">
            <div className="report-title">個人レポート　{workerName}</div>
          </div>
          <div className="report-header-right">
            <Button variant="secondary" size="sm" href={`/worker_detail/${workerId}`} >マイページに戻る</Button>
            {authUser && authUser?.role !== UserRole.Worker && (
              <Button variant="secondary" size="sm" href="/shop_select">トップに戻る</Button>
            )}
          </div>
        </div>
        <div className="report-analysis-header">
          <div className="report-analysis-header-inner">
            <Row className="flex-fill">
              <Col className="col-12" lg={6} xl={4}>
                <Card className="card-report-analysis">
                  <Card.Header className="card-report-header">
                    <h5 className="card-report-title">王冠GET進捗</h5>
                    <div className="select-box">
                      <Form.Select
                        value={targetWorker}
                        onChange={handleTargetWorkerChange}
                      >
                        {targetWorkers.map((item, index) => (
                          <option key={index} value={item}>{item}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="scroll-box" style={{ maxHeight: '480px' }}>
                      <table className="table-report-list table-header-fixed" cellSpacing="0" id="reportTable1">
                        <thead id="table-header">
                          <tr>
                            <td className="sticky-row" rowSpan={2}></td>
                            <td className="sticky-row" rowSpan={2}>対象カリキュラム数</td>
                            <td className="sticky-row" colSpan={2}>王冠</td>
                            <td className="sticky-row" colSpan={2}>eトレーナー<br />認定数</td>
                          </tr>
                          <tr>
                            <td className="sticky-row-2">修了数</td>
                            <td className="sticky-row-2">修了率</td>
                            <td className="sticky-row-2">認定数</td>
                            <td className="sticky-row-2">認定率</td>
                          </tr>
                        </thead>
                        <tbody>
                          {crownProgressData.map((item, index) => (
                            <tr key={index}>
                              <td className="report-col">{item.brandName}</td>
                              <td className="report-col">{item.curriculumCount}</td>
                              <td className="report-col">{item.completedCount !== null ? item.completedCount : ''}</td>
                              <td className="report-col">{item.completedRate !== null ? item.completedRate : ''}%</td>
                              <td className="report-col">{item.certifiedCompletedCount !== null ? item.certifiedCompletedCount : ''}</td>
                              <td className="report-col">{item.certifiedCompletedRate !== null ? item.certifiedCompletedRate : ''}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-12" lg={6} xl={4}>
                <Card className="card-report-analysis">
                  <Card.Header className="card-report-header">
                    <h5 className="card-report-title">履修中カリキュラム</h5>
                    <div className="select-box">
                      <Form.Select
                        value={brandIdCurriculumProgress}
                        onChange={handleBrandCurriculumProgressChange}
                      >
                        <option value="">全体</option>
                        <option value="共通">共通</option>
                        {brands.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="scroll-box" style={{ maxHeight: '480px' }}>
                      <table className="table-report-list table-header-fixed" cellSpacing="0" id="reportTable1">
                        <thead id="table-header">
                          <tr>
                            <td className="sticky-row" rowSpan={2}></td>
                            <td className="sticky-row" rowSpan={2}>部門</td>
                            <td className="sticky-row" rowSpan={2}>カリキュラム</td>
                            <td className="sticky-row" rowSpan={2}>残日数</td>
                            <td className="sticky-row" style={{ fontSize: '1.0rem', fontWeight: '700' }} colSpan={4}>履修率</td>
                          </tr>
                          <tr>
                            <td className="sticky-row-2 noBorderTop"></td>
                            <td className="sticky-row-2">動画</td>
                            <td className="sticky-row-2">セルフ</td>
                            <td className="sticky-row-2">トレーナー</td>
                          </tr>
                        </thead>
                        <tbody>
                          {curriculumProgressData.map((item, index) => (
                            <tr key={index}>
                              <td className="report-col">{index + 1}</td>
                              <td className="report-col">{item.brandName.substring(0, 4)}</td>
                              <td className="report-col" style={{ textAlign: 'left' }}>{item.curriculumName}</td>
                              <td className="report-col">{item.remainingDays !== null ? item.remainingDays : ''}</td>
                              <td className="report-col" style={{ fontSize: '1.0rem', fontWeight: '700' }}>{item.curriculumRate !== null ? item.curriculumRate : ''}%</td>
                              <td className="report-col">{item.videoRate !== null ? item.videoRate : ''}%</td>
                              <td className="report-col">{item.selfCheckRate !== null ? item.selfCheckRate : ''}%</td>
                              <td className="report-col">{item.trainerCheckRate !== null ? item.trainerCheckRate : ''}%</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col className="col-12" lg={6} xl={4}>
                <Card className="card-report-analysis">
                  <Card.Header className="card-report-header">
                    <h5 className="card-report-title">在籍期間別習得目安／実績</h5>
                    <div className="select-box">
                      <Form.Select
                        value={brandIdCurriculumMark}
                        onChange={handleBrandCurriculumMarkChange}
                      >
                        {brands.map((item, index) => (
                          <option key={index} value={item.id}>{item.name}</option>
                        ))}
                      </Form.Select>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex flex-column gap-3 h-100">
                      <table className="table-report-list table-header-fixed" cellSpacing="0" id="reportTable1">
                        <thead id="table-header">
                          <tr>
                            <td className="sticky-row" rowSpan={2}></td>
                            <td className="sticky-row" rowSpan={2}>在籍期間</td>
                            <td className="sticky-row" colSpan={4}>王冠GET数（カリキュラム）</td>
                          </tr>
                          <tr>
                            <td className="sticky-row-2">基準期間</td>
                            <td className="sticky-row-2">目安数</td>
                            <td className="sticky-row-2">実績</td>
                            <td className="sticky-row-2">目安差</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="report-col">{workerMark?.brandName?.substring(0, 4)}</td>
                            <td className="report-col">{workerMark?.workerDuration}</td>
                            <td className="report-col">{workerMark?.roundedWorkerDuration}</td>
                            <td className="report-col">{workerMark?.estimatedCurriculumCount}</td>
                            <td className="report-col">{workerMark?.completedCurriculumCount}</td>
                            <td className="report-col">{workerMark?.diffCurriculumCount}</td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="chart-box" style={{ height: '320px' }}>
                        {curriculumMonths && curriculumMonths.length > 0 && (
                          <Line
                            options={options}
                            data={data}
                            plugins={[backgroundPlugin]}
                          />
                        )}
                        {(!curriculumMonths || (curriculumMonths && curriculumMonths.length === 0)) && (
                          <div className="chart-no-data">チャートデータはありません。</div>
                        )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalReport;
