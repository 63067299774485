import { Button, Card } from "react-bootstrap";
import Header from "../components/Header";
import { useEffect, useState } from "react";
import { useGetCurriculumDisplaySetting, useUpdateCurriculumDisplaySetting } from "../queries/WorkersQuery";

interface CurriculumDisplaySettingProps {
    workerDetail: boolean,
    workerList: boolean
}

function CurriculumDisplaySetting(){

    const {
        data,
        isSuccess,
    } = useGetCurriculumDisplaySetting();

    const [displayCurriculumPreference, setDisplayCurriculumPreference] = useState<CurriculumDisplaySettingProps>({workerDetail: false, workerList: false});
    const updateCurriculumDisplaySetting = useUpdateCurriculumDisplaySetting();

    const handleChangeWorkerDetail = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayCurriculumPreference({...displayCurriculumPreference, workerDetail: e.target.checked});
    }

    const handleChangeWorkerList = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDisplayCurriculumPreference({...displayCurriculumPreference, workerList: e.target.checked});
    }

    useEffect(() => {
        if(isSuccess){
            setDisplayCurriculumPreference(data);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const saveDisplayCurriculumPreference = () => {
        updateCurriculumDisplaySetting.mutate({curriculumDisplayPreference: displayCurriculumPreference});
    }

    return (
        <>
            <Header></Header>
            <div className="content-main" style={{ maxWidth: '760px', width: '100%' }}>
                <div className="d-flex flex-column">
                    <h3 className="align-self-center mt-3 mb-4">システム設定 </h3>
                    <Card style={{ margin: '1rem', borderRadius: '.25rem' }}>
                        <Card.Body>
                            <div className="d-flex flex-column gap-2">
                                <div className="d-flex flex-column justify-content-start flex-sm-row" style={{ color: '#495057' }}>
                                    <label className="col-form-label form-label-minw fw-bold" style={{ marginRight: "10px", fontSize: "14px" }}>
                                        カリキュラム表示の
                                        <br />
                                        オン／オフ
                                    </label>
                                    <div className="form-check align-items-center mt-1">
                                        <input type="checkbox" className="form-check-input" onChange={handleChangeWorkerDetail} checked={displayCurriculumPreference.workerDetail}/>
                                        <label className="form-check-label" style={{ fontSize: '16px', marginTop: '4px' }}>有効にする</label>
                                        <p className="mt-2" style={{ fontSize: '12.5px', marginBottom: '30px', lineHeight: '17px' }}>
                                            ※マイページでカリキュラム内のステップ、タスクがすべてグレーになっている場合にステップは閉じてカリキュラムの帯だけの表示にする。
                                            <br />カリキュラムの帯の開閉は可能。
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <div className="d-flex flex-column justify-content-start flex-sm-row" style={{ color: '#495057' }}>
                                    <label className="col-form-label form-label-minw fw-bold" style={{ marginRight: "10px", fontSize: "14px" }}>
                                        ステップ項目の表示の
                                        <br />
                                        オン/オフ
                                    </label>
                                    <div className="form-check align-items-center mt-1">
                                        <input type="checkbox" className="form-check-input" onChange={handleChangeWorkerList} checked={displayCurriculumPreference.workerList}/>
                                        <label className="form-check-label" style={{ fontSize: '16px', marginTop: '4px' }}>有効にする</label>
                                        <p className="mt-2" style={{ fontSize: '12.5px', marginBottom: '30px', lineHeight: '17px' }}>
                                            ※スタッフ一覧でステップの表示をすべて開くまたは閉じる。
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                        <Card.Footer style={{ borderTop: '0' }}>
                            <div className="d-flex align-items-center justify-content-center gap-2">
                                <Button className="px-3" variant="default" href="/shop_select">キャンセル</Button>
                                <Button className="px-4" variant="primary" onClick={saveDisplayCurriculumPreference}>保存する</Button>
                            </div>
                        </Card.Footer>
                    </Card>
                </div>
            </div>
        </>
    );
}

export default CurriculumDisplaySetting;
