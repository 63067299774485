import { useEffect, useState } from "react";
import Header from "../components/Header";
import { Button } from "react-bootstrap";
import axios from "axios";
import Loading from "../components/Loading";
import { useGetFiltersCsvOutput } from "../queries/WorkersQuery";
import { Shop, Curriculum, Worker, TaskType, Brand } from "../types/FiltersCsvOutput";
import { useParams } from "react-router-dom";

function CsvOutput(){

  
  const { type } = useParams();

  const [shops, setShops] = useState<Shop[]>();
  const [brands, setBrands] = useState<Brand[]>();
  const [curriculums, setCurriculums] = useState<Curriculum[]>();
  const [targetWorkers, setTargetWorkers] = useState<string[]>();
  const [workers, setWorkers] = useState<Worker[]>();
  const [taskTypes, setTaskTypes] = useState<TaskType[]>();

  const [shopId, setShopId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [curriculumId, setCurriculumId] = useState("");
  const [targetWorker, setTargetWorker] = useState("");
  const [workerId, setWorkerId] = useState("");
  const [taskTypeId, setTaskTypeId] = useState("");
  
  const [title, setTitle] = useState("CSV出力 個人別履修数");
  const [typeCSV] = useState(type || "personal");
  const [isLoading, setIsLoading] = useState(false);

  const {
    data: filterData,
  } = useGetFiltersCsvOutput({ shopId }, { brandId }, { targetWorker })

  useEffect(() => {
    setShops(filterData?.shops)
    setBrands(filterData?.brands)
    setCurriculums(filterData?.curriculums)
    setTargetWorkers(filterData?.targetWorkers)
    setWorkers(filterData?.workers)
    setTaskTypes(filterData?.taskTypes)
  }, [filterData])

  useEffect(() => {
    setCurriculumId("")
    setWorkerId("")
  }, [shopId, brandId])

  useEffect(() => {
    if (type === "personal") {
      setTitle("CSV出力 個人別履修数");
    } else if (type === "crown") {
      setTitle("CSV出力 個人別王冠数");
    } else if (type === "attendance") {
      setTitle("CSV出力 履修数・王冠数　店別部門別集計");
    } else if (type === "selfcheck") {
      setTitle("CSV出力 セルフチェック分析");
    } else if (type === "comment") {
      setTitle("CSV出力 コメント分析");
    }
  }
  , [type]);

  const handleCSVDownload = async () => {
    setIsLoading(true);

    const payload = {
      shop_id: shopId,
      brand_id: brandId,
      curriculum_id: curriculumId,
      target_worker: targetWorker,
      worker_id: workerId,
      task_type_id: taskTypeId,
    };
  
    axios({
      url: `/api/csv/${type}/export`,
      method: 'GET',
      responseType: 'blob',
      params: payload,
    }).then((response) => {
        const href = URL.createObjectURL(response.data);
    
        const link = document.createElement('a');
        link.href = href;
        const date = new Date().toISOString().slice(0, 10);
        link.setAttribute('download', `${typeCSV}-${date}.csv`);
        document.body.appendChild(link);
        link.click();
    
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }).finally(() => {
      setIsLoading(false);
    });
  }

  return (
    <>
      {isLoading && <Loading />}
      <Header>
        <div className="head-report-personal">
          <Button variant="default" href="/csv_output/personal">個人別履修数</Button>
          <Button variant="default" href="/csv_output/crown">個人別王冠数</Button>
          <Button variant="default" href="/csv_output/attendance">履修数・王冠数　店別部門別集計</Button>
          <Button variant="default" href="/csv_output/selfcheck">セルフチェック分析</Button>
          <Button variant="default" href="/csv_output/comment">コメント分析</Button>
        </div>
      </Header>
      <div className="content-charts">
        <div className="report-header">
          <div className="report-header-left">
            <div className="report-title">{title}</div>
          </div>
          <div className="report-header-right">
            <Button variant="secondary" size="sm" href="/shop_select">トップに戻る</Button>
          </div>
        </div>
        <div className="reportsearch-header">
          <div className="reportsearch-header-inner">
            <div className="reportsearch-select">
              <h3 className="selecttitle">店舗</h3>
              <div className="selectbox">
                <select
                  className=""
                  value={shopId}
                  onChange={(e) => {
                    setBrandId("")
                    setShopId(e.target.value)
                  }}
                >
                  <option value="">全店舗</option>
                  {shops?.map((shop) => (
                    <option key={shop.id} value={shop.id}>{shop.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="reportsearch-select">
              <h3 className="selecttitle">部門（応援除く）</h3>
              <div className="selectbox">
                <select
                  className=""
                  value={brandId}
                  onChange={(e) => {
                    setShopId(brands?.find(brand => brand.id === e.target.value)?.shop_id || "")
                    setBrandId(e.target.value)
                  }}
                >
                  <option value="">全部門</option>
                  {shops?.map((shop) => {
                    return (
                      <optgroup label={shop.name}>
                        {brands?.filter(brand => brand.shop_id === shop.id).map((brand) => {
                          return (
                            <option key={brand.id} value={brand.id}>
                              {brand.name}
                            </option>
                          )
                        })}
                      </optgroup>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className="reportsearch-select">
              <h3 className="selecttitle">対象者</h3>
              <div className="selectbox">
                <select
                  className=""
                  value={targetWorker}
                  onChange={(e) => setTargetWorker(e.target.value)}
                >
                  <option value="">全対象者</option>
                  {targetWorkers?.map((targetWorker) => (
                    <option key={targetWorker} value={targetWorker}>{targetWorker}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="reportsearch-select">
              <h3 className="selecttitle">カリキュラム</h3>
              <div className="selectbox">
                <select
                  className=""
                  value={curriculumId}
                  onChange={(e) => setCurriculumId(e.target.value)}
                >
                  <option value="">全カリキュラム</option>
                  {curriculums?.map((curriculum) => (
                    <option key={curriculum.id} value={curriculum.id}>{curriculum.id} - {curriculum.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="reportsearch-select">
              <h3 className="selecttitle">スタッフ（退職者除く）</h3>
              <div className="selectbox">
                <select
                  className=""
                  value={workerId}
                  onChange={(e) => setWorkerId(e.target.value)}
                >
                  <option value="">全スタッフ</option>
                  {workers?.map((worker) => (
                    <option key={worker.id} value={worker.id}>{worker.name}</option>
                  ))}
                </select>
              </div>
            </div>
            {type === "comment" && (
              <div className="reportsearch-select">
                <h3 className="selecttitle">タスクの種類</h3>
                <div className="selectbox">
                  <select
                    className=""
                    value={taskTypeId}
                    onChange={(e) => setTaskTypeId(e.target.value)}
                  >
                    <option value="">全タスク</option>
                    {taskTypes?.map((taskType) => (
                      <option key={taskType.id} value={taskType.id}>{taskType.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            )}
            <Button variant="primary" size="sm" className="ms-2 px-4"
              onClick={handleCSVDownload}>CSVダウンロード</Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CsvOutput;
