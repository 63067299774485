// PracticeModal.tsx
import React, { FormEvent, useEffect, useState } from 'react';
import TextareaModal from './TextareaModal';
import { TaskInstance } from '../../types/TaskInstance';
import { useUpdatePracticeWorkerComment } from '../../queries/TasksQuery';
import Loading from '../Loading';
import { StButtonTypeB, StButtonTypeBDisabled, textareaStyle } from '../../style/styles';
import PreformattedText from '../PreformattedText';

type PracticeModalProps = {
  taskInstance: TaskInstance;
  onSuccess: () => void;
  closeModal: () => void;
  canEditAsWorker: boolean;
  canEditAsTrainer: boolean;
};

function PracticeModal(props: PracticeModalProps) {
  const [comment, setComment] = useState<string>(
    props.taskInstance.task_self_comment || ''
  );
  const [isTextareaModalOpen, setTextareaModalOpen] = useState(false);
  const updatePracticeWorkerComment = useUpdatePracticeWorkerComment();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    updatePracticeWorkerComment.mutate({
      workerId: props.taskInstance.worker_id,
      taskId: props.taskInstance.task_id,
      comment,
    });
  };

  useEffect(() => {
    if (updatePracticeWorkerComment.isSuccess) {
      props.onSuccess();
    }
  }, [updatePracticeWorkerComment.isSuccess]);

  return (
    <>
      <div className="modal">
        {updatePracticeWorkerComment.isLoading ? <Loading /> : ''}
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1>実践テスト</h1>
        </div>
        <PreformattedText
          description={props.taskInstance.description ?? ''}
        />
        <form onSubmit={handleSubmit}>
          <fieldset disabled={!props.canEditAsWorker}>
            <pre
              style={{...textareaStyle,
                color: comment ? '#000' : '#a0a0a0'
              }}
              onClick={() => setTextareaModalOpen(true)}
            >
              {comment || "ここをクリックして表示したいテキストを入力してください。"}
            </pre>
            <button type="submit" disabled={!props.canEditAsWorker} style={props.canEditAsWorker ? StButtonTypeB : StButtonTypeBDisabled}>
              スタッフ送信
            </button>
          </fieldset>
        </form>
      </div>
      <TextareaModal
        isOpen={isTextareaModalOpen}
        onClose={() => setTextareaModalOpen(false)}
        onConfirm={(text) => {
          setComment(text);
          setTextareaModalOpen(false);
        }}
        comment={comment}
      />
    </>
  );
}

export default PracticeModal;
