import * as api from "../api/TasksAPI";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

const useUpdateAim = () => {
  return useMutation(api.updateAim, {
    onSuccess: () => {
      toast.success("目標設定を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "目標設定の更新に失敗しました。"
      );
    },
  });
};

const useUpdateSelfChecks = () => {
  return useMutation(api.updateSelfChecks, {
    onSuccess: () => {
      toast.success("セルフチェック項目を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "セルフチェック項目の更新に失敗しました。"
      );
    },
  });
};

const useUpdateTrainerChecks = () => {
  return useMutation(api.updateTrainerChecks, {
    onSuccess: () => {
      toast.success("トレーナーチェック項目を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "トレーナーチェック項目の更新に失敗しました。"
      );
    },
  });
};

const useMarkMovieAsWatched = () => {
  return useMutation(api.markMovieAsWatched, {
    onSuccess: () => {
      toast.success("動画視聴を完了しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "動画視聴の完了に失敗しました。"
      );
    },
  });
};

const useMarkSheetAsRead = () => {
  return useMutation(api.markSheetAsRead, {
    onSuccess: () => {
      toast.success("紙マニュアルの閲覧を完了しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "紙マニュアルの閲覧の完了に失敗しました。"
      );
    },
  });
};

const useUpdatePracticeWorkerComment = () => {
  return useMutation(api.updatePracticeWorkerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdateFeedbackWorkerComment = () => {
  return useMutation(api.updateFeedbackWorkerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdatePracticeFeedbackWorkerComment = () => {
  return useMutation(api.updatePracticeFeedbackWorkerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdateFeedbackTrainerComment = () => {
  return useMutation(api.updateFeedbackTrainerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useUpdatePracticeFeedbackTrainerComment = () => {
  return useMutation(api.updatePracticeFeedbackTrainerComment, {
    onSuccess: () => {
      toast.success("コメントを送信しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "コメントの送信に失敗しました。"
      );
    },
  });
};

const useGetTaskInformation = (
  {workerId}: { workerId: string | undefined },
  {taskId}: { taskId: string | undefined }
  ) => {
  return useQuery(["getTaskInformation", workerId, taskId], () => 
    api.getTaskInformation(workerId, taskId)
  );
};

const useUpdateCurriculumCertificate = () => {
  return useMutation(api.updateCurriculumCertificate, {
    onSuccess: () => {
      toast.success("資格取得を完了しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "資格取得の完了に失敗しました。"
      );
    },
  });
}

const useRevokeCurriculumCertificate = () => {
  return useMutation(api.revokeCurriculumCertificate, {
    onSuccess: () => {
      toast.success("資格取得を取り消しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "資格取得の取り消しに失敗しました。"
      );
    },
  });
}

const useUpdateStepCertificate = () => {
  return useMutation(api.updateStepCertificate, {
    onSuccess: () => {
      toast.success("資格取得を完了しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "資格取得の完了に失敗しました。"
      );
    },
  });
}

const useRevokeStepCertificate = () => {
  return useMutation(api.revokeStepCertificate, {
    onSuccess: () => {
      toast.success("資格取得を取り消しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "資格取得の取り消しに失敗しました。"
      );
    },
  });
}

const useUpdateAimCurriculum = () => {
  return useMutation(api.updateAimCurriculum, {
    onSuccess: () => {
      toast.success("目標設定を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "目標設定の更新に失敗しました。"
      );
    },
  });
}

const useGetListRankingReport = (
  {areaId}: { areaId: string | undefined}, 
  {shopId}: { shopId: string | undefined}, 
  {brandName}: { brandName: string | undefined },
  {employmentTypeId}: { employmentTypeId: string | undefined}) => {
  return useQuery([areaId, shopId, brandName, employmentTypeId], () =>
    api.getListRankingReport(areaId, shopId, brandName, employmentTypeId)
  );
};

export {
  useUpdateAim,
  useUpdateSelfChecks,
  useUpdateTrainerChecks,
  useMarkMovieAsWatched,
  useMarkSheetAsRead,
  useUpdatePracticeWorkerComment,
  useUpdateFeedbackWorkerComment,
  useUpdatePracticeFeedbackWorkerComment,
  useUpdateFeedbackTrainerComment,
  useUpdatePracticeFeedbackTrainerComment,
  useGetTaskInformation,
  useUpdateCurriculumCertificate,
  useRevokeCurriculumCertificate,
  useUpdateStepCertificate,
  useRevokeStepCertificate,
  useUpdateAimCurriculum,
  useGetListRankingReport
};
