import React, { useEffect, useState } from 'react';

// Tipo de propriedades do componente TextareaModal
type TextareaModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (text: string) => void;
  comment?: string;
};

// Componente TextareaModal
const TextareaModal: React.FC<TextareaModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  comment,
}) => {

  const [text, setText] = useState(comment || '');

  useEffect(() => {
    if (isOpen) {
      setText(comment || '');
    }
  }, [isOpen, comment]);

  if (!isOpen) {
    return null;
  }

  const modalContainerStyle: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    paddingTop: '25px',
    zIndex: 1000,
  };

  const modalContentStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '80%',
    maxWidth: '700px',
    textAlign: 'right',
  };

  const buttonStyle: React.CSSProperties = {
    borderRadius: '4px',
    padding: '0px 20px',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    minWidth: '100px',
    minHeight: '35px',
    marginLeft: '10px',
  }

  const buttonSaveStyle: React.CSSProperties = {
    backgroundColor: '#1876D2',
    color: 'white',
    border: '2px solid #008CBA',
  };

  const buttonCancelStyle: React.CSSProperties = {
    backgroundColor: '#fff',
    color: '#1876D2',
    border: '2px solid #1876D2'
  };

  const handleConfirm = () => {
    onConfirm(text);
  };

  const handleCancel = () => {
    onClose();
    setText('');
  };

  return (
    <div style={modalContainerStyle} onClick={onClose}>
      <div
        style={modalContentStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <textarea
          rows={5}
          autoFocus
          value={text}
          placeholder='ここをクリックして表示したいテキストを入力してください。'
          onChange={(e) => setText(e.target.value)}
          style={{ width: '100%', fontSize: '1.3em' }}
        />
        <button style={{...buttonStyle, ...buttonCancelStyle}} onClick={handleCancel}>キャンセル</button>
        <button style={{...buttonStyle, ...buttonSaveStyle}} onClick={handleConfirm}>変更</button>
      </div>
    </div>
  );
};

export default TextareaModal;
