import React, { useEffect, useRef, useState } from 'react'
import { useLogout } from '../queries/AuthQuery'
import { NavLink } from 'react-router-dom'
import { useModal } from 'react-hooks-use-modal'
import CurriculumAddModal from './modal/CurriculumAddModal'
import { GrowthGoalEditModal } from './modal/GrowthGoalEditModal'
import { useAuthUser } from '../hooks/AuthUserContext'
import { UserRole } from '../types/UserRole'
import { CurriculumGroup } from '../types/CurriculumGroup'
import Loading from './Loading'
import { useGetBrandsForCurriculums } from '../queries/ShopsQuery'
import useConfig from '../hooks/GetConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Form } from '../types/WorkerDetailResponse'
import { Button } from 'react-bootstrap'

type WorkerHeaderProps = {
  shopId: string
  brandId: string
  brandName: string
  forms: Form[]
  curriculumDisplayPreference: boolean
  handleShowAllCurriculums: (toggleCurriculums: boolean) => void
  workerId: string
  workerName: string
  growthGoal: string
  curriculums: CurriculumGroup[]
  workerCurriculumIds: string[]
  onUpdate: () => void
  curriculumBrandName?: string
  handleCurriculumBrandName?: (name: string) => void
  hasAlarm?: boolean
  setHasAlarm?: (alarm: boolean) => void
  setHasCalendar?: (calendar: boolean) => void
  setHasIncomplete?: (incomplete: boolean) => void
  sortCurriculums: (sortOrder: boolean) => void
}

function WorkerHeader(props: WorkerHeaderProps) {
  // hooks
  const { authUser } = useAuthUser()
  const logout = useLogout()
  const config = useConfig();
  const [Modal1, openModal1, closeModal1, isOpenModal1] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })
  const [Modal2, openModal2, closeModal2, isOpenModal2] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: true,
  })
  const { data: brandsForCurriculums } = useGetBrandsForCurriculums(props.shopId)

  // states
  const [canEditAsWorker, setCanEditAsWorker] = useState<boolean>(false)
  const [canEditAsTrainer, setCanEditAsTrainer] = useState<boolean>(false)
  const [canAddCurriculum, setCanAddCurriculum] = useState<boolean>(false)

  // effects
  useEffect(() => {
    switch (authUser?.role) {
      case UserRole.Worker:
        setCanEditAsWorker(props.workerId === authUser?.workerId)
        setCanEditAsTrainer(false)
        break
      case UserRole.Trainer:
      case UserRole.Store1:
        setCanEditAsWorker(props.shopId === authUser?.shopId)
        setCanEditAsTrainer(props.shopId === authUser?.shopId)
        setCanAddCurriculum(props.shopId === authUser?.shopId)
        break
      case UserRole.Manager:
        setCanEditAsWorker(props.shopId === authUser?.shopId)
        setCanEditAsTrainer(props.shopId === authUser?.shopId)
        setCanAddCurriculum(true)
        break
      case UserRole.Admin:
        setCanEditAsWorker(true)
        setCanEditAsTrainer(true)
        setCanAddCurriculum(true)
        break
    }
  }, [authUser])

  // methods
  const setHasAlarm = (alarm: boolean) => { props.setHasAlarm!(alarm) }
  const setHasCalendar = (calendar: boolean) => { props.setHasCalendar!(calendar) }
  const setHasIncomplete = (incomplete: boolean) => { props.setHasIncomplete!(incomplete) }

  const handleSorts = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.value) {
      case "1":
        setHasAlarm(true);
        setHasCalendar(false);
        setHasIncomplete(false);
        break;
      case "2":
        setHasCalendar(true);
        setHasAlarm(false);
        setHasIncomplete(false);
        break;
      case '3':
        setHasIncomplete(true);
        setHasAlarm(false);
        setHasCalendar(false);
        break;
      default:
        setHasAlarm(false);
        setHasCalendar(false);
        setHasIncomplete(false);
        break;
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const toggleOpenDropdown = () => setIsOpenDropdown(!isOpenDropdown);

  const dropdownRef = useRef<HTMLDivElement>(null);
  const linkDropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isOpen && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
      if (isOpenDropdown && linkDropdownRef.current && !linkDropdownRef.current.contains(e.target as Node)) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpen, isOpenDropdown])

  return (
    <>
      {logout.isLoading && <Loading />}
      <header>
        <table>
          <tbody>
            <tr className="header-row-top" style={{ maxHeight: '62px' }}>
              <td
                rowSpan={2}
                className="shop-img"
                style={{
                  backgroundImage: "url('/img/shop/shop_dummy1.png')",
                  backgroundSize: 'cover',
                }}
              />
              <td className="head-logo">
                <img src="/img/calc07.png" alt="" />
              </td>
              <td className="head-name">{props.workerName}</td>

              <td className="head-nav">
                <div className="head-nav-inner">
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      logout.mutate()
                    }}
                    className="btn btn-sm btn-default"
                  >
                    ログアウト
                  </a>
                  <div id="dropdown-component" ref={dropdownRef} className="dropdown">
                    <button onClick={toggleOpen} type="button" className="btn btn-settingicon" id="btn-setting">
                      <FontAwesomeIcon icon={faGear} size='xl' />
                      <div>設定</div>
                    </button>
                    {isOpen && (
                      <ul className="dropdown-menu dropdown-menu-right dropdown-menu-separate">
                        {authUser && authUser.role !== UserRole.Worker ? (
                          <li>
                            <a
                              onClick={(e) => {
                                e.preventDefault()
                                setIsOpen(false)
                                openModal2()
                              }}
                              href="#"
                            >
                              カリキュラム設定
                            </a>
                          </li>
                        ) : ''}
                        <li>
                          <NavLink className='dropdown-item' to={`/password_change`}>
                            パスワード変更
                          </NavLink>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
              </td>
            </tr>
            <tr className="header-row-second">
              <td className="shop-desc" colSpan={3}>
                <div className="shop-goal">
                  <div className="shop-goal-header">
                    <div className="">今月の目標</div>
                    <a
                      className="btn btn-sm btn-primary ms-2"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault()
                        openModal1()
                      }}
                    >
                      編集する
                    </a>
                  </div>
                  <div className="shop-goal-body">
                    <p className="py-0">{props.growthGoal}</p>
                  </div>
                  <Modal1>
                    <GrowthGoalEditModal
                      workerId={props.workerId}
                      growthGoal={props.growthGoal}
                      closeModal={closeModal1}
                      onSuccess={() => {
                        props.onUpdate()
                        closeModal1()
                      }}
                      canEditAsWorker={canEditAsWorker}
                      canEditAsTrainer={canEditAsTrainer}
                    />
                  </Modal1>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </header>
      <div className="content">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="stafflist-header">
            <div className="stafflist-header-left">
              <NavLink
                to={`/shop/${props.shopId}/worker_list/${props.brandId}`}
                className='btn btn-pink me-3'
              >
                <FontAwesomeIcon icon={faUsers} />
                スタッフ別
              </NavLink>

              <div className="stafflist-select me-3">
                <h3 className="selecttitle">カリキュラム表示切替</h3>
                <div className="selectbox">
                  <select
                    className="children2"
                    value={props.curriculumBrandName === '' ? props.brandId : props.curriculumBrandName}
                    onChange={(e) => {
                      props.handleCurriculumBrandName?.(e.target.value)
                    }}
                  >
                    <option value="" className="msg">-----部門を選択-----</option>
                    {brandsForCurriculums?.map((brand) => {
                      return (
                        <option key={brand.id} value={brand.id}>
                          {brand.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>

              <div className="stafflist-conditions me-3">
                <div className="stafflist-select">
                  <h3 className="selecttitle">絞り込み</h3>
                  <div className="selectbox">
                    <select className="" onChange={handleSorts}>
                      <option value="0">すべて</option>
                      <option value="1">セルフチェックアラート</option>
                      <option value="2">目標設定アラート</option>
                      <option value="3">未完了のみ</option>
                    </select>
                  </div>
                </div>
              </div>
              {props.curriculumDisplayPreference && (
                <div className="stafflist-select me-3">
                  <h3 className="selecttitle fw-bold">履修済みカリキュラム</h3>
                  <div className="togglebox">
                    <div className="form-check form-switch">
                      <label className="form-check-label toggle-label-left">表示する</label>
                      <input
                        type="checkbox"
                        className="form-check-input"
                        onChange={(e) => props.handleShowAllCurriculums(e.target.checked)}
                      />
                      <label className="form-check-label toggle-label-right">しない</label>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="stafflist-header-right">
              <Button variant="secondary" href={`/personal_report/${props.workerId}`}>個人レポート</Button>
              {(config && !config?.hideFormButtons) && (
                <div className="dropdown" id="dropdown-component">
                  <button onClick={toggleOpenDropdown} className="btn btn-secondary">
                    外部リンク {isOpenDropdown ? '▲' : '▼'}
                  </button>
                  {isOpenDropdown && (
                    <ul className="dropdown-menu dropdown-menu-right dropdown-menu-separate">
                      {Object.values(props.forms).length > 0 && (
                        Object.values(props.forms).map((form, index) => (
                          <li key={index}>
                            <a
                              href={form.url}
                              className={'dropdown-item ' + (!form.url ? 'disabled' : '')}
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(form.url, '_blank');
                              }}
                            >
                              {form.name}
                            </a>
                          </li>
                        ))
                      )}
                    </ul>
                  )}
                </div>
              )}
              {authUser && authUser.role !== UserRole.Worker && (
                <Button variant="secondary" href="/shop_select">トップに戻る</Button>
              )}
            </div>
          </div>


          {authUser && authUser.role !== UserRole.Worker ? (
            <>
              <Modal2>
                <CurriculumAddModal
                  workerId={props.workerId}
                  curriculums={props.curriculums}
                  closeModal={closeModal2}
                  workerCurriculumIds={props.workerCurriculumIds}
                  onSuccess={() => {
                    props.onUpdate()
                    closeModal2()
                  }}
                  canEditAsWorker={canEditAsWorker}
                  canAddCurriculum={canAddCurriculum}
                />
              </Modal2>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

export default WorkerHeader
