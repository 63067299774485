import styles from '../../css/components/modal/ColorSettingModal.module.css';

type ColorSettingModalProps = {
    closeModal: () => void
}

function ColorSettingModal(props: ColorSettingModalProps) {
    return (
        <div className="modal" style={{ width: '75vw', height: 'auto' }}>
            <div className="modal-close">
                <button type="button" className="modal-close-button" onClick={props.closeModal}>
                    <img src="/images/circle-xmark.svg" className="modal-close-icon" />
                </button>
            </div>
            <div className="modal-header">
                <h1>スタッフ一覧の背景色の説明</h1>
            </div>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <table className={styles.tableColorSetting + ' staff-list mb-3'} cellSpacing="0" id="colorSettingModalTable1">
                        <tbody>
                            <tr>
                                <td width="40%" className="avoid-hover">水色</td>
                                <td className="avoid-hover">通常</td>
                            </tr>
                            <tr>
                                <td className="avoid-hover"><img src="/img/king.png" alt="crown" /></td>
                                <td className="avoid-hover">履修完了</td>
                            </tr>
                            <tr>
                                <td className="can-trainer avoid-hover">オレンジ色</td>
                                <td className="can-trainer avoid-hover">eトレーナー認定</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-12 col-lg-6">
                    <table className={styles.tableColorSetting + ' staff-list'} cellSpacing="0" id="colorSettingModalTable2">
                        <tbody>
                            <tr>
                                <td width="40%" className="avoid-hover">水色</td>
                                <td className="avoid-hover">通常</td>
                            </tr>
                            <tr>
                                <td className="alert-red avoid-hover">赤色</td>
                                <td className="alert-red avoid-hover">目標設定（終了）：超過</td>
                            </tr>
                            <tr>
                                <td className="light-yellow avoid-hover">薄い黄色</td>
                                <td className="light-yellow avoid-hover">目標設定（終了）：今月</td>
                            </tr>
                            <tr>
                                <td className="light-red avoid-hover">薄い桃色</td>
                                <td className="light-red avoid-hover">目標設定（終了）：来月</td>
                            </tr>
                            <tr>
                                <td className="light-green avoid-hover">薄い緑色</td>
                                <td className="light-green avoid-hover">目標設定（終了）：再来月</td>
                            </tr>
                            <tr>
                                <td className="can-trainer avoid-hover">オレンジ色</td>
                                <td className="can-trainer avoid-hover">eトレーナー認定</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="mt-2">※eトレーナー認定の色は履修済みでない場合は、目標設定の色が優先になります。</div>
        </div>
    );
}

export { ColorSettingModal }