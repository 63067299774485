import * as api from "../api/ShopsAPI";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

const useGetShops = () => {
  return useQuery(["getShops"], () => api.getShops());
};

const useGetShopsBrands = (shopId: string | undefined) => {
  return useQuery(["getShopsBrands"], () => api.getShopsBrands({shopId}));
};

const useGetBrandsForCurriculums = (shopId: string | undefined) => {
  return useQuery(["getBrandsForCurriculums"], () => api.getBrandsForCurriculums({shopId}));
};

const useGetShopsAreas = () => {
  return useQuery(["getShopsAreas"], () => api.getShopsAreas());
};

const useGetShopsOnly = (areaId: string | undefined) => {
  return useQuery(["getShopsOnly"], () => api.getShopsOnly({areaId}));
};

const useUpdateShopGrowthGoal = () => {
  return useMutation(api.updateShopGrowthGoal, {
    onSuccess: () => {
      toast.success("ショップの今月の目標を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
          "ショップの今月の目標の更新に失敗しました。"
      );
    },
  });
};

export { 
  useGetShops,
  useUpdateShopGrowthGoal,
  useGetShopsBrands,
  useGetShopsAreas,
  useGetShopsOnly,
  useGetBrandsForCurriculums
};
