import React, { FormEvent, useEffect, useState } from 'react'
import { useMarkSheetAsRead } from '../../queries/TasksQuery'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ReactLoading from "react-loading";
import Loading from '../Loading'
import {
  useGetTaskInformation,
} from '../../queries/TasksQuery'
import {StButtonTypeB, StButtonTypeBDisabled} from '../../style/styles'
import PreformattedText from '../PreformattedText'

type SheetReadingModalProps = {
  workerId: string
  taskId: string
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function SheetReadingModal(props: SheetReadingModalProps) {
  const markSheetAsRead = useMarkSheetAsRead()

  const { 
    isFetching,
    isLoading,
    data: taskInformationResponse,
  } = useGetTaskInformation(
    {workerId: props.workerId},
    {taskId: props.taskId}
  )

  const [sheets, setSheets] = useState(taskInformationResponse?.sheets || []);
  const [task, setTask] = useState(taskInformationResponse?.sheets || {} as any);

  useEffect(() => {
    setSheets(taskInformationResponse?.sheets || [])
    setTask(taskInformationResponse?.task || {})
  }, [taskInformationResponse])

  const sliderSetting = {
    dots: true,
    infinite: false,
    speed: 500,
    autoPlay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    markSheetAsRead.mutate({
      workerId: props.workerId,
      taskId: props.taskId,
    })
  }

  useEffect(() => {
    if (markSheetAsRead.isSuccess) {
      props.onSuccess()
    }
  }, [markSheetAsRead.isSuccess])

  return (
    <>
      {(isLoading || isFetching) ? <ReactLoading type="spin" /> : <div className="modal">
        <div style={{ maxWidth: '1000px', padding: '0 40px' }}>
          {markSheetAsRead.isLoading ? <Loading /> : ''}
          <div className="modal-close">
            <button type="button" className="modal-close-button" onClick={props.closeModal}>
              <img src="/images/circle-xmark.svg" alt="" className="modal-close-icon" />
            </button>
          </div>
          <div className="modal-header">
            <h1> {task.title} </h1>
          </div>
          <PreformattedText description={task.description ?? ''}/>
          <Slider {...sliderSetting}>
            {sheets?.map((sheet) => {
              return (
                <div key={sheet.id}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      alt=""
                      src={`/api/img/calc/${sheet.id}`}
                      style={{ maxWidth: '100%', maxHeight: '80vh' }}
                    />
                  </div>
                </div>
              )
            })}
            <div>
              <form onSubmit={handleSubmit}>
                <fieldset disabled={!props.canEditAsWorker}>
                  <div className="submit" style={{ textAlign: 'center' }}>
                    <input type="submit" disabled={!props.canEditAsWorker} style={props.canEditAsWorker ? StButtonTypeB : StButtonTypeBDisabled } value="見たよ" />
                  </div>
                </fieldset>{' '}
              </form>
            </div>
          </Slider>
        </div>
      </div>}
    </>
  )
}

export default SheetReadingModal
