import React, { FormEvent, useEffect, useState } from 'react'
import { useLogin } from '../queries/AuthQuery'
import { Navigate, NavLink } from 'react-router-dom'
import { useAuthUser } from '../hooks/AuthUserContext'
import Loading from '../components/Loading'
import '../css/pages/login.css'
import { UserRole } from '../types/UserRole'
import { Button, Card, Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

const Login: React.VFC = () => {
  const login = useLogin()
  const { authUser } = useAuthUser()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')


  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    login.mutate({ username, password })
  }

  type Notice = {
    title: string,
    line1: string,
    line2: string,
    line3: string,
    line4: string,
    line5: string,
  }

  const [notice, setNotice] = useState<Notice>({
    title: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    line5: '',
  })

  useEffect(() => {
    const fetchNotice = async () => {
      try {
        // Supondo que sua API retorne os dados nesse formato.
        const response = await axios.get('api/notice');
        setNotice(response.data); // ajuste aqui conforme a estrutura de dados da sua API.
      } catch (error) {
        console.error(error);
      }
    };

    fetchNotice();
  }, []);

  if (authUser?.id && authUser?.role) {
    switch (authUser.role) {
      case UserRole.Worker:
        return <Navigate to={`/worker_detail/${authUser.id}`} />
      case UserRole.Trainer:
      case UserRole.Store1:
      case UserRole.Manager:
      case UserRole.Admin:
        return <Navigate to={`/shop_select`} />
    }
  }

  return (
    <>
      {login.isLoading && <Loading />}
      <div style={{ background: '#ff0000', padding: '5px' }}></div>
      <div id="login-page" className="container d-flex justify-content-center align-items-center flex-column">
        <div className="mt-4" style={{ width: '380px', maxWidth: '100%' }}>
          <div className="text-center">
            <img
              src="/img/logo.png"
              alt="logo"
              width={64}
              height={64}
            />
            <h1 className="mt-1">e-training</h1>
          </div>
          <Card className="mt-4 mb-1" style={{ background: '#fafafa' }}>
            <Card.Body>
              <div className="my-sm-2 mx-sm-4">
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <div className="text-id">
                      ユーザー（社員番号）
                    </div>
                    <InputGroup className="input-group input-group-icon mb-3">
                      <Form.Control
                        placeholder="ユーザー（社員番号）を入力してください"
                        size='lg'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                      <InputGroup.Text className="input-group-text-icon" style={{ backgroundColor: "#fff" }}><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="mb-3">
                    <div className="text-id">
                      パスワード
                    </div>
                    <InputGroup className="input-group input-group-icon mb-3">
                      <Form.Control
                        placeholder="パスワードを入力してください"
                        size='lg'
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputGroup.Text className="input-group-text-icon" style={{ backgroundColor: "#fff" }}><FontAwesomeIcon icon={faLock} /></InputGroup.Text>
                    </InputGroup>
                  </div>
                  <div className="text-center mt-4">
                    <Button
                      variant="primary"
                      type="submit"
                      className='px-4 btn-login-page'
                    >
                      ログイン
                    </Button>
                  </div>
                  <div className="text-center mt-3">
                    <NavLink
                      style={{
                        display: 'inline-block',
                      }}
                      to={`/password_reset`}
                    >
                      パスワードリセットはこちら
                    </NavLink>
                  </div>
                </form>
              </div>
            </Card.Body>
          </Card>
          <div className="version text-end mt-1 mx-1">version 1.0.0</div>
          <div style={{ textAlign: 'center', fontSize: '13px' }}>
          </div>
        </div>
        <div className="text-center mt-4">
          <div className="text-danger fw-bold">
            <p dangerouslySetInnerHTML={{__html: notice.title}}></p>
          </div>
          <p className="text-danger">
            <br />
            <p dangerouslySetInnerHTML={{ __html:notice.line1 }}></p>
            <br />
            <p dangerouslySetInnerHTML={{ __html:notice.line2 }}></p>
            <br />
            <p dangerouslySetInnerHTML={{ __html:notice.line3 }}></p>
            <br />
            <p dangerouslySetInnerHTML={{ __html:notice.line4 }}></p>
            <br />
            <p dangerouslySetInnerHTML={{ __html:notice.line5 }}></p>
          </p>
        </div>
      </div>
    </>
  )
}

export default Login
