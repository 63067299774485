import { AuthUser } from '../types/AuthUser'
import { useLogout } from '../queries/AuthQuery'
import { useAuthUser } from '../hooks/AuthUserContext'

function AuthStatusHeader() {
  const { authUser } = useAuthUser()
  const logout = useLogout()

  return (
    <header
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '0 5px',
        backgroundColor: 'rgb(56,56,56)',
        color: '#FFF',
        fontSize: '10px',
        height: '24px',
        padding: '0',
      }}
    >
      {authUser && (
        <div style={{ padding: '0 16px' }}>
          {authUser?.username}({authUser?.role}) でログイン中
        </div>
      )}
    </header>
  )
}

export default AuthStatusHeader
