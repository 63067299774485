import React, { FormEvent, useEffect, useState } from 'react'
import { Curriculum } from '../../types/Curriculum'
import { CurriculumGroup } from '../../types/CurriculumGroup'
import { useUpdateWorkerCurriculums } from '../../queries/WorkersQuery'
import Loading from '../Loading'
import CurriculumSelect from '../CurriculumSelect'
import { StButtonTypeB } from '../../style/styles'

type CurriculumAddModalProps = {
  workerId: string
  curriculums: CurriculumGroup[]
  workerCurriculumIds: string[]
  onSuccess: () => void
  closeModal: () => void
  canEditAsWorker: boolean
  canAddCurriculum: boolean
}

function CurriculumAddModal(props: CurriculumAddModalProps) {
  const [curriculumId, setCurriculumId] = useState<string | undefined>(
    undefined
  )

  const updateWorkerCurriculums = useUpdateWorkerCurriculums()

  useEffect(() => {
    if (updateWorkerCurriculums.isSuccess) {
      props.onSuccess()
    }
  }, [updateWorkerCurriculums.isSuccess])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (!curriculumId) return
    const curriculumIds = [...props.workerCurriculumIds, curriculumId]
    updateWorkerCurriculums.mutate({
      workerId: props.workerId,
      curriculumIds,
    })
  }

  const renderGroup = (group: CurriculumGroup): any => {
    if (group.type === 'group') {
      return (
        <optgroup label={group.group_name} key={group.group_name}>
          {renderOptions(group.items)}
        </optgroup>
      )
    } else {
      return renderOptions(group.items)
    }
  }

  const renderOptions = (items: Curriculum[]): any => {
    return items.map((item) => {
      return (
        <option value={item.id}>{item.name}</option>
      )
    })
  }

  return (
    <>
      <div className="modal">
        {updateWorkerCurriculums.isLoading ? <Loading /> : ''}
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.closeModal}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1>カリキュラム追加</h1>
        </div>

        <p>表示するカリキュラムを選んでください</p>

        <form onSubmit={handleSubmit}>
          <fieldset disabled={!props.canAddCurriculum}>
            <CurriculumSelect curriculums={props.curriculums} onSelect={setCurriculumId} />

            {/*<input type="text" id="answercalc" />*/}
            {/*<div id="checkcalc">パスワードチェック</div>*/}
            {/*<div id="answerAreacalc">*/}
            {/*  <button*/}
            {/*    type="submit"*/}
            {/*    name="add_category_button"*/}
            {/*    id="add_category_button"*/}
            {/*    disabled={false}*/}
            {/*  >*/}
            {/*    追加する!*/}
            {/*  </button>*/}
            {/*</div>*/}

            <div>
              <button type="submit" style={StButtonTypeB}>
                追加する!
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  )
}

export default CurriculumAddModal
