import { FormEvent, useEffect, useState } from 'react'
import { useRevokeCurriculumCertificate, useRevokeStepCertificate, useUpdateCurriculumCertificate, useUpdateStepCertificate } from '../../queries/TasksQuery'
import Loading from '../Loading'
import { StButtonTypeB, StButtonTypeBDisabled, textareaStyle } from '../../style/styles'
import TextareaModal from './TextareaModal'

type CertificationModalProps = {
  onClose: () => void
  trainingItem: {id: string, workerId: string | undefined, comment: string, isCertified: boolean}
  onSuccess: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
  hasStep?: boolean
}

function CertificationModal(props: CertificationModalProps) {
  const [comment, setComment] = useState<string>(props.trainingItem.comment || '')

  const [isTextareaModalOpen, setTextareaModalOpen] = useState(false);

  const updateCurriculumCertificate = useUpdateCurriculumCertificate()
  const revokeCurriculumCertificate = useRevokeCurriculumCertificate()
  const updateStepCertificate = useUpdateStepCertificate()
  const revokeStepCertificate = useRevokeStepCertificate()

  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    if (updateCurriculumCertificate.isSuccess || updateStepCertificate.isSuccess || revokeCurriculumCertificate.isSuccess || revokeStepCertificate.isSuccess) {
      setIsLoading(false)
      props.onSuccess()
    }
  }, [updateCurriculumCertificate.isSuccess, updateStepCertificate.isSuccess, revokeCurriculumCertificate.isSuccess, revokeStepCertificate.isSuccess])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    setIsLoading(true)
    if(props.hasStep && props.trainingItem.workerId){
      updateStepCertificate.mutate({
        workerId: props.trainingItem.workerId,
        stepId: props.trainingItem.id,
        comment: comment,
      })
    }else if(props.trainingItem.workerId){
      updateCurriculumCertificate.mutate({
        workerId: props.trainingItem.workerId,
        curriculumId: props.trainingItem.id,
        comment: comment,
      })
    }
  }

  const revokeCertification = () => {
    setIsLoading(true)
    if(props.hasStep && props.trainingItem.workerId){
      revokeStepCertificate.mutate({
        workerId: props.trainingItem.workerId,
        stepId: props.trainingItem.id,
      })
    }else if(props.trainingItem.workerId){
      revokeCurriculumCertificate.mutate({
        workerId: props.trainingItem.workerId,
        curriculumId: props.trainingItem.id,
      })
    }
  }

  return (
    <>
      {updateCurriculumCertificate.isLoading || updateStepCertificate.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.onClose}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          {props.hasStep ? (
            <h1>eトレーナー認定チェック＜STEP＞</h1>
          ) : (
            <h1>eトレーナー認定チェック＜カリキュラム＞</h1>
          )}
        </div>
        <form id="certification-form" onSubmit={handleSubmit}>
          <pre
            style={{
              ...textareaStyle,
              color: comment ? '#000' : '#a0a0a0'
            }}
            onClick={() => setTextareaModalOpen(true)}
          >
            {comment || "ここをクリックして表示したいテキストを入力してください。"}
          </pre>
          <TextareaModal
            isOpen={isTextareaModalOpen}
            onClose={() => setTextareaModalOpen(false)}
            onConfirm={(text) => {
              setComment(text);
              setTextareaModalOpen(false);
            }}
            comment={comment}
          />
        </form>
          <div className="d-flex justify-content-between">
            <button 
              type="submit"
              form="certification-form"
              disabled={!props.canEditAsWorker || isLoading}
              style={(props.canEditAsWorker) ? StButtonTypeB : StButtonTypeBDisabled}
            >
              認定
            </button>
            {(props.trainingItem.isCertified || !props.canEditAsWorker) ? (
              <button
                onClick={revokeCertification}
                style={{
                    padding: '8px 20px',
                    color: '#fff',
                    borderRadius: '10px',
                    fontSize: '21px',
                    cursor: 'pointer',
                    background: 'rgb(233, 77, 77)',
                }}
                disabled={!props.canEditAsWorker || isLoading}
              > 
                取り消し
              </button>
            ) : ''}
            </div>
      </div>
    </>
  )
}

export default CertificationModal
