import React, { FC } from 'react';

interface PreformattedTextProps {
  description: string;
}

const PreformattedText: FC<PreformattedTextProps> = ({ description }) => {
  if(description == '') {
    return null;
  }

  return (
    <div className='preformatted-text' dangerouslySetInnerHTML={{ __html: description }} />
  );
};

export default PreformattedText;
