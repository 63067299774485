import { NavLink, useNavigate } from 'react-router-dom'
import { WorkerListResponse, Worker, TargetWorkersMap } from '../types/WorkerListResponse'
import { AuthUser } from '../types/AuthUser'
import { UserRole } from '../types/UserRole'
import useConfig from '../hooks/GetConfig'
import { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import { StepStatus } from '../types/StepStatus'
import Loading from './Loading'

type WorkerListTableProps = {
  workerListResponse: WorkerListResponse | undefined,
  authUser: AuthUser | undefined,
  showSupports: boolean | undefined,
  showAlarm: boolean | undefined,
  changeStaffListSortState?: string,
  workersSorted: Worker[],
  showSteps: boolean,
}

function WorkerListTable(props: WorkerListTableProps) {
  const config = useConfig();
  const navigate = useNavigate();

  const cantAccessWorker = (worker: Worker) => {
    return props.authUser?.role === UserRole.Worker && props.authUser?.workerId !== worker.id
  }

  const additionalClass = () => {
    if (props.authUser?.role === UserRole.Worker) {
      let result = 'for-staff'
      if (config?.hideFormButtons) {
        result += '-no-button'
      }
      return result
    }
    return ''
  }

  const navigateWorkerDetail = async (
    worker: Worker,
    targetWorkerId: string,
    sectionId: string,
    permission: boolean,
  ) => {
    if (!cantAccessWorker(worker) && permission) {
      navigate(`/worker_detail/${targetWorkerId}#${sectionId}`);
    }
  };

  const contentStaffRef = useRef<HTMLDivElement>(null);

  const [stepsData, setStepsData] = useState<Record<string, any[]>>({});
  const [uniqueSteps, setUniqueSteps] = useState<Record<string, any[]>>({});

  const toggleStepsSummary = async (curriculumId: string) => {
    if (curriculumId) {
      const stepsSummaryOfWorkers = props.workerListResponse?.stepsSummaryOfWorkers;

      if (!stepsSummaryOfWorkers) {
        return;
      }

      const stepSummaryForCurriculum = stepsSummaryOfWorkers.step_summary.filter(item => item.curriculum_id === curriculumId);
      const uniqueStepsForCurriculum = stepsSummaryOfWorkers.unique_steps.filter(item => item.curriculum_id === curriculumId);

      setStepsData(prev => ({
        ...prev,
        [curriculumId]: stepSummaryForCurriculum
      }));

      setUniqueSteps(prev => ({
        ...prev,
        [curriculumId]: uniqueStepsForCurriculum
      }));
    }
  }

  const toggleBulkStepsSummary = async (curriculumIds: string[]): Promise<void> => {
    if (curriculumIds.length > 0) {
      const stepsSummaryOfWorkers = props.workerListResponse?.stepsSummaryOfWorkers;

      if (!stepsSummaryOfWorkers) {
        return;
      }
  
      curriculumIds.forEach((curriculumId) => {
        const stepSummaryForCurriculum = stepsSummaryOfWorkers.step_summary.filter(item => item.curriculum_id === curriculumId);
        const uniqueStepsForCurriculum = stepsSummaryOfWorkers.unique_steps.filter(item => item.curriculum_id === curriculumId);

        setStepsData(prev => ({
          ...prev,
          [curriculumId]: stepSummaryForCurriculum
        }));
  
        setUniqueSteps(prev => ({
          ...prev,
          [curriculumId]: uniqueStepsForCurriculum
        }));
      });
    }
  };

  const [activeCurriculums, setActiveCurriculums] = useState<string[]>([]);

  const handleCurriculumClick = async (curriculumId: string) => {
    setActiveCurriculums(prev => {
      if (prev.includes(curriculumId)) {
        return prev.filter(id => id !== curriculumId);
      } else {
        toggleStepsSummary(curriculumId);
        return [...prev, curriculumId];
      }
    });
  };

  function getBackgroundColorForStep(stepStatus: string = '0', isCertified?: boolean) {
    if (isCertified) {
      switch (stepStatus) {
        case StepStatus.Completed:
          return '#ffe4c4';
        case StepStatus.UntilCurrentMonth:
          return '#fe8a8a';
        case StepStatus.CurrentMonth:
          return '#fff3cc';
        case StepStatus.NextMonth:
          return '#ffebf1';
        case StepStatus.MonthAfterNext:
          return '#e3efda';
        case StepStatus.AfterThatOrNoGoal:
          return '#ffe4c4';
        default:
          return '#ffe4c4';
      }
    } else {
      switch (stepStatus) {
        case StepStatus.Completed:
          return '#deecf8';
        case StepStatus.UntilCurrentMonth:
          return '#fe8a8a';
        case StepStatus.CurrentMonth:
          return '#fff3cc';
        case StepStatus.NextMonth:
          return '#ffebf1';
        case StepStatus.MonthAfterNext:
          return '#e3efda';
        case StepStatus.AfterThatOrNoGoal:
          return '#deecf8';
        default:
          return '#deecf8';
      }
    }
  }

  const totalColSpan = (label: string, curriculums: TargetWorkersMap | undefined) => {
    if (!curriculums) {
      return 1;
    }

    let total = 0;

    curriculums[label].forEach((curriculum) => {
      if (activeCurriculums.includes(curriculum.id)) {
        total += (uniqueSteps[curriculum.id]?.length || 0) + 1;
      } else {
        total += 1;
      }
    });

    return total;
  }

  const [isLoading, setIsLoading] = useState(false);

  const fetchShowStepsData = async () => {

    if(!props.workerListResponse?.curriculumDisplayPreference){
      return;
    }

    setIsLoading(true);

    if (props.showSteps) {
      const curriculumIds: string[] = [];
      const unloadedCurriculumIds: string[] = [];

      props.workerListResponse?.targetWorkers.labels.forEach((label) => {
        props.workerListResponse?.targetWorkers.list[label].forEach((curriculum) => {
          if (!uniqueSteps[curriculum.id]) {
            unloadedCurriculumIds.push(curriculum.id);
          }
          curriculumIds.push(curriculum.id);
        });
      })

      setActiveCurriculums(curriculumIds);
      await toggleBulkStepsSummary(unloadedCurriculumIds);
    } else {
      setActiveCurriculums([]);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchShowStepsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showSteps]);

  return (
    <>
      {isLoading && <Loading />}
      <div className={`content-staff ${additionalClass()}`} ref={contentStaffRef}>
        <table className="staff-list" cellSpacing={0}>
          <thead>
            <tr>
              <td rowSpan={3} colSpan={3} className="avoid-hover" id="staff-name">
                スタッフ名
              </td>
              <td rowSpan={3} id="label-brand">
                {config?.labels?.brand}
              </td>
              {props.workerListResponse?.targetWorkers.labels
                .map((label) => {
                  return (
                    <td key={label} colSpan={totalColSpan(label, props.workerListResponse?.targetWorkers.list)}>
                      {label}
                    </td>
                  );
                })}
              <td rowSpan={3}>
                成長目標
              </td>
            </tr>
            <tr>
              {props.workerListResponse?.targetWorkers.labels.map((label) => (
                props.workerListResponse?.targetWorkers.list[label].map((curriculum) => (
                  <td
                    className="curriculum-name"
                    key={curriculum.id}
                    onClick={() => handleCurriculumClick(curriculum.id)}
                    width="60"
                    style={{
                      fontSize: '13px',
                      ...(activeCurriculums.includes(curriculum.id) && uniqueSteps[curriculum.id]?.length > 0) ? {borderBottom: 0} : {}
                    }}
                    colSpan={
                      activeCurriculums.includes(curriculum.id) ? uniqueSteps[curriculum.id]?.length + 1 : 1
                    }
                    rowSpan={
                      (activeCurriculums.includes(curriculum.id) && uniqueSteps[curriculum.id]?.length > 0) ? 1 : 2
                    }
                  >
                    {curriculum.name}
                  </td>
                ))
              ))}
            </tr>
            <tr>
              {props.workerListResponse?.targetWorkers.labels.map((label) => (
                props.workerListResponse?.targetWorkers.list[label].map((curriculum) => (
                  <>
                    {activeCurriculums.includes(curriculum.id) && uniqueSteps[curriculum.id]?.map((step, index) => {
                      return (
                        <>
                          {index === 0 && (
                            <td
                              width={60}
                              className="curriculum-name"
                              style={{ fontSize: '13px', borderTop: 0 }}
                              onClick={() => handleCurriculumClick(curriculum.id)}
                            ></td>
                          )}
                          <td key={step.id} style={{ fontSize: '12px' }}>
                            {step.step_name}
                          </td>
                        </>)
                    })}
                  </>
                ))
              ))}
            </tr>
          </thead>
          <tbody>
            {props.workersSorted.map((worker) => {
              return (
                <tr key={worker.id}>
                  <td className={"worker-name staff-name " + (cantAccessWorker(worker) ? 'avoid-hover' : '')} >
                    {cantAccessWorker(worker) ? (
                      worker.name
                    ) : (
                      <NavLink to={`/worker_detail/${worker.id}`}>
                        {worker.name}
                      </NavLink>
                    )}
                  </td>

                  {cantAccessWorker(worker) ? (
                    <td className="staff-alarm avoid-hover" aria-label="アラーム">
                      {worker.alarmNum > 0 ? (
                        <>
                          <img src="/img/on.png" alt="on.png" />
                          <span style={{ fontSize: '13px', }} >{worker.alarmNum}</span>
                        </>
                      ) : (
                        <img src="/img/off.png" alt="off.png" />
                      )}
                    </td>
                  ) : (

                    <td className="staff-alarm" aria-label='アラーム'>
                      <NavLink to={`/worker_detail/${worker.id}`}>
                        {worker.alarmNum > 0 ? (
                          <>
                            <img src="/img/on.png" alt="on.png" />
                            <span style={{ fontSize: '13px', }} >{worker.alarmNum}</span>
                          </>
                        ) : (
                          <img src="/img/off.png" alt="off.png" />
                        )}
                      </NavLink>
                    </td>
                  )}

                  {props.authUser?.role === UserRole.Worker &&
                    props.authUser?.workerId !== worker.id ? (
                    <td className="calendar-alert">
                      {worker.isCalendarAlert === true ? (
                        <img src="/img/cal-on.png" alt="cal-on.png" />
                      ) : (
                        <img src="/img/cal-off.png" alt="cal-off.png" />
                      )}
                    </td>
                  ) : (
                    <td className="calendar-alert">
                      <NavLink to={`/worker_detail/${worker.id}`}>
                        {worker.isCalendarAlert === true ? (
                          <img src="/img/cal-on.png" alt="cal-on.png" />
                        ) : (
                          <img src="/img/cal-off.png" alt="cal-off.png" />
                        )}
                      </NavLink>
                    </td>
                  )}

                  <td className="staff-supporter">
                    {worker.isSupporter === true ? (worker.brandAbrev ?? worker.brandName.slice(0, 2)) : '' }
                  </td>
                  {props.workerListResponse?.targetWorkers.labels.map((label) => (
                    props.workerListResponse?.targetWorkers.list[label].map((curriculum) => {
                      const curriculumByWorker = (worker.curriculums ?? []).find((item) => item.id === curriculum.id);
                      const backgroundColor = getBackgroundColorForStep(curriculumByWorker?.curriculum_status, curriculumByWorker?.is_certified);
                      return (
                        <>
                          <td
                            key={curriculum.id}
                            width="60"
                            onClick={() => { navigateWorkerDetail(worker, worker.id, curriculum.id, !!curriculumByWorker) }}
                            style={{
                              width: '60px !important',
                              whiteSpace: 'nowrap',
                              fontSize: '13px',
                              backgroundColor: backgroundColor,
                              cursor: (cantAccessWorker(worker) || !curriculumByWorker) ? 'default' : 'pointer',
                            }}
                          >
                            <span>
                              {curriculumByWorker &&
                                curriculumByWorker?.doneSteps >= curriculumByWorker?.totalSteps ? (
                                <img src="/img/king.png" alt="king.png" />
                              ) : (
                                curriculumByWorker && (
                                  <>
                                    <span style={{ fontSize: '24px', fontWeight: 'bold', }}>
                                      {curriculumByWorker?.doneSteps}
                                    </span>
                                    /{curriculumByWorker?.totalSteps}
                                  </>
                                )
                              )}
                            </span>
                          </td>
                          {activeCurriculums.includes(curriculum.id) && uniqueSteps[curriculum.id]?.map((step) => {
                            const workerStepData = stepsData[curriculum.id]?.find(s => s.worker_id === worker.id && s.step_id === step.step_id);
                            const taskDoneCount = workerStepData ? workerStepData.done_task_count : 0;
                            const taskCount = workerStepData ? workerStepData.task_count : 0;

                            const backgroundColor = getBackgroundColorForStep(workerStepData?.step_status, workerStepData?.is_certified);
                            const isEmptyTask = taskDoneCount === 0 && taskCount === 0;

                            if(!curriculumByWorker) {
                              return (
                                <td
                                  key={step.id}
                                  style={{
                                    backgroundColor: backgroundColor,
                                    cursor: (cantAccessWorker(worker) || !curriculumByWorker) ? 'default' : 'pointer',
                                  }}
                                >
                                </td>
                              );
                            }

                            return (
                              <td
                                key={step.id}
                                style={{
                                  backgroundColor: backgroundColor,
                                  cursor: (cantAccessWorker(worker) || !curriculumByWorker) ? 'default' : 'pointer',
                                }}
                                onClick={() => { navigateWorkerDetail(worker, worker.id, step.step_id, !!workerStepData) }}
                              >
                                {!isEmptyTask && (
                                  taskDoneCount >= taskCount ? (
                                    <img src="/img/king.png" alt="king" width={24} />
                                  ) : (
                                    <>
                                      <span style={{ fontSize: '20px', fontWeight: '700' }}>{taskDoneCount}</span>
                                      <span style={{ fontSize: '13px' }}>/{taskCount}</span>
                                    </>
                                  )
                                )}
                              </td>
                            );
                          })}
                        </>
                      )}
                    )
                  ))}
                  <td style={{ minWidth: '240px', maxWidth: '393px' }}>
                    <div id={'staff-goal'} >
                      {worker.growthGoal}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div >
    </>
  )
}

export default WorkerListTable
