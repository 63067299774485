import React, { useEffect } from "react";
import { useLogout } from "../queries/AuthQuery";
import { useAuthUser } from "../hooks/AuthUserContext";

const Logout: React.VFC = () => {
  const { authUser } = useAuthUser();
  const logout = useLogout();

  useEffect(() => {
    if (authUser?.id) {
      logout.mutate();
    }
  }, [authUser]);

  return <></>;
};

export default Logout;
