import { useEffect, useState } from 'react'
import { useAuthUser } from '../hooks/AuthUserContext'
import { useGetWorkersOfShop } from '../queries/WorkersQuery'
import { Worker } from '../types/WorkerListResponse'
import { useParams } from 'react-router-dom'
import Loading from '../components/Loading'
import ShopHeader from '../components/ShopHeader'
import WorkerListTable from '../components/WorkerListTable'
import WorkerCurriculumBulkAddRemove from '../components/WorkerCurriculumBulkAddRemove'

interface WorkerListProps {
  isBulkEditing?: boolean
}

enum Tab {
  Add,
  Remove,
}

function WorkerList(props: WorkerListProps) {
  const { shopId, brandId, type } = useParams()
  const { authUser, setAuthUser } = useAuthUser()
  const [isBulkEditing, setIsBulkEditing] = useState<boolean>(props.isBulkEditing || false)
  const [curriculumBrandName, setCurriculumBrandName] = useState<string|undefined>('')
  const [employmentTypeId, setEmploymentTypeId] = useState<string|undefined>('')
  const [showSupports, setShowSupports] = useState<boolean|undefined>(false)
  const [showAlarm, setShowAlarm] = useState<boolean|undefined>(false)
  const [showNotSupports, setShowNotSupports] = useState<boolean|undefined>(false)
  const [showCalendarAlert, setShowCalendarAlert] = useState<boolean|undefined>(false)
  const [changeStaffListSortState, setChangeStaffListSortState] = useState<string>('default');
  const tabSelected = type === 'remove' ? Tab.Remove : Tab.Add;

  const {
    isLoading,
    isFetching,
    refetch,
    data: workerListResponse,
  } = useGetWorkersOfShop({ shopId }, { brandId }, { curriculumBrandName }, { employmentTypeId })

  const [workersSorted, setWorkersSorted] = useState<Worker[]>()

  const onUpdate = () => {
    refetch()
  }

  const toggleBulkEditing = () => {
    let isEditing = !isBulkEditing
    setIsBulkEditing(isEditing)
    if (isEditing) {
      setCurriculumBrandName(workerListResponse?.brand.id)
    } else {
      setCurriculumBrandName('')
    }
  }

  const setHasSupporter = (supporter: boolean) => {
    setShowSupports(supporter)
  }

  const setHasShowAlarm = (showAlarm: boolean) => {
    setShowAlarm(showAlarm);
  }

  const setHasNotSupporter = (notSupporter: boolean) => {
    setShowNotSupports(notSupporter)
  }

  const setHasCalendarAlert = (calendarAlert: boolean) => {
    setShowCalendarAlert(calendarAlert)
  }

  const setChangeStaffListSort = (sort: string) => {
    setChangeStaffListSortState(sort)
  }

  useEffect(() => {
    applyFilters();
  }, [showAlarm, showSupports, showNotSupports, showCalendarAlert, changeStaffListSortState]);


  // consts
  const sortWorkersByDoneTasks = (workers: Worker[]) => {
    return workers.sort((a, b) => {
      const doneNumA = (a.curriculums ?? []).reduce(
        (carry, curriculum) =>
          carry +
          curriculum.steps.reduce(
            (stepCarry, step) => stepCarry + step.doneTasks,
            0
          ),
        0
      );
      const doneNumB = (b.curriculums ?? []).reduce(
        (carry, curriculum) =>
          carry +
          curriculum.steps.reduce(
            (stepCarry, step) => stepCarry + step.doneTasks,
            0
          ),
        0
      );
  
      return doneNumB - doneNumA;
    });
  };

  function sortWorkers(workers: Worker[], sort?: string) {
    return workers.sort((workerA, workerB) => {
      const curriculumsA = workerA.curriculums ?? [];
      const curriculumsB = workerB.curriculums ?? [];
  
      const crownA = curriculumsA.filter((curriculum) => curriculum.doneSteps === curriculum.totalSteps);
      const pendingStepTasksACount = curriculumsA.filter((curriculum) => curriculum.doneSteps !== curriculum.totalSteps).length;
      const crownB = curriculumsB.filter((curriculum) => curriculum.doneSteps === curriculum.totalSteps);
      const pendingStepTasksBCount = curriculumsB.filter((curriculum) => curriculum.doneSteps !== curriculum.totalSteps).length;
      const crownACount = crownA.length;
      const crownBCount = crownB.length;
  
      if (crownACount !== crownBCount) {
        if (sort === 'default') {
          return crownBCount - crownACount;
        }
        return sort === 'asc' ? crownBCount - crownACount : crownACount - crownBCount;
      }
  
      const usedCrownASteps = crownA.reduce((acc, curriculum) => acc + curriculum.doneSteps, 0);
      const usedCrownBSteps = crownB.reduce((acc, curriculum) => acc + curriculum.doneSteps, 0);
  
      const sumDoneStepsA = curriculumsA.reduce((acc, curriculum) => acc + curriculum.doneSteps - usedCrownASteps, 0);
      const sumDoneStepsB = curriculumsB.reduce((acc, curriculum) => acc + curriculum.doneSteps - usedCrownBSteps, 0);
  
      if (sumDoneStepsA !== sumDoneStepsB) {
        if (sort === 'default') {
          return sumDoneStepsB - sumDoneStepsA;
        }
        return sort === 'asc' ? sumDoneStepsB - sumDoneStepsA : sumDoneStepsA - sumDoneStepsB;
      }
  
      if (sort === 'default') {
        return pendingStepTasksBCount - pendingStepTasksACount;
      }
      return sort === 'asc' ? pendingStepTasksBCount - pendingStepTasksACount : pendingStepTasksACount - pendingStepTasksBCount;
    });
  }
  

  const applyFilters = () => {
    let filteredWorkers = [];
    let workers = workerListResponse?.workers ?? [];
  
    if (showSupports === true) {
      filteredWorkers = workers.filter(worker => worker.isSupporter === true);
    } else if(showNotSupports === true) {
      filteredWorkers = workers.filter(worker => worker.isSupporter === false);
    }else{
      filteredWorkers = workerListResponse?.workers ?? [];
    }
  
    let workersSupports: Worker[] = [];
    let workersNoSupports: Worker[] = [];
  
    if (filteredWorkers.length > 0) {
      workersSupports = filteredWorkers.filter(worker => worker.isSupporter === true);
      workersNoSupports = filteredWorkers.filter(worker => worker.isSupporter === false);
  
      if (showAlarm === true) {
        workersNoSupports = workersNoSupports.filter(worker => worker.alarmNum > 0);
        workersSupports = workersSupports.filter(worker => worker.alarmNum > 0);
      }

      if(showCalendarAlert === true){
        workersNoSupports = workersNoSupports.filter(worker => worker.isCalendarAlert === true);
        workersSupports = workersSupports.filter(worker => worker.isCalendarAlert === true);
      }

      workersNoSupports = sortWorkersByDoneTasks(workersNoSupports.sort((a: Worker, b: Worker) => Number(a.id) - Number(b.id)));
      workersSupports = sortWorkersByDoneTasks(workersSupports);
    }
  
    let workersFiltered = workersNoSupports.concat(workersSupports);
  
    if (changeStaffListSortState !== 'default') {
      workersFiltered = sortWorkers(workersFiltered, changeStaffListSortState);
    }
  
    setWorkersSorted(workersFiltered);
  };
  
  // effects
  useEffect(() => {
    applyFilters();
  }, [workerListResponse])

  const [showSteps, setShowSteps] = useState(true);

  const handleShowAllCurriculums = (toggleCurriculums: boolean) => {
    setShowSteps(toggleCurriculums);
  }

  return (
    <>
      <link rel="stylesheet" href="/css/pages/worker_list.css" />
      <div>
        {workerListResponse?.shop ? (
          <ShopHeader
            shopId={workerListResponse.shop.id}
            shopName={workerListResponse.shop.name}
            brandName={workerListResponse.brand.name}
            form={workerListResponse.form}
            growthGoal={workerListResponse.shop.growthGoal}
            brandId={workerListResponse.brand.id}
            onUpdate={onUpdate}
            isBulkEditing={isBulkEditing}
            onToggleBulkEditing={() => toggleBulkEditing()}
            curriculumBrandName={curriculumBrandName}
            handleCurriculumBrandName={(name: string) => setCurriculumBrandName(name)}
            hasSupporter={showSupports}
            setHasSupporter={(supporter: boolean) => setHasSupporter(supporter)}
            hasNotSupporter={showNotSupports}
            setHasNotSupporter={(notSupporter: boolean) => setHasNotSupporter(notSupporter)}
            hasCalendarAlert={showCalendarAlert}
            setHasCalendarAlert={(calendarAlert: boolean) => setHasCalendarAlert(calendarAlert)}
            hasAlarm={showAlarm}
            setHasAlarm={(alarm: boolean) => setHasShowAlarm(alarm)}
            changeStaffListSortState={changeStaffListSortState}
            setChangeStaffListSortState={(sort) => setChangeStaffListSort(sort)}
            curriculumDisplayPreference={workerListResponse.curriculumDisplayPreference}
            handleShowAllCurriculums={handleShowAllCurriculums}
            showSteps={showSteps}
          />
        ) : (
          ''
        )}

        {isLoading || isFetching ? (
          <Loading />
        ) : (
          <div style={{
            padding: '0px 20px',
            backgroundColor: '#eee',
            width: 'auto',
          }}>
            {!isBulkEditing ? (
              <WorkerListTable
                workerListResponse={workerListResponse}
                authUser={authUser}
                showSupports={showSupports}
                showAlarm={showAlarm}
                changeStaffListSortState={changeStaffListSortState}
                workersSorted={workersSorted||[]}
                showSteps={showSteps}
              />
            ) : (
              <WorkerCurriculumBulkAddRemove
                workerListResponse={workerListResponse}
                onSuccess={onUpdate}
                showSupports={showSupports}
                workersSorted={workersSorted||[]}
                Tab={tabSelected}
              />
            )}
          </div>

        )}
      </div>
    </>
  )
}

export default WorkerList
