import * as api from "../api/WorkersAPI";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

const useGetWorkersOfShop = (
  {shopId}: { shopId: string | undefined},
  {brandId}: { brandId: string | undefined },
  {curriculumBrandName}: { curriculumBrandName: string | undefined},
  {employmentTypeId}: { employmentTypeId: string | undefined}) => {
  return useQuery(["workersOfShop", shopId, brandId, curriculumBrandName, employmentTypeId], () =>
    api.getWorkersOfShop(shopId, brandId, curriculumBrandName, employmentTypeId)
  );
};

const useGetWorkerDetail = (
  { workerId }: { workerId: string | undefined },
  {curriculumBrandName}: { curriculumBrandName: string | undefined }
) => {
  return useQuery(["workerDetail", workerId, curriculumBrandName], () =>
    api.getWorkerDetail(workerId, curriculumBrandName)
  );
};

const useUpdateGrowthGoal = () => {
  return useMutation(api.updateGrowthGoal, {
    onSuccess: () => {
      toast.success("今月の目標を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message || "今月の目標の更新に失敗しました。"
      );
    },
  });
};

const useUpdateWorkerCurriculums = () => {
  return useMutation(api.updateWorkerCurriculums, {
    onSuccess: () => {
      toast.success("スタッフのカリキュラムを更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
        "スタッフのカリキュラムの更新に失敗しました。"
      );
    },
  });
};

const useReloadRelationCurriculum = () => {
  return useMutation(api.reloadRelationCurriculum, {
    onSuccess: () => {
      toast.success("カリキュラム情報を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
        "カリキュラム情報の更新に失敗しました。"
      );
    },
  });
};

const useUpdateCurriculumSchedule = () => {
  return useMutation(api.updateCurriculumSchedule, {
    onSuccess: () => {
      toast.success("カリキュラム情報の更新が予定されていました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
        "カリキュラム情報の更新に失敗しました。"
      );
    },
  });
};


const useRegisterWorkerCurriculum = () => {
  return useMutation(api.registerWorkerCurriculum, {
    onSuccess: () => {
      toast.success("カリキュラムを追加しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
        "カリキュラムの追加に失敗しました。"
      );
    },
  });
};

const useDeleteWorkerCurriculum = () => {
  return useMutation(api.deleteWorkerCurriculums, {
    onSuccess: () => {
      toast.success("カリキュラムを削除しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
        "カリキュラムの削除に失敗しました。"
      );
    },
  });
};

const useGetCurriculumEditInformation = (
  {areaId}: { areaId: string | undefined},
  {shopId}: { shopId: string | undefined},
  {brandId}: { brandId: string | undefined },
  {employmentTypeId}: { employmentTypeId: string | undefined}) => {
  return useQuery([areaId, shopId, brandId, employmentTypeId], () =>
    api.getCurriculumEditInformation(areaId, shopId, brandId, employmentTypeId)
  );
};

const useGetCurriculumUpdate = () => {
  return useQuery([], () =>
    api.getCurriculumUpdate()
  );
};

const useGetFiltersCsvOutput = (
  { shopId }: { shopId: string | undefined },
  { brandId }: { brandId: string | undefined },
  { targetWorker }: { targetWorker: string | undefined }
) => {
  return useQuery([shopId, brandId, targetWorker], () =>
    api.getFiltersCsvOutput(shopId, brandId, targetWorker)
  );
}

const useGetCurriculumDisplaySetting = () => {
  return useQuery([], () =>
    api.getCurriculumDisplaySetting()
  );
}

const useUpdateCurriculumDisplaySetting = () => {
  return useMutation(api.updateCurriculumDisplaySetting, {
    onSuccess: () => {
      toast.success("カリキュラム表示設定を更新しました。");
    },
    onError: (error: any) => {
      toast.error(
        error.response?.data?.message ||
        "カリキュラム表示設定の更新に失敗しました。"
      );
    },
  });
}

export {
  useGetWorkersOfShop,
  useGetWorkerDetail,
  useUpdateGrowthGoal,
  useUpdateWorkerCurriculums,
  useReloadRelationCurriculum,
  useRegisterWorkerCurriculum,
  useDeleteWorkerCurriculum,
  useGetCurriculumEditInformation,
  useGetCurriculumUpdate,
  useUpdateCurriculumSchedule,
  useGetFiltersCsvOutput,
  useGetCurriculumDisplaySetting,
  useUpdateCurriculumDisplaySetting,
};

