import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthUser } from "../hooks/AuthUserContext";

const Home: React.VFC = () => {
  const { authUser } = useAuthUser();

  if (authUser?.id && authUser?.shopId) {
    return <Navigate to={`/shop/${authUser.shopId}/worker_list/${authUser.worker?.brand_id}`} />;
  } else if (authUser?.id) {
    return <Navigate to={`/shop_select`} />;
  } else {
    return <Navigate to={`/login`} />;
  }
};
export default Home;
