import React, { ChangeEvent, useEffect, useState } from 'react'
import Loading from '../components/Loading'
import { useDeleteUser, useGetUsers } from '../queries/UsersQuery'
import { useLogout } from '../queries/AuthQuery'
import CustomPagination from '../components/CustomPagination'
import { Button, Card, Col, Dropdown, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faPenToSquare, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import Header from '../components/Header'

function UserList() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [search, setSearch] = useState('')
  const { isLoading, isFetching, refetch, data: usersResponse } = useGetUsers({ page, limit, search })
  const deleteUser = useDeleteUser()
  const logout = useLogout()

  const [tempSearch, setTempSearch] = useState('')

  const onUpdate = () => {
    refetch()
  }

  useEffect(() => {
    if (deleteUser.isSuccess) {
      onUpdate()
    }

    if (search !== tempSearch) {
      setTempSearch(search)
    }

  }, [deleteUser.isSuccess, usersResponse])

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
    refetch();
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTempSearch(e.target.value);
  };

  const handleSearch = () => {
    setSearch(tempSearch);
    setPage(1);
  }

  return (
    <>
      <Header></Header>

      <div className="content-main">
        <div className="d-flex flex-column">
          <h3 className="align-self-center mt-3 mb-4" style={{ fontSize: '22px' }}>【 ユーザー 一覧 】</h3>
          {deleteUser.isLoading || isLoading || isFetching ? (
              <Loading />
            ) : (
              <>
                <Card style={{ backgroundColor: '#fff' }}>
                  <Card.Header style={{ backgroundColor: '#fff' }}>
                    <div className="d-flex align-items-end justify-content-between gap-2 flex-wrap">
                      <Button href={`/shop_select`} variant="default" className="px-4"><FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon> メニューへ戻る</Button>
                      <Button href={`/users/create`} variant="primary" className="px-4">新規登録</Button>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="box-w-md">
                      <Row className="align-items-center row justify-content-center mb-2 p-0">
                        <Row className="row align-items-center mb-2 p-0">
                          <Col md={2}>
                            <div className="d-flex justify-content-start">
                              <label className="col-form-label text-nowrap">検索：</label>
                              <input
                                type="text"
                                className="form-control form-search-filter"
                                id="search-filter"
                                name="search-filter"
                                value={tempSearch}
                                onChange={handleInputChange}
                                onBlur={handleSearch}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleSearch()
                                  }
                                }}
                              />
                            </div>
                          </Col>
                          <Col md={10}>
                            <div className="d-flex justify-content-end">
                              {usersResponse && (
                                <CustomPagination
                                  pagination={usersResponse}
                                  onPageChange={handlePageChange}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row className="p-0">
                          <div className="table-users-list table-responsive mb-2" style={{ minHeight: 250 }}>
                            <table className="table table-striped table-hover table-bordered table-nowrap mb-0">
                              <thead>
                                <tr>
                                  <th>ログインID</th>
                                  <th>ユーザー名</th>
                                  <th>店舗</th>
                                  <th>ロール</th>
                                  <th style={{ width: '100px' }}>操作</th>
                                </tr>
                              </thead>
                              <tbody>
                                {usersResponse?.data.map((user) => {
                                  return (
                                    <tr key={user.id}>
                                      <td>{user.username}</td>
                                      <td>{user.name}</td>
                                      <td>{user.shop_name}</td>
                                      <td>{user.role}</td>
                                      <td>
                                        <Dropdown id="user-list">
                                          <Dropdown.Toggle size="sm" variant="default" id="dropdown-basic" style={{ color: '#808080' }}>
                                            操作を選択
                                          </Dropdown.Toggle>
                                          <Dropdown.Menu>
                                            <Dropdown.Item className="fs-sm" href={`/users/update/${user.id}`}><FontAwesomeIcon icon={faPenToSquare}></FontAwesomeIcon> 編集</Dropdown.Item>
                                            <Dropdown.Item
                                              className="fs-sm"
                                              style={{ color: '#DC3545' }}
                                              onClick={(e) => {
                                                e.preventDefault()
                                                if (window.confirm('削除してよろしいですか？')) {
                                                  deleteUser.mutate({ userId: user.id })
                                                }
                                              }}
                                            >
                                              <FontAwesomeIcon icon={faTrashCan}></FontAwesomeIcon> 削除
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>

                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </Row>
                        <Row className="p-0">
                          <Col>
                            <div className="d-flex align-items-center justify-content-start">
                              <select
                                className="form-select form-select-sm"
                                style={{ width: '80px' }}
                                value={limit}
                                onChange={(e) => {
                                  setPage(1)
                                  setLimit(parseInt(e.target.value))
                                }}
                              >
                                <option value="25" selected>25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              <div className="text-nowrap ps-1" style={{ fontSize: '13px' }}>件表示</div>
                            </div>
                            <div className="mt-1" style={{ fontSize: '13px' }}>{usersResponse?.total}件中 {usersResponse?.from}～{usersResponse?.to}件表示しています</div>
                          </Col>
                          <Col>
                            <div className="d-flex justify-content-end">
                              {usersResponse && (
                                <CustomPagination
                                  pagination={usersResponse}
                                  onPageChange={handlePageChange}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Row>
                    </div>
                  </Card.Body>
                </Card>
              </>
            )}
        </div>
      </div>
    </>
  )
}

export default UserList
