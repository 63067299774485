type ProgressBarProps = {
  data: Array<Number>
}

function ProgressBar(props: ProgressBarProps) {
  const sections = [];
  for (let index = 1; index < 101; index++) {
    if (props.data.includes(index)) {
      const element = <div key={index} style={{ display: "inline-block", backgroundColor: "#02ADEF", height: "20px", width: "1%" }} />
      sections.push(element);
    } else {
      const space = <div key={index} style={{ display: "inline-block", height: "20px", width: "1%" }} />
      sections.push(space);
    }
  }


  return (
    <div>
      <div
        style={{
          backgroundColor: "#555",
          height: "20px",
          width: "100%",
          marginBottom: "10px",
        }}
      >
        <div style={{ position: "absolute", textAlign: "center", width: "100%", paddingTop: '3px'}}>
          {props.data.length}%
        </div>
        {sections}
      </div>
    </div>
  );
}

export default ProgressBar;
