import { useEffect, useRef, useState } from 'react'
import { useLogout } from '../queries/AuthQuery'
import { useModal } from 'react-hooks-use-modal'
import { ShopGrowthGoalEditModal } from './modal/ShopGrowthGoalEditModal'
import { useGetBrandsForCurriculums, useGetShopsBrands } from '../queries/ShopsQuery'
import { useGetPasswordConfig } from '../queries/UsersQuery'
import { useAuthUser } from '../hooks/AuthUserContext'
import { NavLink, useNavigate } from 'react-router-dom'
import { buttonFormStyleDisabled } from '../style/styles'
import Loading from './Loading'
import { UserRole } from '../types/UserRole'
import useConfig from '../hooks/GetConfig'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGear } from '@fortawesome/free-solid-svg-icons'
import { Form } from '../types/WorkerDetailResponse'
import { ColorSettingModal } from './modal/ColorSettingModal'
import { Button } from 'react-bootstrap'

type ShopHeaderProps = {
  shopId: string
  brandId: string | undefined
  shopName: string
  brandName: string
  growthGoal: string
  form: Form
  onUpdate: () => void
  isBulkEditing?: boolean
  onToggleBulkEditing?: () => void
  curriculumBrandName?: string
  handleCurriculumBrandName?: (name: string) => void
  hasSupporter?: boolean
  setHasSupporter?: (supporter: boolean) => void
  hasNotSupporter?: boolean
  setHasNotSupporter?: (supporter: boolean) => void
  hasCalendarAlert?: boolean
  setHasCalendarAlert?: (calendar: boolean) => void
  hasAlarm?: boolean
  setHasAlarm?: (alarm: boolean) => void
  setChangeStaffListSortState?: (sort: string) => void
  changeStaffListSortState?: string
  curriculumDisplayPreference?: boolean
  handleShowAllCurriculums: (toggleCurriculums: boolean) => void
  showSteps: boolean
}

function ShopHeader(props: ShopHeaderProps) {
  // states
  const [canEditAsWorker, setCanEditAsWorker] = useState<boolean>(false)
  const [canEditAsTrainer, setCanEditAsTrainer] = useState<boolean>(false)
  const [brandId, setBrandId] = useState<string | undefined>(props.brandId)
  const [isSortingStaffs, setIsSortingStaffs] = useState<boolean>(false)
  const navigate = useNavigate()

  // hooks
  const logout = useLogout()
  const { authUser } = useAuthUser()
  const { isLoading, isFetching, refetch, data: passwordConfig } = useGetPasswordConfig()
  const { data: getShopsBrandsResponse } = useGetShopsBrands(props.shopId)
  const { data: brandsForCurriculums } = useGetBrandsForCurriculums(props.shopId)
  const [Modal, open, close, isOpen] = useModal('root', { preventScroll: true, closeOnOverlayClick: true, })
  const [ModalPassword, openModalPassword, closeModalPassword, isOpenModalPassword] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })
  // modal setting color
  const [ModalSettingColor, openModalSettingColor, closeModalSettingColor, isOpenModalSettingColor] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: true,
  })

  // methods
  const setHasSupporter = (supporter: boolean) => { props.setHasSupporter!(supporter) }
  const setHasNotSupporter = (supporter: boolean) => { props.setHasNotSupporter!(supporter) }
  const setHasCalendarAlert = (calendar: boolean) => { props.setHasCalendarAlert!(calendar) }

  const onEnableSort = () => {
    if (!props.setChangeStaffListSortState) return
    if (!isSortingStaffs) {
      props.setChangeStaffListSortState('asc')
    }
    if (isSortingStaffs) {
      props.setChangeStaffListSortState('default')
    }
    setIsSortingStaffs(!isSortingStaffs)
  }

  const handleSortStaffs = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.value) {
      case '1':
        props.setChangeStaffListSortState!('asc')
        break
      case '2':
        props.setChangeStaffListSortState!('desc')
        break
      default:
        props.setChangeStaffListSortState!('default')
        break
    }
  }

  const setHasAlarm = (alarm: boolean) => {
    props.setHasAlarm!(alarm)
  }

  const handleSorts = (event: React.ChangeEvent<HTMLSelectElement>) => {
    switch (event.target.value) {
      case '1':
        setHasNotSupporter(true)
        setHasSupporter(false)
        setHasAlarm(false)
        setHasCalendarAlert(false)
        break
      case '2':
        setHasSupporter(true)
        setHasAlarm(false)
        setHasCalendarAlert(false)
        setHasNotSupporter(false)
        break
      case '3':
        setHasAlarm(true)
        setHasSupporter(false)
        setHasNotSupporter(false)
        setHasCalendarAlert(false)
        break
      case '4':
        setHasCalendarAlert(true)
        setHasSupporter(false)
        setHasAlarm(false)
        setHasNotSupporter(false)
        break
      default:
        setHasAlarm(false)
        setHasSupporter(false)
        setHasNotSupporter(false)
        setHasCalendarAlert(false)
        break
    }
  }

  // consts
  const config = useConfig();
  const hasRoleManagerAdmin = authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin
  const hasRoleStore1ManagerAdmin = authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin || authUser?.role === UserRole.Store1
  const hasRoleTrainerManagerAdmin = authUser?.role === UserRole.Trainer || authUser?.role === UserRole.Manager || authUser?.role === UserRole.Admin || authUser?.role === UserRole.Store1

  // effects
  useEffect(() => {
    switch (authUser?.role) {
      case UserRole.Worker:
        setCanEditAsWorker(false)
        setCanEditAsTrainer(false)
        break
      case UserRole.Trainer:
      case UserRole.Store1:
      case UserRole.Manager:
        setCanEditAsWorker(false)
        setCanEditAsTrainer(props.shopId === authUser?.shopId)
        break
      case UserRole.Admin:
        setCanEditAsWorker(true)
        setCanEditAsTrainer(true)
        break
    }
  }, [authUser])

  useEffect(() => {
    if (brandId != undefined) {
      navigate("/shop/" + props.shopId + "/worker_list/" + brandId)
    }
  }, [brandId])

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const toggleOpenDropdown = () => setIsOpenDropdown(!isOpenDropdown);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (isOpenDropdown && dropdownRef.current && !dropdownRef.current.contains(e.target as Node)) {
        setIsOpenDropdown(false)
      }
    }

    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [isOpenDropdown])

  return (
    <>
      {logout.isLoading && <Loading />}
      <header>
        <table>
          <tbody>
            <tr className="header-row-top" style={{ maxHeight: '62px' }}>
              <td className="head-logo"><img src="/img/calc07.png" alt="logo.png" /></td>
              <td className="head-name">{props.shopName} {props.brandName}</td>
              <td className="head-nav">
                <div className="head-nav-inner">
                  <a
                    className="btn btn-sm btn-default"
                    onClick={(e) => {
                      e.preventDefault()
                      logout.mutate()
                    }}
                  >
                    ログアウト
                  </a>
                  <div id="dropdown-component" className="dropdown" ref={dropdownRef}>
                    <button onClick={toggleOpenDropdown} type="button" className="btn btn-settingicon" id="btn-setting">
                      <FontAwesomeIcon icon={faGear} size='xl' />
                      <div>設定</div>
                    </button>
                    <ul style={{ display: (isOpenDropdown ? 'block' : 'none') }} className="dropdown-menu dropdown-menu-right dropdown-menu-separate">
                      {hasRoleStore1ManagerAdmin && (
                        <>
                          {!props.isBulkEditing ? (
                            <li>
                              <a
                                href="#"
                                className='dropdown-item'
                                onClick={(e) => {
                                  e.preventDefault()
                                  props.onToggleBulkEditing?.()
                                }}
                              >
                                カリキュラム一括設定
                              </a>
                            </li>

                          ) : (
                            <li>
                              <a
                                href="#"
                                className='dropdown-item'
                                onClick={(e) => {
                                  e.preventDefault()
                                  window.location.reload()
                                }}
                              >
                                スタッフ一覧に戻る
                              </a>
                            </li>
                          )}
                        </>
                      )}
                      <li>
                        <NavLink className='dropdown-item' to={`/password_change`}>
                          パスワード変更
                        </NavLink>
                      </li>

                      {(authUser?.role !== UserRole.Worker) ? (
                        <>
                          <li>
                            <a
                              className='dropdown-item'
                              href="#"
                              onClick={(e) => {
                                e.preventDefault()
                                setIsOpenDropdown(false)
                                openModalPassword()
                              }}
                            >
                              リセットパスワード表示
                            </a>
                          </li>
                          <ModalPassword>
                            <div className="modal">
                              <div className="modal-close">
                                <button type="button" className="modal-close-button" onClick={(e) => { closeModalPassword() }}>
                                  <img src="/images/circle-xmark.svg" className="modal-close-icon" />
                                </button>
                              </div>
                              <div className="modal-header">
                                <h1>リセットパスワード表示</h1>
                              </div>

                              <div>
                                <input type='text' value={passwordConfig} className='catselect width96'></input>
                              </div>

                            </div>
                          </ModalPassword>
                        </>
                      ) : (
                        ''
                      )}
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="header-row-second">
              {authUser?.role !== UserRole.Worker ? (
                <td className="shop-desc" colSpan={3}>
                  <div className="shop-goal">
                    <div className="shop-goal-header">
                      <div className="">今月の目標</div>
                      <a
                        href="#"
                        className='btn btn-sm btn-primary ms-2'
                        onClick={(e) => {
                          e.preventDefault()
                          open()
                        }}
                      >
                        編集する
                      </a>
                      <Modal>
                        <ShopGrowthGoalEditModal
                          shopId={props.shopId}
                          growthGoal={props.growthGoal}
                          closeModal={close}
                          onSuccess={() => {
                            props.onUpdate()
                            close()
                          }}
                          canEditAsWorker={canEditAsWorker}
                          canEditAsTrainer={canEditAsTrainer}
                        />
                      </Modal>
                    </div>
                    <div className="shop-goal-body">
                      <p className="py-0">{props.growthGoal}</p>
                    </div>
                  </div>
                </td>
              ) : (
                ''
              )}
            </tr>
          </tbody>
        </table>
      </header>
      <div className="content">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0 5px',
          }}
        >
          <div className="stafflist-header">
            <div className="stafflist-header-left">
            {hasRoleTrainerManagerAdmin && (
              <>
                <div className="stafflist-select">
                  <h3 className="selecttitle">部門表示</h3>
                  <div className="selectbox">
                    <select
                      className="children2"
                      value={brandId}
                      onChange={(e) => {
                        if (e.target.value != '') {
                          props.handleCurriculumBrandName?.('')
                          setBrandId(e.target.value);
                        }
                      }}
                    >
                      <option value="">
                        -----{config?.labels?.brand}を選択-----
                      </option>
                      {getShopsBrandsResponse?.map((brand) => {
                        return (
                          <option key={brand.id} value={brand.id}>
                            {brand.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="stafflist-select me-3">
                  <h3 className="selecttitle">カリキュラム表示切替</h3>
                  <div className="selectbox">
                    <select
                      className="children2"
                      value={props.curriculumBrandName === '' ? props.brandId : props.curriculumBrandName}
                      onChange={(e) => {
                        props.handleCurriculumBrandName?.(e.target.value)
                      }}
                    >
                      <option value="">
                        -----{config?.labels?.brand}を選択-----
                      </option>   
                      {brandsForCurriculums?.map((brand) => {
                        return (
                          <option key={brand.id} value={brand.id}>
                            {brand.name}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="stafflist-conditions">
                  <div className="stafflist-select">
                    <h3 className="selecttitle">並び順</h3>
                    <div className="selectbox">
                      <select onChange={handleSortStaffs}>
                        <option value="0">標準</option>
                        <option value="1">カリキュラム進捗多</option>
                        <option value="2">カリキュラム進捗少</option>
                      </select>
                    </div>
                  </div>
                  <div className="stafflist-select">
                    <h3 className="selecttitle">絞り込み</h3>
                    <div className="selectbox">
                      <select onChange={handleSorts}>
                        <option value="0">すべて</option>
                        <option value="1">自部門のみ</option>
                        <option value="2">他部門からの応援者</option>
                        <option value="3">セルフチェックアラート</option>
                        <option value="4">目標設定アラート</option>
                      </select>
                    </div>
                  </div>
                </div>
                {props.curriculumDisplayPreference && (
                  <div className="stafflist-select me-3">
                    <h3 className="selecttitle fw-bold">ステップ項目</h3>
                    <div className="togglebox">
                      <div className="form-check form-switch">
                        <label className="form-check-label toggle-label-left">表示する</label>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={props.showSteps}
                          onChange={(e) => props.handleShowAllCurriculums(e.target.checked)}
                        />
                        <label className="form-check-label toggle-label-right">しない</label>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
            </div>
            <div className="stafflist-header-right">
              {!props.isBulkEditing && (
                <button
                  className='btn btn-secondary btn-sm'
                  onClick={(e) => {
                    e.preventDefault()
                    openModalSettingColor()
                  }}
                >
                  色の説明
                </button>
              )}
              <ModalSettingColor>
                <ColorSettingModal
                  closeModal={closeModalSettingColor} 
                />
              </ModalSettingColor>
              {(config && !config?.hideFormButtons && !props.isBulkEditing) &&
                <button
                  className='btn btn-secondary'
                  onClick={(e) => {
                    e.preventDefault()
                    window.open(props.form.url, '_blank');
                  }}
                  style={!props.form.url ? buttonFormStyleDisabled : undefined}
                  disabled={!props.form.url}
                >
                  外部リンク
                </button>
              }
              {(hasRoleTrainerManagerAdmin && !props.isBulkEditing) && (
                <NavLink className="btn btn-secondary" to={`/shop_select`}>
                  トップに戻る
                </NavLink>
              )}

              {(hasRoleStore1ManagerAdmin && props.isBulkEditing) && (
                <a
                  href="#"
                  className='btn btn-secondary'
                  onClick={(e) => {
                    e.preventDefault()
                    window.location.reload()
                  }}
                >
                  スタッフ一覧に戻る
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopHeader
