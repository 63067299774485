import React, { useState } from 'react'
import './App.css'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import Router from './router'
import { AuthUserProvider } from './hooks/AuthUserContext'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { ScFooter, ScPageContainer } from './style/styles'
import axios, { AxiosError, AxiosResponse } from 'axios'

function App() {
  const [queryClient, setQueryClient] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnWindowFocus: false,
        },
        mutations: {
          retry: false,
        },
      },
    })
  )

  const responseSuccessHandler = (response: AxiosResponse) => {
    return response
  }
  const responseErrorHandler = (error: AxiosError) => {
    if (error.response?.status === 401 && error.config.url !== '/auth/login') {
      window.location.href =
        window.location.protocol + '//' + window.location.host + '/login'
    }
    return Promise.reject(error)
  }
  axios.interceptors.response.use(
    (response) => responseSuccessHandler(response),
    (error) => responseErrorHandler(error)
  )

  const year = new Date().getFullYear();

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>e-Training</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=1024px,user-scalable=yes" />
          <meta name="keywords" content="" />
          <meta name="description" content="" />
          <link href="/favicon.ico" type="image/x-icon" rel="icon" />
          <link href="/favicon.ico" type="image/x-icon" rel="shortcut icon" />
        </Helmet>

        <ScPageContainer>
          <QueryClientProvider client={queryClient}>
            <AuthUserProvider>
              <Router />
              <ToastContainer />
            </AuthUserProvider>
          </QueryClientProvider>
          <ScFooter>
            &copy; Copyright {year}, Franchise Advantage, Inc. All rights
            reserved.
          </ScFooter>
        </ScPageContainer>
      </HelmetProvider>
    </>
  )
}

export default App
