import { FormEvent, useEffect, useState } from 'react'
import { useUpdateAimCurriculum } from '../../queries/TasksQuery'
import moment from 'moment'
import Loading from '../Loading'
import { StButtonTypeB, StButtonTypeBDisabled, textareaStyle } from '../../style/styles'
import TextareaModal from './TextareaModal'
import { CurriculumForWorkerDetail } from '../../types/WorkerDetailResponse'

type AimCurriculumSettingModalProps = {
  onClose: () => void;
  curriculum: CurriculumForWorkerDetail
  workerId: string | undefined;
  onSuccess: () => void
  canEditAsWorker: boolean
  canEditAsTrainer: boolean
}

function AimCurriculumSettingModal(props: AimCurriculumSettingModalProps) {

  const startAt = props.curriculum.start ?? moment().format('YYYY-MM-DD')
  const endAt = props.curriculum.end ?? moment().format('YYYY-MM-DD')

  const [aim, setAim] = useState<string>(props.curriculum.curriculum_aim_comment || '')
  const [startAtYear, setStartAtYear] = useState<number>(
    parseInt(startAt.split('-')[0])
  )
  const [startAtMonth, setStartAtMonth] = useState<number>(
    parseInt(startAt.split('-')[1])
  )
  const [startAtDate, setStartAtDate] = useState<number>(
    parseInt(startAt.split('-')[2])
  )
  const [endAtYear, setEndAtYear] = useState<number>(
    parseInt(endAt.split('-')[0])
  )
  const [endAtMonth, setEndAtMonth] = useState<number>(
    parseInt(endAt.split('-')[1])
  )
  const [endAtDate, setEndAtDate] = useState<number>(
    parseInt(endAt.split('-')[2])
  )


  const [isTextareaModalOpen, setTextareaModalOpen] = useState(false);

  const updateAim = useUpdateAimCurriculum()

  useEffect(() => {
    if (updateAim.isSuccess) {
      props.onSuccess()
    }
  }, [updateAim.isSuccess])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    
    if(!props.workerId) return

    updateAim.mutate({
      workerId: props.workerId,
      curriculumId: props.curriculum.curriculumId,
      start: new Date(
        startAtYear,
        startAtMonth - 1,
        startAtDate
      ).toDateString(),
      end: new Date(endAtYear, endAtMonth - 1, endAtDate).toDateString(),
      comment: aim 
    })
  }

  const range = (start: number, stop: number, step: number) =>
    Array.from(
      { length: (stop - start) / step + 1 },
      (_, i) => start + i * step
    )

  return (
    <>
      {updateAim.isLoading ? <Loading /> : ''}
      <div className="modal">
        <div className="modal-close">
          <button type="button" className="modal-close-button" onClick={props.onClose}>
            <img src="/images/circle-xmark.svg" className="modal-close-icon" />
          </button>
        </div>
        <div className="modal-header">
          <h1> 目標設定 </h1>
        </div>
        <form onSubmit={handleSubmit}>
          <fieldset disabled={!props.canEditAsWorker && !props.canEditAsTrainer}>
            <div className="input date">
              <label>開始日</label>
              <select
                value={startAtYear}
                onChange={(e) => setStartAtYear(parseInt(e.target.value))}
              >
                <option value="">年</option>
                {range(2022, 2032, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={startAtMonth}
                onChange={(e) => setStartAtMonth(parseInt(e.target.value))}
              >
                <option value="">月</option>
                {range(1, 12, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={startAtDate}
                onChange={(e) => setStartAtDate(parseInt(e.target.value))}
              >
                <option value="">日</option>
                {range(1, 31, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
            <div className="input date">
              <label>終了日</label>
              <select
                value={endAtYear}
                onChange={(e) => setEndAtYear(parseInt(e.target.value))}
              >
                <option value="">年</option>
                {range(2022, 2032, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={endAtMonth}
                onChange={(e) => setEndAtMonth(parseInt(e.target.value))}
              >
                <option value="">月</option>
                {range(1, 12, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
              <select
                value={endAtDate}
                onChange={(e) => setEndAtDate(parseInt(e.target.value))}
              >
                <option value="">日</option>
                {range(1, 31, 1).map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                })}
              </select>
            </div>
          </fieldset>
          <pre
            style={{
              ...textareaStyle,
              color: aim ? '#000' : '#a0a0a0'
            }}
            onClick={() => setTextareaModalOpen(true)}
          >
            {aim || "ここをクリックして表示したいテキストを入力してください。"}
          </pre>

          <TextareaModal
            isOpen={isTextareaModalOpen}
            onClose={() => setTextareaModalOpen(false)}
            onConfirm={(text) => {
              setAim(text);
              setTextareaModalOpen(false);
            }}
            comment={aim}
          />
          <div className="submit">
            <input type="submit" value="送信"
              disabled={!props.canEditAsWorker}
              style={(props.canEditAsWorker) ? StButtonTypeB : StButtonTypeBDisabled}
            />
          </div>
        </form>
      </div>
    </>
  )
}

export default AimCurriculumSettingModal
