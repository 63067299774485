import { CurriculumGroup } from '../types/CurriculumGroup'
import { Curriculum } from '../types/Curriculum'
import { useState, useEffect, useRef } from 'react'
import { GetShopsResponse, Courses } from '../types/GetShopsResponse'
import axios from 'axios'

type CurriculumSelectProps = {
    curriculums: CurriculumGroup[],
    onSelect: (curriculumId: string) => void
}

function CurriculumSelect(props: CurriculumSelectProps) {
    const [curriculumId, setCurriculumId] = useState<string>('')
    const [groupName, setGroupName] = useState<string>('')
    const [selectedCurriculum, setSelectedCurriculum] = useState<Curriculum>()
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false)
    const [selectedGroup, setSelectedGroup] = useState<any>(null)
    const [courses, setCourses] = useState<Courses[]>([])
    const [courseId, setCourseId] = useState<string>('')

    const handleGroupSelected = (groupName: string) => {
        setGroupName((value) => {
            if (value === groupName) {
                return ''
            }
            return groupName
        })
    }

    const handleCurriculumSelected = (curriculum: Curriculum) => {
        setCurriculumId(curriculum.id)
        setSelectedCurriculum(curriculum)
        props.onSelect(curriculum.id)
        setDropdownOpen(false)
        setGroupName('')
    }

    const handleCourseSelected = (id: string) => {
        setCourseId(id)
        const group = props.curriculums.find((group) => {
            return group.group_name == groupName
        })
        if(id !== '全て'){
            const selectedCurriculums = group?.items?.filter((curriculum: Curriculum) => {
                return curriculum.course_id == id;
            });
            setSelectedGroup({group_name: groupName, items: selectedCurriculums, type: 'group'})
        }else{
            setSelectedGroup(group)
        }
    }

    const ref = useRef<HTMLDivElement>(null)
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                setDropdownOpen(false)
                setGroupName('')
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    useEffect(() => {
      const getCourses = async () => {
        const { data } = await axios.get<GetShopsResponse>(`/api/shops/courses`)
        const courses = data?.courses
  
        setCourses([...courses])
      }
      getCourses()
  
    }, [courseId])

    const handleOpenCloseDropdowns = () => {
        setDropdownOpen(!dropdownOpen)
        setSelectedGroup(null)
    }


    return (
        <div className="curriculum-selector" ref={ref}>
            <button type="button" onClick={handleOpenCloseDropdowns}>
                {selectedCurriculum ? selectedCurriculum.name : '-- カリキュラムを選択 --'}
            </button>
            <div className={dropdownOpen ? 'curriculum-selector-dropdown open' : 'curriculum-selector-dropdown'}>
                {props.curriculums.map((group) => {
                    return (
                        <div className="curriculum-selector-group" key={group.group_name}>
                            <div className={groupName === group.group_name ? 'curriculum-selector-group-name selected' : 'curriculum-selector-group-name'}
                                onClick={() => {
                                    handleGroupSelected(group.group_name)
                                    setSelectedGroup(group)
                                    setSelectedCurriculum(undefined)
                                    setCourseId('')
                                    setCurriculumId('')
                                }}>
                                <div className="curriculum-selector-group-icon">
                                    {groupName === group.group_name ? '-' : '+'}
                                </div>
                                {group.group_name}
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className={groupName === selectedGroup?.group_name ? 'course-selector-group-items open' : 'course-selector-group-items'}> 
                <div className={courseId === '全て' ? 'curriculum-selector-group-item selected' : 'curriculum-selector-group-item'}
                    onClick={() => handleCourseSelected('全て')}>
                    全て
                    <div className="curriculum-selector-group-icon">
                        {courseId === '全て' ? '-' : '+'}
                    </div>
                </div>
                {courses.map((course: any) => {
                    return (
                        <div key={course.id} className={courseId === course.id ? 'curriculum-selector-group-item selected' : 'curriculum-selector-group-item'}
                            onClick={() => handleCourseSelected(course.id)}>
                            <div className="curriculum-selector-group-icon">
                                {courseId === course.id ? '-' : '+'}
                            </div>
                            {course.name}
                        </div>
                    )
                })}
            </div>

            <div className={courseId != '' && selectedCurriculum == null ? 'curriculum-selector-group-items open' : 'curriculum-selector-group-items'}>
                {selectedGroup?.items?.map((curriculum: any) => {
                    return (
                        <div key={curriculum.id} className={curriculumId === curriculum.id ? 'curriculum-selector-group-item selected' : 'curriculum-selector-group-item'}
                            onClick={() => handleCurriculumSelected(curriculum)}>
                            {curriculum.name}
                        </div>
                    )
                })}
            </div>

        </div >
    );
}

export default CurriculumSelect;
